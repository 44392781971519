import axios from 'axios';

import {
  getItemSession,
} from './helpers/storage';

import { SERVER_URL } from './helpers/env';

// --------------------------- WARNING -----------------------------//
// Don't forget to add websocket listener into broadcastListener.js //
// for all the endpoints here that are waiting for "callback" from  //
// server.                                                          //
// -----------------------------------------------------------------//

const API = SERVER_URL;

export function fetchMe() {
  return new Promise((resolve, reject) => {
    const url = `${API}/user/me`;

    axios.get(url).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

/* NOTIFICATIONS */
export function patchNotification(id) {
  return new Promise((resolve, reject) => {
    const url = `${API}/notifications/${id}`;

    axios.patch(url).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

/* GATEWAYS */
export function fetchGateways() {
  return new Promise((resolve, reject) => {
    const url = `${API}/network/${window.Store.currentNetwork.id}/gateway`;

    axios.get(url).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function postUser(email) {
  return new Promise((resolve, reject) => {
    const url = `${API}/network/${window.Store.currentNetwork.id}/user`;

    axios.post(url, { email }).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function removeUser(id) {
  return new Promise((resolve, reject) => {
    const url = `${API}/network/${window.Store.currentNetwork.id}/user/${id}`;

    axios.delete(url).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function postGateway(serial, newName) {
  return new Promise((resolve, reject) => {
    const url = `${API}/network/${window.Store.currentNetwork.id}/gateway`;

    axios.post(url, { serial_code: serial, name: newName }).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function deleteGateway(id) {
  return new Promise((resolve, reject) => {
    const url = `${API}/gateway/${id}`;

    axios.delete(url).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function patchGateway(id, settings) {
  return new Promise((resolve, reject) => {
    const url = `${API}/gateway/${id}`;

    axios.patch(url, settings).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

/* CONTROL CENTER */

export function patchLightSettings(id, settings) {
  return new Promise((resolve, reject) => {
    const url = `${API}/light/${id}`;
    const requestId = window.Store.addPendingRequest(url, 'light.settings');

    axios.patch(url, { action_id: requestId, ...settings }).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

/* SIGN */
export function signIn(email, password) {
  return new Promise(async (resolve, reject) => {
    const url = `${API}/login`;

    await signOut();

    axios.post(url, { email, password }).then(result => {
      window.Store.setAccessToken(result.data.access_token);
      resolve(result);
    }).catch(error => {
      // eslint-disable-next-line
      console.warn(error.response);
      reject(error);
    });
  });
}

export function signUp(data) {
  return new Promise(async (resolve, reject) => {
    const url = `${API}/register`;

    await signOut();

    axios.post(url, data).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export async function signOut() {
  window.Store.reset();
  window.Store.signIn.submitted = false;

  const token = await getItemSession('access_token');
  if (token) {
    axios.post(`${API}/logout`);
  }

  await window.Store.removeAccessToken();
}

/* LOST PASSWORD */
export function initLostPassword(email) {
  return new Promise((resolve, reject) => {
    const url = `${API}/password/email`;

    axios.post(url, { email }).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function finishLostPassword(data) {
  return new Promise((resolve, reject) => {
    const url = `${API}/password/reset`;

    axios.post(url, data).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

/* VIEWS */
export function patchLightPosition(viewId, lightId, x, y) {
  return new Promise((resolve, reject) => {
    const url = `${API}/views/${viewId}`;

    axios.patch(url, { lights: {[lightId]: { x, y }}}).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function fetchViews() {
  return new Promise((resolve, reject) => {
    const url = `${API}/networks/${window.Store.currentNetwork.id}/views`;

    axios.get(url).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function postView(networkId, name) {
  return new Promise((resolve, reject) => {
    const url = `${API}/networks/${networkId}/views`;

    axios.post(url, { name }).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function updateView(id, updateObject) {
  return new Promise((resolve, reject) => {
    const url = `${API}/views/${id}`;

    axios.patch(url, updateObject).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function updateBlueprint(id, data) {
  return new Promise((resolve, reject) => {
    const url = `${API}/views/${id}`;

    data.append('_method', 'PATCH');

    axios.post(url, data).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function deleteView(id) {
  return new Promise((resolve, reject) => {
    const url = `${API}/views/${id}`;

    axios.delete(url).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

/* LIGHT GROUPS */
export function fetchGroups(roomId) {
  return new Promise((resolve, reject) => {
    const url = `${API}/rooms/${roomId}/groups`;

    axios.get(url).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function postGroup(roomId, name) {
  return new Promise((resolve, reject) => {
    const url = `${API}/rooms/${roomId}/groups`;

    axios.post(url, { name }).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function patchGroup(groupId, settings) {
  return new Promise((resolve, reject) => {
    const url = `${API}/groups/${groupId}`;

    axios.patch(url, settings).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function patchLight(lightId, settings) {
  return new Promise((resolve, reject) => {
    const url = `${API}/lights/${lightId}`;

    axios.patch(url, settings).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function deleteGroup(groupId) {
  return new Promise((resolve, reject) => {
    const url = `${API}/groups/${groupId}`;

    axios.delete(url).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function patchGroupLights(roomId, groupId, lights) {
  return new Promise((resolve, reject) => {
    const url = `${API}/rooms/${roomId}/groups/${groupId}/lights`;

    axios.patch(url, { lights }).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

/* SCENE */
export function createScene(level, levelId, data) {
  return new Promise((resolve, reject) => {
    const url = `${API}/${level}s/${levelId}/scenes`;

    axios.post(url, data).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function patchScene(sceneId, data) {
  return new Promise((resolve, reject) => {
    const url = `${API}/scenes/${sceneId}`;

    axios.patch(url, data).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function deleteScene(sceneId) {
  return new Promise((resolve, reject) => {
    const url = `${API}/scenes/${sceneId}`;

    axios.delete(url).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

/* AUTO MODE */
export function postAutoMode(roomId, data) {
  return new Promise((resolve, reject) => {
    const url = `${API}/rooms/${roomId}/auto-mode-configurations`;

    axios.post(url, data).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function deleteAutoMode(id) {
  return new Promise((resolve, reject) => {
    const url = `${API}/auto-mode-configurations/${id}`;

    axios.delete(url).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function fetchAutoMode(id) {
  return new Promise((resolve, reject) => {
    const url = `${API}/auto-mode-configurations/${id}`;

    axios.get(url).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function patchAutoMode(id, data) {
  return new Promise((resolve, reject) => {
    const url = `${API}/auto-mode-configurations/${id}`;

    axios.patch(url, data).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

/* NETWORKS */
export function fetchNetwork(id) {
  return new Promise((resolve, reject) => {
    const suffix = id ? `/${id}` : '';
    const url = `${API}/network${suffix}`;

    axios.get(url).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function fetchNetworks() {
  return new Promise((resolve, reject) => {
    const url = `${API}/network`;

    axios.get(url).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function postNetwork(name) {
  return new Promise((resolve, reject) => {
    const url = `${API}/network`;

    axios.post(url, { name }).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function updateNetwork(id, settings) {
  return new Promise((resolve, reject) => {
    const url = `${API}/network/${id}`;

    axios.patch(url, settings).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function deleteNetwork(id) {
  return new Promise((resolve, reject) => {
    const url = `${API}/network/${id}`;

    axios.delete(url).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

/* ROOMS */

export function fetchRooms(networkId) {
  return new Promise((resolve, reject) => {
    const url = `${API}/network/${networkId}/room`;

    axios.get(url).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function fetchRoom(id) {
  return new Promise((resolve, reject) => {
    const url = `${API}/rooms/${id}`;

    axios.get(url).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function patchRoom(id, settings) {
  return new Promise((resolve, reject) => {
    const url = `${API}/rooms/${id}`;

    axios.patch(url, settings).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function postRoom(viewId, name) {
  return new Promise((resolve, reject) => {
    const url = `${API}/views/${viewId}/rooms`;

    axios.post(url, { name }).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function deleteRoom(id) {
  return new Promise((resolve, reject) => {
    const url = `${API}/rooms/${id}`;

    axios.delete(url).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

/* DEVICES */

export function fetchDevices(networkId) {
  return new Promise((resolve, reject) => {
    const url = `${API}/network/${networkId}/light`;

    axios.get(url).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function postDevice(groupId, data) {
  return new Promise((resolve, reject) => {
    const url = `${API}/groups/${groupId}/lights`;

    axios.post(url, data).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function patchDevice(id, settings) {
  return new Promise((resolve, reject) => {
    const url = `${API}/lights/${id}`;

    axios.patch(url, settings).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function deleteDevice(id) {
  return new Promise((resolve, reject) => {
    const url = `${API}/lights/${id}`;

    axios.delete(url).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

/* PROFILE */

export function fetchProfile() {
  return new Promise((resolve, reject) => {
    const url = `${API}/user/profile_options`;

    axios.get(url).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function changeEmail(data) {
  return new Promise((resolve, reject) => {
    const url = `${API}/user/change_email`;

    axios.post(url, data).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function changePassword(data) {
  return new Promise((resolve, reject) => {
    const url = `${API}/user/change_password`;

    axios.post(url, data).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}

export function postProfileOptions(options) {
  return new Promise((resolve, reject) => {
    const url = `${API}/user/profile`;

    axios.post(url, options).then(result => {
      resolve(result);
    }).catch(error => {
      reject(error);
    });
  });
}
