export const BrightnessBounds = [0, 100];
export const ChromaticityBounds = [100, -100];

export const MODE_MANUAL = 1;
export const MODE_SCENE = 2;
export const MODE_AUTO = 3;
export const MODE_INHERIT = 9;

export const ChromaticityGradient = [
  { step: 0.1, background: 'rgba(238, 203, 59, 0.8)' },
  { step: 0.7, background: 'rgba(66, 134, 244, 0.8)' },
  { step: 0.9, background: 'rgba(255, 145, 0, 0.8)' },
  { step: 0, background: 'rgba(238, 203, 59, 0.8)' },
  { step: 1, background: 'rgba(66, 134, 244, 0.8)' },
];

export const BrightnessGradient = [
  { step: 0, background: 'rgba(238, 203, 59, 0.8)' },
  { step: 1, background: 'rgba(255, 0, 0, 0)' },
];

export const DayLength = 1439;

export const AUTO_WEEKDAYS = 0;
export const AUTO_WEEKENDS = 1;
export const AUTO_HOLIDAYS = 2;
export const AUTO_EXCEPTION = 3;
