import React from 'react';
import { observer, Observer } from 'mobx-react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Typography } from '@material-ui/core';

import styles from './HierarchyTree.module.scss';
import HierarchyLevel from './HierechyLevel';
import HierarchyDevice from './HierarchyDevice';
import Modal from './Modal';

@observer
class HierarchyTree extends React.Component {

  handleOnBeforeCapture = (start) => {
    const level = this.props.data.getChildren(start.draggableId, this.props.data.network);
    if (level.level < 5) {
      const draggableEL = document.getElementById(start.draggableId);
      const hierarchy = document.getElementById('hierarchyWrapper');
      hierarchy.style.marginBottom = `${draggableEL.offsetHeight}px`;
      level.isExpanded = false;
    }
  };

  onDragStart = (start) => {
    this.props.data.isEditting = true;
    const level = this.props.data.getChildren(start.draggableId, this.props.data.network);
    this.props.data.enableDrop(level.droppableTo, this.props.data.network);
  };

  onDragEnd = (result) => {
    this.props.data.isEditting = false;
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }

    this.props.data.changeParent(source, destination, draggableId);
    this.props.data.disableDrop(this.props.data.network);

    const level = this.props.data.getChildren(draggableId, this.props.data.network);
    if (level.level < 5) {
      level.isExpanded = true;
      const draggableEL = document.getElementById(draggableId);

      setTimeout(draggableEL.scrollIntoView());
      const hierarchy = document.getElementById('hierarchyWrapper');
      hierarchy.style.marginBottom = '80px';
    }
  };

  render() {
    const s = this.props.data;

    return (
      <DragDropContext
        onDragEnd={(result) => this.onDragEnd(result)}
        onBeforeCapture={(start) => this.handleOnBeforeCapture(start)}
        onDragStart={(start) => this.onDragStart(start)}
      >
        {s.network && s.network.map((firstLevel) => {
          return (
            <React.Fragment key={firstLevel.id}>
              <div className={styles.network}>
                <Typography variant='h1'>{firstLevel.title}</Typography>
              </div>
              <Droppable
                droppableId={firstLevel.DnD_Id}
                isDropDisabled={firstLevel.droppDisabled}
              >
                {(provided) => (
                  <Observer>
                    {() => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={styles.hierarchyDroppable}
                        style={{
                          background: !firstLevel.droppDisabled && 'rgba(187, 176, 151, 0.3)',
                          marginBottom: !firstLevel.droppDisabled && '10px',
                        }}
                      >
                        {firstLevel.children && firstLevel.children.map((level, index) => {
                          if (firstLevel.id !== 'Gateways') {
                            return (
                              <HierarchyLevel
                                key={level.id}
                                levelData={level}
                                dataTree={s}
                                index={index}
                                isLast={index === firstLevel.children.length - 1}
                                isOnlyChild={firstLevel.children.length === 1}
                                getChildren={(id, data) => s.getChildren(id, data)}
                                handleModal={(id) => firstLevel.handleModal(id)}
                              />
                            );
                          } else {
                            return  (
                              <HierarchyDevice
                                key={level.id}
                                dataTree={s}
                                levelData={level}
                                index={index}
                                isOnlyChild={firstLevel.children.length === 1}
                              />
                            );
                          }
                        })}
                      {provided.placeholder}
                    </div>
                    )}
                  </Observer>
                )}
              </Droppable>

              <div className={styles.addButtonWrapper}>
                {firstLevel.id !== 'Gateways' &&
                  <Modal
                    data={firstLevel}
                    store={s}
                  />
                }
                {firstLevel.id === 'Gateways' &&
                  <Modal
                    data={firstLevel}
                    store={s}
                  />
                }
              </div>
            </React.Fragment>
          );
        })}
      </DragDropContext>
    );
  }
}

export default HierarchyTree;
