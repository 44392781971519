import { observable, computed, action } from 'mobx';
import dayjs from 'dayjs';

import Controlable from '../extendable/controlable';

export default class Light extends Controlable {
  @observable id = 0;
  @observable name = '';
  @observable serialCode = '';
  @observable on = false;
  @observable kelvinRange = 0;
  @observable alive = false;
  @observable powerOn = 0;
  @observable ledTemp = 0;
  @observable driverTemp = 0;
  @observable watts = 0;
  @observable motionEnabled = false;
  @observable nightLightEnabled = false;
  @observable parent = null;
  @observable showDiagnostics = false;
  @observable signal = null;
  @observable rssi = null;
  @observable reed = null;
  @observable aliveAt = null;

  constructor(d, parent) {
    super(d);
    this.replace(d, parent);
  }

  @action
  replace = (d, parent) => {
    this.replaceControlable(d);
    this.replaceRuntime(d);

    if (parent) {
      this.parent = parent;
    }

    this.id = d.id;
    this.name = d.name;
    this.serialCode = d.serial_code;
    this.firmwareVersion = d.firmware_version;
    this.on = d.on;
    this.brightness = d.brightness;
    this.chromaticity = d.chromaticity;
    this.kelvinRange = d.kelvin_range;
    this.motionEnabled = d.motion_reaction_enabled;
    this.nightLightEnabled = d.night_light_enabled;
    this.reed = d.reed;
    this.aliveAt = d.alive_at;

    this.capabilities.set('brightness', d.capabilities.Brightness);
    this.capabilities.set('chromaticity', d.capabilities.Chromaticity);
    this.capabilities.set('uvA', d.capabilities.UV_A);
    this.capabilities.set('uvC', d.capabilities.UV_C);
  };

  @action
  replaceRuntime = (d) => {
    this.alive = d.alive;
    this.powerOn = d.runtime_time;
    this.ledTemp = d.runtime_led_temperature;
    this.driverTemp = d.runtime_driver_temperature;
    this.watts = d.runtime_watts;
  };

  @computed get scheduled() {
    return this.parent.scheduled;
  }

  @computed get poweredSince() {
    return dayjs().subtract(this.powerOn, 'second');
  }

  @computed get activeAutoModeName() {
    return this.parent.activeAutoModeName;
  }
}
