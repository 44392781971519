import Echo from 'laravel-echo';
import axios from 'axios';
import { getItemSession } from './helpers/storage';

import { SOCKET_URL } from './helpers/env';

window.io = require('socket.io-client');

// --------------------------- WARNING ------------------------------ //
// Don't forget to add websocket listener here for all the endpoints  //
// from endpoints.js that are waiting for "callback" from server.     //
// ------------------------------------------------------------------ //

export default class BroadcastListener {
  currentChannel = null;

  constructor(store, token = null) {
    this.store = store;
    this.token = token;
  }

  init = async () => {
    if (!this.token) {
      this.token = await getItemSession('access_token');
    }

    this.echo = new Echo({
      broadcaster: 'socket.io',
      host: SOCKET_URL,
      auth: { headers: { Authorization: `Bearer ${this.token}` } },
    });

    this.echo.connector.socket.on('connect', () => {
      axios.defaults.headers.common['X-Socket-ID'] = this.echo.socketId();
    });

    this.subscribeToChannel(window.Store.currentNetwork.id);

    this.echo.private(`App.Models.User.${window.Store.userId}`)
      .listen('.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', (data) => {
        window.Store.addNotification({ id: data.id, data: { message: data.message } });
      });
  };

  leaveCurrentChannel = () => {
    this.echo.leave(this.currentChannel);
  }

  subscribeToChannel = (id) => {
    this.currentChannel = `app-network.${id}`;
    this.echo.private(this.currentChannel)
      .listen('.views-update', (data) => {
        window.Store.controlCenter.replace(data.views, data.diagnosticsLinks, data.diagnostics_on);
        if (window.Store.hierarchySettings.network.length > 0) {
          if (window.Store.hierarchySettings.isEditting === false) {
            const network = window.Store.hierarchySettings.network[0];
            network.replaceNetwork(data.views);
          }
        }
      })
      .listen('.room-update', (room) => {
        window.Store.registerEntity('room', room);
      })
      .listen('.gateways-update', (gateways) => {
        gateways.gateways.forEach(gateway => {
          window.Store.gateways.updateOnOff(gateway.id, gateway.alive);
          if (window.Store.hierarchySettings.network.length > 0) {
            const gatewaySettings = window.Store.hierarchySettings.network[1];
            gatewaySettings.updateOnOff(gateway.id, gateway.alive);
          }
        });
      });
  }
}
