import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';

import RemoveButton from '../../components/RemoveButton';
import DialogTitle from '../DialogTitle';
import { Observer } from 'mobx-react';

class ModalDeleteLevel extends React.Component {
  render() {
    const levelData = this.props.data;
    return (
      <Observer>
        {() => (
          <Dialog
          open={levelData.modalDelete}
          onClose={
            () => {
              levelData.handleModalDelete();
              this.props.store.isEditting = false;
            }}
          >
          <DialogTitle
            title={`Remove device`}
            onClose={() => {
              levelData.handleModalDelete();
              this.props.store.isEditting = false;
            }}
          />
          <DialogContent>
            <DialogContentText>
              Are you sure you want to remove this device? You can re-add it later.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              onClick={() => {
                levelData.handleModalDelete();
                this.props.store.isEditting = false;
              }}
              color="primary"
            >
              Cancel
            </Button>
            <RemoveButton
              onClick={() => {
                levelData.handleModalDelete();
                levelData.handleDelete(levelData);
                this.props.store.isEditting = false;
              }}
              long
            />
          </DialogActions>
        </Dialog>
        )}
      </Observer>
    );
  }
}

export default ModalDeleteLevel;
