import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';

import './index.scss';
import Main from './Main';
import * as serviceWorker from './serviceWorker';
import RootStore from './shared/store';

// Create store
window.Store = new RootStore();

ReactDOM.render(<Provider store={window.Store}><Main /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
