import { observable, flow, computed } from 'mobx';
import Validator from 'validatorjs';

import {
  fetchProfile,
  changeEmail,
  changePassword,
  postProfileOptions
} from '../endpoints';

class Password {
  @observable form = observable.map({
    password: '',
    password_confirmation: '',
    old_password: ''
  });
  @observable submitted = false;

  submit = flow(
    function*() {
      this.submitted = true;
      if (this.valid.passes()) {
        try {
          yield changePassword({
            password: this.form.get('password'),
            password_confirmation: this.form.get('password_confirmation'),
            old_password: this.form.get('old_password'),
          });
        } catch (error) {
          if (error.message.includes('400')) {
            window.Store.handleError('profile.changePassword.wrong');
          } else {
            window.Store.handleError('profile.changePassword');
          }
        }
      }
    }.bind(this)
  );

  @computed get valid() {
    const validator = new Validator(
      {
        password: this.form.get('password'),
        password_confirmation: this.form.get('password_confirmation'),
        old_password: this.form.get('old_password')
      },
      { password: 'required|confirmed|min:6', old_password: 'required' }
    );

    if (this.submitted) {
      // do the validation on first submit
      validator.passes();
    }
    return validator;
  }
}

class Email {
  @observable form = observable.map({
    email: '',
    password: ''
  });
  @observable submitted = false;

  submit = flow(
    function*() {
      this.submitted = true;
      if (this.valid.passes()) {
        try {
          yield changeEmail({
            email: this.form.get('email'),
            password: this.form.get('password')
          });
        } catch (error) {
          window.Store.handleError('profile.changeEmail');
        }
      }
    }.bind(this)
  );

  @computed get valid() {
    const validator = new Validator(
      { email: this.form.get('email'), password: this.form.get('password') },
      { email: 'required|email', password: 'required' }
    );

    if (this.submitted) {
      // do the validation on first submit
      validator.passes();
    }
    return validator;
  }
}

export class Profile {
  constructor() {
    this.password = new Password();
    this.email = new Email();
  }

  // options form

  @observable profile_options = [];
  @observable age = '';
  @observable submitted = '';

  fetchProfile = flow(
    function*() {
      try {
        const res = yield fetchProfile();
        this.profile_options = Object.keys(res.data).map(key => ({
          name: key,
          boxes: res.data[key],
          selected: ''
        }));
      } catch (error) {
        window.Store.handleError('profile.init');
        throw error;
      }
    }.bind(this)
  );

  submitOptions = flow(
    function*() {
      this.submitted = true;
      if (this.age === '' || this.validAge.passes()) {
        try {
          const submitObject = {};
          if (this.age !== '') submitObject.age = this.age;
          this.profile_options.forEach(option => {
            if (option.selected !== '')
              submitObject[option.name] = option.selected;
          });

          yield postProfileOptions(submitObject);
        } catch (error) {
          window.Store.handleError('profile.updateOptions');
        }
      }
    }.bind(this)
  );

  @computed get validAge() {
    const validator = new Validator(
      { age: this.age },
      { age: 'numeric|min:0|max:150' }
    );

    if (this.submitted) {
      // do the validation on first submit
      validator.passes();
    }
    return validator;
  }
}
