import React from 'react';
import { observer, inject } from 'mobx-react';

import {
  Paper,
  Grow,
  Typography,
  CircularProgress,
} from '@material-ui/core';

import { Line } from 'react-chartjs-2';
import classNames from 'classnames';

import styles from './UsageStats.module.scss';

const chartData = (data) => (canvas) => {
  const ctx = canvas.getContext('2d');
  const gradient = ctx.createLinearGradient(0, 0, 0, 150);
  gradient.addColorStop(0, 'rgba(238, 149, 59, 0.8)');
  gradient.addColorStop(1, 'rgba(109, 201, 149, 0.8)');

  return {
    labels: data.map(data => data.date),
    datasets: [
      {
        label: 'kWh',
        backgroundColor: gradient,
        data: data.map(data => data.value / 1000),
      },
    ],
  };
};

const chartOptions = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  scales: {
    yAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Power usage (in kWh)',
      },
    }],
  },
};

@inject('store')
@observer
class UsageStats extends React.Component {
  async componentDidMount() {
    await window.Store.setAccessToken();
    await window.Store.init();

    this.props.store.crumbs = [
      { label: 'Control Center', value: '/in' },
      { label: 'Usage statistics', value: '/in/usage' },
    ];
  }

  render() {
    const s = this.props.store;

    return (
      <div className={styles.wrapper}>
        <Grow in={true} timeout={700}>
          <Paper elevation={2} className={styles.paper}>
            <Typography variant="body1" className="mb-2">
              Power usage (last 30 days)
            </Typography>

            {!s.currentNetwork &&
              <div className={styles.progress}>
                <CircularProgress />
              </div>
            }

            {s.currentNetwork &&
              <div className={classNames(styles.chart, 'mt-1')}>
                <Line data={chartData(s.currentNetwork.statistics)} options={chartOptions} />
              </div>
            }
          </Paper>
        </Grow>
      </div>
    );
  }
}

export default UsageStats;
