import React from 'react';
import { observer, inject } from 'mobx-react';
import dayjs from 'dayjs';

import { Link } from 'react-router-dom';

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';
import DialogTitle from '../../../components/DialogTitle';

import {
  AUTO_EXCEPTION,
} from '../../../shared/constants';

import { prettify } from '../../../shared/stores/extendable/schedulable';

import styles from './Control.module.scss';

@inject('store')
@observer
class AutoModes extends React.Component {
  state = {
    openCreateException: false,
    exceptionName: '',
  };

  toggleCreateException = () => this.setState({ openCreateException: !this.state.openCreateException });

  render() {
    const { s, hash } = this.props;
    const store = this.props.store;
    return (
      <>
        <List>
          {s.autoModeConfigurations.filter(configuration => configuration.type !== AUTO_EXCEPTION).map((configuration, index) => (
            <ListItem
              key={index}
              component={Link}
              to={`/in/settings/view/${store.controlCenter.view.id}/rooms/${s.id}/auto/${configuration.id}#${hash}`}
              button
            >
              <ListItemIcon><SettingsIcon /></ListItemIcon>
              <ListItemText primary={configuration.name} />
            </ListItem>
          ))}
        </List>
        <hr />
        <List>
          {s.autoModeConfigurations.filter(configuration => configuration.type === AUTO_EXCEPTION).map((configuration) => (
            <ListItem
              component={Link}
              to={`/in/settings/view/${store.controlCenter.view.id}/rooms/${s.id}/auto/${configuration.id}#${hash}`}
              button
            >
              <ListItemIcon><SettingsIcon /></ListItemIcon>
              <ListItemText
                primary={configuration.name}
                secondary={prettify({
                  repeatType: configuration.repeat_type,
                  scheduledAt: dayjs(configuration.scheduled_at),
                  repeat: configuration.repeat,
                })}
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => s.deleteAutoMode(configuration.id)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>

        <div className={styles.sceneBar}>
          <Button
            variant="contained"
            color="secondary"
            onClick={this.toggleCreateException}
          >
            <AddIcon className="mr-1" />
            Create exception
          </Button>
        </div>

        <Dialog
          open={this.state.openCreateException}
          onClose={this.toggleCreateException}
        >
          <DialogTitle
            title='Create exeption'
            onClose={this.toggleCreateException}
          />
          <DialogContent>
            <TextField
              label="Exception name"
              margin="normal"
              value={this.state.exceptionName}
              fullWidth
              onChange={(e) => this.setState({ exceptionName: e.target.value })}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleCreateException} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                s.addAutoMode(this.state.exceptionName);
                this.setState({ exceptionName: '', openCreateException: false });
              }}
              variant="contained"
              color="secondary"
            >
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default AutoModes;
