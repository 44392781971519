import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { observer } from 'mobx-react';

import {
  Typography,
  Paper,
  Tooltip,
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import SignalWifi4BarIcon from '@material-ui/icons/SignalWifi4Bar';
import SignalWifiOffIcon from '@material-ui/icons/SignalWifiOff';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';

import styles from './HierarchyTree.module.scss';
import ModalSettings from './modalSettings';
import ModalDeleteLevel from './modalDeleteLevel';

@observer
class HierarchyDevice extends React.Component {
  render() {
    return (
      <Draggable
        draggableId={this.props.levelData.DnD_Id}
        index={this.props.index}
        isDragDisabled={this.props.levelData.level === 'Gateway' && true}
      >
        {(provided) => {
          const position = provided.draggableProps.style.position;
          return (
            <div
              key={this.props.index}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              className={styles.levelDraggable}
            >
              <div className={styles.draggableContentWrapper}>
                <div
                  className={styles.hierarchyLine}
                  style={{
                    display: position === 'fixed' && 'none',
                    height: this.props.isOnlyChild && '100%',
                    top: this.props.isOnlyChild && '0px'
                  }}
                />
                <div
                  className={styles.hierarchyCurve}
                  style={{
                    display: position === 'fixed' && 'none',
                  }}
                >
                  <svg>
                    <path fill='none' stroke='#9D9D9B' strokeWidth='3px' d='M 24.5 0 L 24.5 30 Q 24.5 40 35 40 L 50 40' />
                  </svg>
                </div>
                <div
                  className={styles.paperSection}
                >
                  <Paper>
                    <div className={styles.paperFill}>
                      <div className={styles.nameSection}>
                        <Typography variant='h2'>{this.props.levelData.title}</Typography>
                        {this.props.levelData.alive ? <SignalWifi4BarIcon /> :
                        <>
                          <SignalWifiOffIcon />
                          <div className={styles.descriptionSection}>
                            <Typography variant='caption'>No connection</Typography>
                            <Tooltip title="This device is not discoverable by the network." placement="right-start">
                              <HelpIcon />
                            </Tooltip>
                          </div>
                        </>
                        }
                      </div>
                      <div className={styles.iconSection}>
                        <Typography variant='body2'>{this.props.levelData.serial}</Typography>
                        <IconButton onClick={() => {
                          this.props.levelData.handleOpenSettings();
                          this.props.dataTree.isEditting = true;
                        }}>
                          <SettingsIcon />
                        </IconButton>
                        <ModalSettings
                          data={this.props.levelData}
                          store={this.props.dataTree}
                        />
                        <IconButton onClick={() => {
                          this.props.levelData.handleModalDelete();
                          this.props.dataTree.isEditting = true;
                        }}>
                          <DeleteIcon />
                        </IconButton>
                        <ModalDeleteLevel
                          data={this.props.levelData}
                          store={this.props.dataTree}
                        />
                      </div>
                    </div>
                  </Paper>
                </div>
              </div>
            </div>
          );
        }}
      </Draggable>
    );
  }
}

export default HierarchyDevice;
