import React from 'react';
import styles from './TriStateSwitch.module.scss';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';

const TriStateSwicth = ({ checked, onChange, lights }) => {
  const handleClick = (e, isChecked) => {
    e.stopPropagation();
    onChange(isChecked);
  };
  const CheckIfAnyLightOn = () => {
    return lights.some(light => light.on) && lights.some(light => !light.on);
  };

  return (
    <div className={styles.switchWrapper}>
      <div
      className={
        classNames(
          styles.switch,
          styles.off,
          { [styles.offActive]: !checked && !CheckIfAnyLightOn()})
        }
        onClick={(e) => handleClick(e, false)}
      >
        <Typography variant="caption">
          off
        </Typography>
      </div>
      <div
        className={
          classNames(
            styles.switchBullet,
            { [styles.switchActive]: checked && !CheckIfAnyLightOn() },
            { [styles.switchInActive]: !checked && !CheckIfAnyLightOn() }
          )
        }
        onClick={(e) => handleClick(e)}
      />
      <div
        className={
          classNames(
            styles.switch,
            styles.on,
            { [styles.onActive]: checked }
          )}
          onClick={(e) => handleClick(e, true)}
        >
        <Typography variant="caption">
          on
        </Typography>
      </div>
    </div>
  );
};

export default TriStateSwicth;
