import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import {
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Paper,
  TextField,
  Button,
  ListItemSecondaryAction,
  Grow,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';

import RemoveButton from '../../components/RemoveButton';
import LoadingError from '../../components/LoadingError';
import DialogTitle from '../../components/DialogTitle';

import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';

import styles from './UserAccess.module.scss';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';

@inject('store')
@observer
class UserAccess extends Component {
  state = {
    openRemove: false,
    toRemove: null,
  };

  async componentDidMount() {
    await window.Store.setAccessToken();
    await window.Store.init();

    if (window.Store.currentNetwork) {
      this.props.store.userAccess.init();
    }

    this.props.store.crumbs = [
      { label: 'Control Center', value: '/in' },
      { label: 'User access', value: '/in/access' },
    ];
  }

  toggleRemove = () => this.setState({ openRemove: !this.state.openRemove });
  handleChange = (prop) => (event) => {
    this.props.store.userAccess.form.set(prop, event.target.value);
  };

  render() {
    const s = this.props.store.userAccess;

    return (
      <div className={styles.wrapper}>
        <Grow in={true} timeout={700}>
          <Paper elevation={2} className={styles.paper}>
            <Typography variant="body1">Invite user to network</Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>Send invitations by email</Typography>

            <form className={styles.inviteForm} onSubmit={(e) => {
              e.preventDefault();
              s.addUser();
            }}>
              <TextField
                label="E-mail address for invitation"
                fullWidth
                margin="normal"
                variant="outlined"
                error={!!s.valid.errors.first('email')}
                helperText={s.valid.errors.first('email')}
                onChange={this.handleChange('email')}
                value={s.form.get('email')}
              />
              <Button variant="contained" color="secondary" type="submit">
                Invite
                <SendIcon className="ml-1" />
              </Button>
            </form>
          </Paper>
        </Grow>

        <Grow in={true} timeout={1100}>
          <Paper elevation={2} className={styles.paper}>
            <Typography variant="h3">Manage access of users</Typography>

            {!s.loaded && !s.loadingError &&
              <div className={styles.progress}>
                <CircularProgress />
              </div>
            }

            {s.loadingError &&
              <LoadingError />
            }

            {s.loaded && s.users.map(user => (
              <List key={user.id}>
                <ListItem>
                  <ListItemIcon>
                    <AccountCircleIcon color="secondary" fontSize="large" />
                  </ListItemIcon>
                  <ListItemText primary={user.email} />
                  <ListItemSecondaryAction>
                    {user.email !== this.props.store.user && (
                      <IconButton onClick={() => {
                        this.setState({ toRemove: user });
                        this.toggleRemove();
                      }}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            ))}
          </Paper>
        </Grow>

        {this.state.toRemove && (
          <Dialog
            open={this.state.openRemove}
            onClose={this.toggleRemove}
          >
            <DialogTitle
              title='Remove user'
              onClose={this.toggleRemove}
            />
            <DialogContent>
              <DialogContentText>
                Are you sure you want to remove user {this.state.toRemove.email} from this network?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.toggleRemove} color="primary">
                Cancel
              </Button>
              <RemoveButton
                onClick={() => {
                  this.toggleRemove();
                  s.removeUser(this.state.toRemove.id);
                }}
                long
              />
            </DialogActions>
          </Dialog>
        )}
      </div>
    );
  }
}

export default UserAccess;
