import { createMuiTheme } from "@material-ui/core";

export const darkTheme = createMuiTheme({

  palette: {
    type: 'dark',
    primary: {
      main: '#fff',
      contrastText: '#fff',
    },
    secondary: {
      main: '#f7e879',
    },
    danger: {
      main: '#f7e879',
    },
    background: {
      paper: '#2d2b27',
      default: '#181814',
    },
    alternateTextColor: '#fff',
  },

  typography: {
    fontFamily: ['Lato'],
    h1: {
      fontSize: '18px',
      fontWeight: '500'
    },
    h2: {
      fontSize: '16px',
      fontWeight: '500'
    },
    h3: {
      fontSize: '16px',
      fontWeight: '500'
    },
    body1: {
      fontSize: '14px',
    },
    body2: {
      fontSize: '12px',
    },
    caption: {
      fontSize: '10px',
    },
    title: {
      fontSize: '1.1rem',
    },
    subheading: {
      color: 'rgba(255, 255, 255, 0.75)',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        height: 44,
        margin: '7px 0 4px 0',
      },
      sizeSmall: {
        height: 'auto',
      },
      containedSecondary: {
        background: 'linear-gradient(135deg, #f7e879 0%, #edc834 100%)',
      },
      containedPrimary: {
        color: '#000',
      },
    },
    MuiTableCell: {
      root: {
        padding: '4px 0 0 24px',
      },
    },
    MuiAppBar: {
      colorDefault: '#fff',
      colorPrimary: '#fff',
      colorSecondary: '#fff',
    },
  },
});
