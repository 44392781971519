import { observable, computed, action, flow } from 'mobx';
import every from 'lodash/every';
import some from 'lodash/some';
import find from 'lodash/find';
import dayjs from 'dayjs';

import { patchRoom, postAutoMode, deleteAutoMode } from '../../endpoints';

import Controlable from '../extendable/controlable';
import { NO_REPEAT } from '../extendable/schedulable';
import Navigator from '../../helpers/navigation';

export default class Room extends Controlable {
  @observable id = 0;
  @observable name = '';
  @observable groups = [];
  @observable expanded = false;
  @observable parent = null;
  @observable autoModeConfigurations = [];
  @observable activeAutoModeId = 0;

  // uv c/a
  @observable uvCEnabledAt = null;
  @observable uvCTimer = 0;
  @observable uvCPeriod = 0;
  @observable uvAEnabledAt = null;
  @observable uvA = false;
  @observable uvC = false;

  constructor(d, parent) {
    super(d);
    this.replace(d, parent);
  }

  @action
  replace = (d, parent) => {
    this.replaceControlable(d);

    if (parent) {
      this.parent = parent;
    }

    this.id = d.id;
    this.name = d.name;
    this.autoModeConfigurations = d.auto_mode_configurations ? d.auto_mode_configurations : [];
    this.activeAutoModeId = d.active_auto_mode_configuration_id;

    this.uvCEnabledAt = d.uv_c_enabled_at ? dayjs(d.uv_c_enabled_at) : null;
    this.uvCPeriod = d.uv_c_period;
    this.uvCTimer = d.uv_c_timer;
    this.uvAEnabledAt = d.uv_a_enabled_at ? dayjs(d.uv_a_enabled_at) : null;
    this.uvA = d.uv_a;
    this.uvC = d.uv_c;

    if ('groups' in d) {
      this.groups = [];
      d.groups.forEach((group) => {
        const entity = window.Store.registerEntity('group', group, this);
        this.groups.push(entity);
      });
    }

    this.capabilities.set('brightness', some(this.groups, group => group.capabilities.get('brightness')));
    this.capabilities.set('chromaticity', some(this.groups, group => group.capabilities.get('chromaticity')));
    this.capabilities.set('uvA', some(this.groups, group => group.capabilities.get('uvA')));
    this.capabilities.set('uvC', some(this.groups, group => group.capabilities.get('uvC')));
  };

  setUv = flow(function* (which, value) {
    const data = {};

    if (which === 'a') {
      this.uvAEnabledAt = value ? dayjs() : null;
      data.uv_a_enabled_at = this.uvAEnabledAt;
    }

    if (which === 'c') {
      this.uvCEnabledAt = value ? dayjs() : null;
      data.uv_c_enabled_at = this.uvCEnabledAt;
    }

    if (which === 'howOften') {
      this.uvCPeriod = value;
      data.uv_c_period = value;
    }

    if (which === 'howLong') {
      this.uvCTimer = value;
      data.uv_c_timer = value;
    }

    try {
      const r = yield patchRoom(this.id, data);
      window.Store.registerEntity('room', r.data.data);
    } catch (error) {
      window.Store.handleError('room.settings');
      throw error;
    }
  }.bind(this));

  addAutoMode = flow(function* (name) {
    try {
      const r = yield postAutoMode(this.id, {
        name,
        repeat_type: NO_REPEAT,
        scheduled_at: dayjs(),
        repeat: {},
        configuration: {},
      });
      Navigator._navigator.push(`/in/settings/rooms/${this.id}/auto/${r.data.data.id}`);
    } catch (error) {
      window.Store.handleError('room.settings');
      throw error;
    }
  });

  deleteAutoMode = flow(function* (id) {
    try {
      yield deleteAutoMode(id);
      this.autoModeConfigurations = this.autoModeConfigurations.filter(configuration => configuration.id !== id);
    } catch (error) {
      window.Store.handleError('room.settings');
      throw error;
    }
  });

  toggleExpanded = () => {
    this.expanded = !this.expanded;
  };

  @computed get on() {
    return every(this.groups, group => group.on);
  }

  @computed get motionEnabled() {
    return every(this.groups, group => group.motionEnabled);
  }

  @computed get nightLightEnabled() {
    return every(this.groups, group => group.nightLightEnabled);
  }

  @computed get lights() {
    let result = [];

    this.groups.forEach((group) => {
      result = [...result, ...group.lights];
    });

    return result;
  }

  @computed get activeAutoModeName() {
    return find(this.autoModeConfigurations, { id: this.activeAutoModeId }).name;
  }
}
