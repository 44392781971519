import React from 'react';
import { observer, inject } from 'mobx-react';

import {
  Typography,
  Tooltip,
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DialogTitle from '../../../components/DialogTitle';

import styles from './Control.module.scss';

@inject('store')
@observer
class CustomScenes extends React.Component {
  state = {
    openCreateScene: false,
    openEditScene: false,
    toEdit: null,
    sceneName: '',
  };

  toggleCreateScene = () => this.setState({ openCreateScene: !this.state.openCreateScene });
  toggleEditScene = () => this.setState({ openEditScene: !this.state.openEditScene });

  render() {
    const { level, s } = this.props;

    return (
      <>
        <Typography variant="body1" className="mt-2">Custom scenes</Typography>
        {s.scenes && s.scenes.map((scene) => (
          <div className={styles.scenes} key={scene.id}>
            <Button
              variant="contained"
              className={styles.btn}
              onClick={() => s.setScene(scene.id, level)}
            >
              <span>
                {scene.name}
              </span>

              <div className={styles.actions}>
                <Tooltip title="Change name">
                  <EditIcon
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      this.setState({ toEdit: scene.id, sceneName: scene.name, openEditScene: true });
                    }}
                    className={styles.actionBtn}
                  />
                </Tooltip>
                <Tooltip title={`Save current settings to ${scene.name}`}>
                  <SaveIcon
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      s.updateScene(scene.id, level);
                    }}
                    className={styles.actionBtn}
                  />
                </Tooltip>
                <Tooltip title={`Delete ${scene.name}`}>
                  <DeleteIcon
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      s.deleteScene(scene.id, level);
                    }}
                    className={styles.actionBtn}
                  />
                </Tooltip>
              </div>
            </Button>
          </div>
        ))}

        <div className={styles.sceneBar}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => this.toggleCreateScene()}
          >
            <AddIcon className="mr-1" />
            Create scene
          </Button>
        </div>

        <Dialog
          open={this.state.openCreateScene}
          onClose={this.toggleCreateScene}
        >
          <DialogTitle
            title='Create scene'
            onClose={this.toggleCreateScene}
          />
          <DialogContent>
            <TextField
              label="Scene name"
              margin="normal"
              value={this.state.sceneName}
              fullWidth
              onChange={(e) => this.setState({ sceneName: e.target.value })}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleCreateScene} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                s.createScene(level, { name: this.state.sceneName });
                this.setState({ sceneName: '', openCreateScene: false });
              }}
              variant="contained"
              color="secondary"
            >
              Create
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.openEditScene}
          onClose={this.toggleEditScene}
        >
          <DialogTitle
            title='Change scene name'
            onClose={this.toggleEditScene}
          />
          <DialogContent>
            <TextField
              label="Scene name"
              margin="normal"
              value={this.state.sceneName}
              fullWidth
              onChange={(e) => this.setState({ sceneName: e.target.value })}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleEditScene} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                s.updateScene(this.state.toEdit, level, { name: this.state.sceneName });
                this.setState({ sceneName: '', openEditScene: false });
              }}
              variant="contained"
              color="secondary"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default CustomScenes;
