import React from 'react';
import { inject, Observer } from 'mobx-react';
import axios from 'axios';
import {
  Button,
  Dialog,
  DialogContent,
  TextField,
} from '@material-ui/core';

import SaveButton from '../Save';
import DialogTitle from '../DialogTitle';
import styles from './Modal.module.scss';
import { SERVER_URL } from '../../shared/helpers/env';

@inject('store')
class ModalSettings extends React.Component {
  state = {
    imgSrc: '',
    actualImageSrc: '',
    loadingBlueprint: true,
    loadingBlueprintError: false,
  };

  newFile = null;

  handleUpload = (file) => {
    this.newFile = file;
    const reader = new window.FileReader();
    reader.readAsDataURL(this.newFile);
    reader.onload = () => {
      this.setState({ loadingBlueprint: false });
      this.setState({ imgSrc: reader.result });
    };
  }

  componentDidMount() {
    if (this.props.data.level === 2) {
      axios.get(`${SERVER_URL}/view/${this.props.data.id}/blueprint`, { responseType: 'blob' }).then(response => {
        if (response) {
          const reader = new window.FileReader();
          reader.readAsDataURL(response.data);
          reader.onload = () => {
            this.setState({ loadingBlueprint: false });
            this.setState({ imgSrc: reader.result });
            this.setState({ actualImageSrc: reader.result });
          };
        }
      }).catch(e => {
        this.setState({ loadingBlueprintError: true });
      });
    }
  }

  render() {
    const levelData = this.props.data;
    levelData.newTitle = levelData.title;
    return (
      <Observer>
        {() => (
          <Dialog
          open={levelData.settingsOpen}
          onClose={() => {
            levelData.handleOpenSettings();
            levelData.submitted = false;
            this.props.store.isEditting = false;
            this.newFile = null;
            this.setState({ imgSrc: this.state.actualImageSrc });
          }}
          >
          <form onSubmit={(e) => {
            e.preventDefault();
            levelData.submitted = true;
            if (levelData.valid.passes()) {
              levelData.changeName(levelData, levelData.newTitle);
              levelData.handleOpenSettings();
              if (this.newFile) {
                levelData.uploadBlueprint(levelData.id, this.newFile);
                this.setState({ actualImageSrc: this.state.imgSrc });
              }
              this.newFile = null;
              levelData.submitted = false;
              this.props.store.isEditting = false;
            }

          }}
          >
            <DialogTitle
              title={`Settings of ${levelData.title}`}
              onClose={() => {
                levelData.handleOpenSettings();
                levelData.submitted = false;
                this.newFile = null;
                this.setState({ imgSrc: this.state.actualImageSrc });
                this.props.store.isEditting = false;
              }}
            />
            <DialogContent>
              <TextField
                label="Name"
                margin="normal"
                value={levelData.newTitle}
                error={!!levelData.valid.errors.first('name')}
                helperText={levelData.valid.errors.first('name')}
                fullWidth
                variant="outlined"
                onChange={(e) =>  { levelData.newTitle = e.target.value; }}
              />
              {levelData.level === 2 && (
                <div className={styles.modalContentActions}>
                  {this.state.imgSrc !== '' && (
                    <img
                      className={styles.blueprint}
                      src={this.state.imgSrc}
                      alt="Blueprint"
                    />
                  )}
                  <div
                    className={styles.uploadBlueprintWrapper}
                    style={{right: this.state.imgSrc !== '' && '10px', bottom: this.state.imgSrc !== '' && '10px'}}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      component="label"
                    >
                      {this.state.loadingBlueprint !== '' ? 'Upload blueprint' : 'Change blueprint'}
                      <input
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={(e) => this.handleUpload(e.target.files[0])}
                      />
                    </Button>
                  </div>
                </div>
              )}
            </DialogContent>
            <div className={styles.modalSettingsActions}>
              <div className={styles.buttonWrapper}>
                <Button
                  size="small"
                  onClick={() => {
                    levelData.handleOpenSettings();
                    levelData.submitted = false;
                    this.newFile = null;
                    this.setState({ imgSrc: this.state.actualImageSrc });
                    this.props.store.isEditting = false;
                  }}
                  color="primary"
                >
                  cancel
                </Button>
              </div>
              <div className={styles.buttonWrapper}>
                <SaveButton
                  type='submit'
                  long
                />
              </div>
            </div>
            </form>
          </Dialog>
        )}
      </Observer>
    );
  }
}

export default ModalSettings;
