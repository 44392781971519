import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { injectIntl, FormattedMessage } from "react-intl";

import SignLayout from "./SignLayout";

import { Paper, Typography, Button, TextField, Snackbar } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import styles from "./SignLayout.module.scss";

@inject("store")
@observer
class LostPassword extends Component {
  handleChange = prop => event => {
    this.props.store.lostPassword.form.set(prop, event.target.value);
  };

  state = {
    snackbar: null,
  }

  componentDidMount() {
    this.props.store.lostPassword.init();
  }

  render() {
    const s = this.props.store.lostPassword;

    return (
      <SignLayout history={this.props.history}>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={Boolean(this.state.snackbar)}
          autoHideDuration={3000}
          message={this.state.snackbar}
        />

        <Paper elevation={2} className={styles.paper}>
          <Typography variant="h1" gutterBottom>
            <FormattedMessage id="sign.lostPassword.heading" />
          </Typography>
          {s.secondStep === false &&
            <form
              className={styles.form}
              onSubmit={e => {
                e.preventDefault();
                s.initLostPassword();

                if (s.validFirstStep.passes()) {
                  this.setState({ snackbar: 'We have sent you an e-mail with password reset link.' });
                }
              }}
            >
              <TextField
                label={this.props.intl.formatMessage({ id: "sign.label.email" })}
                fullWidth
                margin="normal"
                variant="outlined"
                error={!!s.validFirstStep.errors.first("email")}
                helperText={s.validFirstStep.errors.first("email")}
                onChange={this.handleChange("email")}
                value={s.form.email}
              />
              <Button
                variant="contained"
                color="secondary"
                className={styles.signButton}
                type="submit"
              >
                <FormattedMessage id="sign.lostPassword.cta" />
                <NavigateNextIcon />
              </Button>
            </form>
          }
          {s.secondStep === true &&
            <form
              className={styles.form}
              onSubmit={e => {
                e.preventDefault();
                s.finishLostPassword();
              }}
            >
              <TextField
                label={this.props.intl.formatMessage({ id: "sign.label.email" })}
                fullWidth
                margin="normal"
                variant="outlined"
                error={!!s.validSecondStep.errors.first("email")}
                helperText={s.validSecondStep.errors.first("email")}
                onChange={this.handleChange("email")}
                value={s.form.get('email')}
              />
              <TextField
                label={this.props.intl.formatMessage({ id: "sign.label.token" })}
                fullWidth
                margin="normal"
                variant="outlined"
                error={!!s.validSecondStep.errors.first("token")}
                helperText={s.validSecondStep.errors.first("token")}
                onChange={this.handleChange("token")}
                value={s.form.get('token')}
              />
              <TextField
                label={this.props.intl.formatMessage({
                  id: "sign.label.password"
                })}
                fullWidth
                margin="normal"
                variant="outlined"
                type="password"
                error={!!s.validSecondStep.errors.first("password")}
                helperText={s.validSecondStep.errors.first("password")}
                onChange={this.handleChange("password")}
                value={s.form.get('password')}
              />
              <TextField
                label={this.props.intl.formatMessage({
                  id: "sign.label.passwordAgain"
                })}
                fullWidth
                margin="normal"
                variant="outlined"
                type="password"
                onChange={this.handleChange("password_confirmation")}
                value={s.form.get('password_confirmation')}
              />
              <Button
                variant="contained"
                color="secondary"
                className={styles.signButton}
                type="submit"
              >
                <FormattedMessage id="sign.lostPassword.cta" />
                <NavigateNextIcon />
              </Button>
            </form>
          }
        </Paper>
      </SignLayout>
    );
  }
}

export default injectIntl(LostPassword);
