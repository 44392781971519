import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const ASTRAL_TIME_SHIFT = 60;
const ASTRAL_DOUBLE_TIME_SHIFT = 120;

const getAstralMiddle = (winterSunAction, summerSunAction) => {
  return Math.round((winterSunAction + summerSunAction) / 2);
};

export const addAstrals = (values, points) => {
  const toMinutes = (value) => dayjs(value).utc().hour() * 60 + dayjs(value).utc().minute();

  const astralBounds = window.Store.currentNetwork.astralBounds;
  const sunriseSummer = toMinutes(astralBounds.sunriseSummer);
  const sunriseWinter = toMinutes(astralBounds.sunriseWinter);
  const sunsetSummer = toMinutes(astralBounds.sunsetSummer);
  const sunsetWinter = toMinutes(astralBounds.sunsetWinter);

  const astralMiddleSunrise = getAstralMiddle(sunriseWinter, sunriseSummer);
  const astralMiddleSunset = getAstralMiddle(sunsetWinter, sunsetSummer);

  const filteredValues =
    filter(values, ({ x }) => (x < sunriseSummer || x > sunriseWinter) && (x > sunsetSummer || x < sunsetWinter));
    filteredValues.push(
      { x: sunriseSummer, y: 50, type: 'astralStart' },
      { x: astralMiddleSunrise - ASTRAL_DOUBLE_TIME_SHIFT, y: points.X2BeforeSunrise, type: 'X2BeforeSunrise' },
      { x: astralMiddleSunrise - ASTRAL_TIME_SHIFT, y: points.XBeforeSunrise, type: 'XBeforeSunrise' },
      { x: astralMiddleSunrise, y: points.sunrise, type: 'sunrise' },
      { x: astralMiddleSunrise + ASTRAL_TIME_SHIFT, y: points.GoldenHourSunrise, type: 'GoldenHourSunrise' },
      { x: sunriseWinter, y: 50, type: 'astral' },
      { x: sunsetWinter, y: 50, type: 'astralStart' },
      { x: astralMiddleSunset - ASTRAL_TIME_SHIFT, y: points.GoldenHourSunset, type: 'GoldenHourSunset' },
      { x: astralMiddleSunset, y: points.sunset, type: 'sunset' },
      { x: astralMiddleSunset + ASTRAL_TIME_SHIFT, y: points.XAfterSunset, type: 'XAfterSunset' },
      { x: astralMiddleSunset + ASTRAL_DOUBLE_TIME_SHIFT, y: points.X2AfterSunset, type: 'X2AfterSunset' },
      { x: sunsetSummer, y: 50, type: 'astral' }
    );

  return sortBy(filteredValues, 'x');
};

export const removeAstrals = (values) => {
  return filter(values, ({ type }) => type === 'classic');
};
