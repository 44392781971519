import React from 'react';
import { observer, inject } from 'mobx-react';

import {
  AppBar,
  Toolbar,
  Button,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Badge,
  IconButton,
  ListItemSecondaryAction,
  ListItem,
} from '@material-ui/core';

import classNames from 'classnames';

import Breadcrumbs from './Breadcrumbs';
import Navigator from '../shared/helpers/navigation';

import AccountCircle from '@material-ui/icons/AccountCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AppsIcon from '@material-ui/icons/Apps';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuIcon from '@material-ui/icons/Menu';

import styles from './TopMenu.module.scss';

import { signOut } from '../shared/endpoints';

@inject('store')
@observer
class TopMenu extends React.Component {
  state = {
    userAnchor: null,
    networkAnchor: null,
    notifyAnchor: null,
  };

  closeUserMenu = () => this.setState({ userAnchor: null });
  closeNetworkMenu = () => this.setState({ networkAnchor: null });
  closeNotifyMenu = () => this.setState({ notifyAnchor: null });

  async componentDidMount() {
    await window.Store.setAccessToken();
  }

  render() {
    const s = this.props.store;
    const networks = s.networks;
    return (
      <AppBar position="fixed" className={classNames(styles.appbar, 'webview-hidden')}>
        <Toolbar>
          <div className="flex-grow">
            <div onClick={() => s.menuOpen = true} className="hidden-desktop">
              <MenuIcon />
            </div>

            <Breadcrumbs />
          </div>
          <div className={styles.actions}>
            <Button onClick={(e) => s.notifications.length && this.setState({ notifyAnchor: e.currentTarget })}>
              <Badge badgeContent={s.notifications.length} color="secondary">
                <NotificationsIcon />
              </Badge>
            </Button>
            <Button onClick={(e) => this.setState({ networkAnchor: e.currentTarget })}>
              <span className="hidden-mobile">{s.currentNetwork && s.currentNetwork.name}</span>
              <AppsIcon className="ml-1" />
            </Button>
            <Button onClick={(e) => this.setState({ userAnchor: e.currentTarget })}>
              <span className="hidden-mobile">{s.user}</span>
              <AccountCircle className="ml-1" />
            </Button>
          </div>
        </Toolbar>

        <Menu
          anchorEl={this.state.notifyAnchor}
          onClose={this.closeNotifyMenu}
          open={Boolean(this.state.notifyAnchor)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          {s.notifications.map((notify, index) => (
            <ListItem key={index}>
              {notify.severity === 1 && (
                <ListItemIcon>
                  <WarningIcon color="error" />
                </ListItemIcon>
              )}
              {notify.severity === 0 && (
                <ListItemIcon>
                  <InfoIcon color="primary" />
                </ListItemIcon>
              )}
              <ListItemText primary={notify.message} style={{ paddingRight: '100px' }} />
              <ListItemSecondaryAction>
                <div className="d-flex align-items-center">
                  {notify.action &&
                    <Button
                      variant="contained"
                      size="small"
                      color="secondary"
                      onClick={() => {
                        notify.action();
                        s.notifications.splice(index, 1);
                        if (!s.notifications.length) {
                          this.closeNotifyMenu();
                        }
                      }}
                    >
                      {notify.actionLabel}
                    </Button>
                  }
                  <IconButton aria-label="Delete" onClick={() => {
                    if (notify.deleteAction) {
                      notify.deleteAction();
                    }

                    s.notifications.splice(index, 1);
                    if (!s.notifications.length) {
                      this.closeNotifyMenu();
                    }
                  }}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </Menu>

        <Menu
          anchorEl={this.state.networkAnchor}
          onClose={this.closeNetworkMenu}
          open={Boolean(this.state.networkAnchor)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          {s.currentNetwork && networks.map(network => (
            <MenuItem
              onClick={() => {
                s.setNetwork(network);

                Navigator._navigator.push('/refresh');
                setTimeout(() => Navigator.push('ControlCenter'));
              }}
              key={network.id}
            >
              <ListItemText primary={network.name} />
            </MenuItem>
          ))}
          <Divider />
          <MenuItem onClick={() => Navigator.push('NetworkSettings')}>
            <ListItemIcon><SettingsIcon /></ListItemIcon>
            <ListItemText primary="Network settings" />
          </MenuItem>
        </Menu>

        <Menu
          anchorEl={this.state.userAnchor}
          onClose={this.closeUserMenu}
          open={Boolean(this.state.userAnchor)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <MenuItem onClick={() => Navigator.push('Profile')}>
            <ListItemIcon><SettingsIcon /></ListItemIcon>
            <ListItemText primary="Edit profile" />
          </MenuItem>
          <MenuItem onClick={() => {
            signOut();
            Navigator.push('SignIn');
          }}>
            <ListItemText primary="Sign Out" />
          </MenuItem>
        </Menu>
      </AppBar>
    );
  }
}

export default TopMenu;
