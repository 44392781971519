import React from 'react';
import {
  Paper,
  Typography
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

import { Observer } from 'mobx-react';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import HierarchyDevice from './HierarchyDevice';
import ModalSettings from './modalSettings';
import ModalDeleteLevel from './modalDeleteLevel';
import Modal from './Modal';

import styles from './HierarchyTree.module.scss';

class HierechyLevel extends React.Component {
  render() {
    return (
      <Draggable
        draggableId={this.props.levelData.DnD_Id}
        index={this.props.index}
        isDragDisabled={this.props.levelData.id === 'Gateways' && true}
      >
        {(provided) => {
          const position = provided.draggableProps.style.position;
          return (
            <Observer>
              {() => (
                <div
                id={this.props.levelData.DnD_Id}
                className={styles.levelDraggable}
                key={this.props.index}
                {...provided.draggableProps}
                ref={provided.innerRef}
              >
                <div
                  className={styles.draggableContentWrapper}
                >
                  <div className={styles.hierarchyLine}
                    style={{
                      display: position === 'fixed' && 'none',
                    }}
                  />
                  <div
                    className={styles.hierarchyCurve}
                    style={{
                      display: position === 'fixed' && 'none',
                    }}
                  >
                    <svg>
                      <path fill='none' stroke='#9D9D9B' strokeWidth='3px' d='M 24.5 0 L 24.5 30 Q 24.5 40 35 40 L 50 40' />
                    </svg>
                  </div>
                  <div
                    className={styles.paperSection}
                  >
                    <Paper {...provided.dragHandleProps}>
                      <div className={styles.paperFill}>
                        <div className={styles.nameSection}>
                          <Typography variant='h2'>{this.props.levelData.title}</Typography>
                        </div>
                        <div className={styles.iconSection}>
                          <IconButton onClick={() => {
                            this.props.levelData.handleOpenSettings();
                            this.props.dataTree.isEditting = true;
                          }}>
                            <SettingsIcon />
                          </IconButton>
                          <ModalSettings
                            data={this.props.levelData}
                            store={this.props.dataTree}
                          />
                          <IconButton onClick={() => {
                            this.props.levelData.handleModalDelete();
                            this.props.dataTree.isEditting = true;
                          }}>
                            <DeleteIcon />
                          </IconButton>
                          <ModalDeleteLevel
                            data={this.props.levelData}
                            store={this.props.dataTree}
                          />
                        </div>
                      </div>
                    </Paper>
                  </div>
                </div>
                {this.props.levelData.isExpanded === true && (
                  <>
                    <Droppable
                      droppableId={this.props.levelData.DnD_Id}
                      isDropDisabled={this.props.levelData.droppDisabled}
                    >
                      {(provided) => {
                        return (
                          <Observer>
                            {() => (
                              <div
                                className={styles.levelDroppable}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                  background: !this.props.levelData.droppDisabled && 'rgba(187, 176, 151, 0.3)',
                                  marginBottom: !this.props.levelData.droppDisabled && '10px'
                                }}
                              >
                              <div />
                                {this.props.levelData.children && this.props.levelData.children.map((level, index) => {
                                  let last;
                                  if (this.props.levelData.children.length === 1) {
                                      last = false;
                                    } else {
                                      last = index === this.props.levelData.children.length - 1;
                                    }
                                    if (level.level <= 4) {
                                      return (
                                        <HierechyLevel
                                          key={level.id}
                                          dataTree={this.props.dataTree}
                                          levelData={level}
                                          index={index}
                                          isLast={last}
                                          isOnlyChild={this.props.levelData.children.length === 1}
                                          getChildren={(id, data) => this.props.getChildren(id, data)}
                                          handleModal={(id) => this.props.levelData.handleModal(id)}
                                        />
                                      );
                                    } else {
                                      return (
                                        <HierarchyDevice
                                          key={level.id}
                                          dataTree={this.props.dataTree}
                                          levelData={level}
                                          index={index}
                                          isOnlyChild={this.props.levelData.children.length === 1}
                                        />
                                      );
                                    }
                                })}
                              {provided.placeholder}
                            </div>
                            )}
                          </Observer>
                        );
                      }}
                    </Droppable>
                  </>
                )}
                {this.props.levelData.isExpanded === true && (
                  <div className={styles.addButtonWrapper}>
                    <Modal
                      data={this.props.levelData}
                      store={this.props.dataTree}
                    />
                  </div>
                )}

              </div>
              )}
            </Observer>
          );
        }}
      </Draggable>
    );
  }
}


export default HierechyLevel;
