import React from 'react';
import { observer, inject } from 'mobx-react';
import { injectIntl, FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';

import styles from './SignLayout.module.scss';

import logo from '../../shared/images/logo-inverse.png';
import { Typography } from '@material-ui/core';

import Navigator from '../../shared/helpers/navigation';

@inject('store')
@observer
class SignLayout extends React.Component {
  render() {
    return (
      <div className={styles.layout}>
        <div className={styles.breadcrumbs}>
          <Typography variant="h1">
            <span
              onClick={() => Navigator.push('SignIn')}
              className={styles.link}
            >
              <FormattedMessage id="sign.home"/>
            </span>{" "}
            <FormattedMessage id={`path.${this.props.history.location.pathname}`}/>
          </Typography>
        </div>
        <img src={logo} className={styles.logo} alt="Suntense" />

        {this.props.children}

        <div className={styles.footnote}>
          <Typography variant="body1">© {dayjs().format('YYYY')} ESYST</Typography>
          <Typography
            variant="body1"
            onClick={() => Navigator.push('TermsOfUse')}
            className={styles.link}
          >
            <FormattedMessage id="path./terms-of-use"/>
          </Typography>
        </div>
      </div>
    );
  }
}

export default injectIntl(SignLayout);
