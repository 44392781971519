import { observable } from 'mobx';

export default class Loadable {
  @observable loaded = false;
  @observable loadingError = false;

  resetLoadable = () => {
    this.loaded = false;
    this.loadingError = false;
  };
}
