import React, { useState } from 'react';
import Draggable from 'react-draggable';
import { KEY_DELETE } from 'keycode-js';

import { AddAlarm, AccessAlarm } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import styles from './Chart.module.scss';

const CLOCK_WIDTH = 16;

const AlarmBar = ( props ) => {
  const [offsetX, setOffsetX] = useState(0);
  const [displayAddAlarm, setDisplayAddAlarm] = useState();

  const minuteStep = (props.chartWidth - (CLOCK_WIDTH)) / props.duration;

  const handleMouseMove = (e) => {
    setOffsetX(e.nativeEvent.layerX);
  };

  const handleKeyDown = (e, index) => {
    if (e.keyCode === KEY_DELETE) {
      props.onDelete(index);
    }
  };

  const handleClickDelete = (index) => {
    props.onDelete(index);
  };

  const handleMouseOver = () => {
    setDisplayAddAlarm(true);
  };

  const handleMouseOverAlarm = () => {
    setDisplayAddAlarm(false);
  };

  const handleMouseLeave = () => {
    setDisplayAddAlarm(false);
  };

  const handleClick = (e) => {
    const x = e.nativeEvent.layerX / props.pixelsXRatio;
    const newAlarm = {
      xOffset: x,
    };
    props.onAlarmsChange([...props.alarms, newAlarm]);
  };

  return (
    <div className={styles.alarmBarWrapper} style={{ width: `${props.chartWidth}px` }}>
      <div
        className={styles.alarmBarline}
        onMouseMove={handleMouseMove}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        style={{ width: `${props.chartWidth}px` }}
      />

      {props.alarms && props.alarms.map((alarm, index) => {
        const offsetX = alarm.xOffset * props.pixelsXRatio;
        return (
          <Draggable
            key={index}
            bounds={"parent"}
            axis = "x"
            onDrag={(e, position) => props.handleChange(position.x, index)}
            position={{ x: offsetX, y: 0 }}
          >
            <button
              className={styles.Alarm}
              onMouseOver={handleMouseOverAlarm}
              onMouseLeave={handleMouseLeave}
              onKeyDown={(e) => handleKeyDown(e, index)}
            >
              <div className={styles.alarmDelete} onClick={() => handleClickDelete(index)}>
                x
              </div>
              <div className={styles.alarmTime} >
                <Typography
                  className={styles.timeLabelText}
                  variant="caption"
                >
                  {props.timeFormatter(offsetX / minuteStep)}
                </Typography>
              </div>
              <AccessAlarm />
              <div className={styles.pointLine} />
            </button>
          </Draggable>
        );
      })}

      <div
        className={styles.addAlarm}
        style={{left: `${offsetX}px`, display: displayAddAlarm ? 'block': 'none'}}
      >
        <AddAlarm />
      </div>
    </div>
  );
};

export default AlarmBar;
