import React from 'react';
import styles from './DialogTitle.module.scss';

import {
  IconButton,
  Typography,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

class DialogTitle extends React.Component {
  render() {
    return (
      <MuiDialogTitle className={styles.titleSection}>
        <Typography variant="h1">{this.props.title}</Typography>
        <IconButton aria-label="close" onClick={this.props.onClose}>
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
    );
  }
}

export default DialogTitle;
