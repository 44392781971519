import { observable, action, flow, computed } from 'mobx';
import fromPairs from 'lodash/fromPairs';
import find from 'lodash/find';
import debounce from 'lodash/debounce';
import map from 'lodash/map';
import dayjs from 'dayjs';

import Loadable from './extendable/loadable';
import some from 'lodash/some';
import { addAstrals, removeAstrals } from './extendable/astrable';
import { DAY, WEEK, MONTH, YEAR, NO_REPEAT } from './extendable/schedulable';
import { DayLength } from '../constants';

import { patchAutoMode, fetchAutoMode, fetchRoom } from '../endpoints';

const defaultIntervals = () => [[600, 840]];
const defaultPoints = () => [
  { x: 0, y: 0, type: 'classic' },
  { x: DayLength, y: 0, type: 'classic'},
];
const defaultTransition = () => [
  { x: 0, y: 100, type: 'classic' },
  { x: 300, y: 0, type: 'classic '},
];

const serializeIntervals = (intervals) => intervals.map(interval => ({ start: interval[0], end: interval[1] }));
const serializePoints = (points) => points.filter(point => point.type === 'classic').map(({ x, y }) => ({ time: x, value: y }));
const deserializePoints = (points) =>
  points.map(({ time, value }) => ({ type: 'classic', y: value, x: time }));
const deserializeIntervals = (intervals) => intervals.map(({ start, end }) => [start, end]);

class Item {
  @observable id = 0;
  parent = null;
  store = null;

  @observable name = '';
  @observable astralEnabled = false;
  @observable motionEnabled = false;
  @observable motionIntervals = defaultIntervals();
  @observable motionTransition = defaultTransition();
  @observable nightLightEnabled = false;
  @observable nightLightIntervals = defaultIntervals();
  @observable brightness = defaultPoints();
  @observable chromaticity = defaultPoints();
  @observable alarms = [];
  @observable capabilities = observable.map({
    brightness: false,
    chromaticity: false,
    uvA: false,
    uvC: false,
  });

  pointsBrightness = {
    'X2BeforeSunrise': 50,
    'XBeforeSunrise': 50,
    'sunrise': 50,
    'GoldenHourSunrise': 50,
    'GoldenHourSunset': 50,
    'sunset': 50,
    'XAfterSunset': 50,
    'X2AfterSunset': 50,
  };

  pointsChromaticity = {
    'X2BeforeSunrise': 50,
    'XBeforeSunrise': 50,
    'sunrise': 50,
    'GoldenHourSunrise': 50,
    'GoldenHourSunset': 50,
    'sunset': 50,
    'XAfterSunset': 50,
    'X2AfterSunset': 50,
  };

  constructor(entity, configuration, parent, store) {
    this.init(entity, configuration, parent, store);
  }

  init = (entity, configuration, parent, store) => {
    this.id = entity.id;
    this.name = entity.name;

    this.parent = parent;
    this.store = store;

    if (configuration) {
      if (Array.isArray(configuration.brightness)) {
        this.brightness = deserializePoints(configuration.brightness);
      }

      if (Array.isArray(configuration.chromaticity)) {
        this.chromaticity = deserializePoints(configuration.chromaticity);
      }

      if (configuration.astral) {
        if (configuration.astral.pointsBrightness && configuration.astral.pointsChromaticity) {

          this.getAstralPointsData(configuration.astral.pointsBrightness, this.pointsBrightness);
          this.getAstralPointsData(configuration.astral.pointsChromaticity, this.pointsChromaticity);
        }

        this.setAstralEnabled(!!configuration.astral.enabled, false);
      }

      if (configuration.motion) {
        this.motionEnabled = !!configuration.motion.reaction_enabled;

        if (Array.isArray(configuration.motion.reaction_intervals)) {
          this.motionIntervals = deserializeIntervals(configuration.motion.reaction_intervals);
        }

        if (configuration.motion.transition) {
          this.motionTransition = map(configuration.motion.transition, (y, x) => ({ x: parseInt(x), y }));
        }
      }

      if (configuration.night_light) {
        this.nightLightEnabled = !!configuration.night_light.enabled;

        if (Array.isArray(configuration.night_light.intervals)) {
          this.nightLightIntervals = deserializeIntervals(configuration.night_light.intervals);
        }
      }

      if (configuration.alarms) {
        this.alarms = configuration.alarms.map((alarm) => ({ xOffset: alarm }));
      }
    }
  };

  getAstralPointsData (serverData, storeData) {
    for (const [serverKey, serverValue] of Object.entries(serverData)) {
      for (const [storeKey, storeValue] of Object.entries(storeData)) {
        if (serverKey === storeKey) {
          storeData[storeKey] = serverValue;
        } else {
          storeData[storeKey] = storeValue;
        }
      }
    }
  }

  serializeConfiguration = () => {
    return {
      brightness: serializePoints(this.brightness),
      chromaticity: serializePoints(this.chromaticity),
      night_light: {
        enabled: this.nightLightEnabled,
        intervals: serializeIntervals(this.nightLightIntervals),
      },
      motion: {
        reaction_enabled: this.motionEnabled,
        reaction_intervals: serializeIntervals(this.motionIntervals),
        transition: fromPairs(this.motionTransition.map(({ x, y }) => [x, y])),
      },
      alarms: this.alarms.map((alarm) => alarm.xOffset),
      astral: {
        enabled: this.astralEnabled,
        ...(this.astralEnabled ? {
          pointsBrightness : this.getAstralPointValue('brightness'),
          pointsChromaticity : this.getAstralPointValue('chromaticity')
        } : {}),
      },
    };
  };

  @action
  getAstralPointValue = (pointSet) => {
    if (pointSet === 'brightness') {
      this.pointsBrightness = {
        X2BeforeSunrise: this.brightness.filter(point => point.type === 'X2BeforeSunrise')[0].y,
        XBeforeSunrise: this.brightness.filter(point => point.type === 'XBeforeSunrise')[0].y,
        sunrise: this.brightness.filter(point => point.type === 'sunrise')[0].y,
        GoldenHourSunrise: this.brightness.filter(point => point.type === 'GoldenHourSunrise')[0].y,
        GoldenHourSunset: this.brightness.filter(point => point.type === 'GoldenHourSunset')[0].y,
        sunset: this.brightness.filter(point => point.type === 'sunset')[0].y,
        XAfterSunset: this.brightness.filter(point => point.type === 'XAfterSunset')[0].y,
        X2AfterSunset: this.brightness.filter(point => point.type === 'X2AfterSunset')[0].y,
      };

      return this.pointsBrightness;
    } else {
      this.pointsChromaticity = {
        X2BeforeSunrise: this.chromaticity.filter(point => point.type === 'X2BeforeSunrise')[0].y,
        XBeforeSunrise: this.chromaticity.filter(point => point.type === 'XBeforeSunrise')[0].y,
        sunrise: this.chromaticity.filter(point => point.type === 'sunrise')[0].y,
        GoldenHourSunrise: this.chromaticity.filter(point => point.type === 'GoldenHourSunrise')[0].y,
        GoldenHourSunset: this.chromaticity.filter(point => point.type === 'GoldenHourSunset')[0].y,
        sunset: this.chromaticity.filter(point => point.type === 'sunset')[0].y,
        XAfterSunset: this.chromaticity.filter(point => point.type === 'XAfterSunset')[0].y,
        X2AfterSunset: this.chromaticity.filter(point => point.type === 'X2AfterSunset')[0].y,
      };

      return this.pointsChromaticity;
    }
  };

  @action
  setAstralEnabled = (astralEnabled, patch = true) => {
    this.astralEnabled = astralEnabled;

    if (astralEnabled) {
      this.brightness = addAstrals(
        this.brightness,
        this.pointsBrightness,
        );
      this.chromaticity = addAstrals(
        this.chromaticity,
        this.pointsChromaticity,
      );
    } else {
      this.brightness = removeAstrals(this.brightness);
      this.chromaticity = removeAstrals(this.chromaticity);
    }

    if (patch) {
      this.store.patch();
    }
  };

  @action
  setMotionEnabled = (motionEnabled) => {
    this.motionEnabled = motionEnabled;

    this.store.patch();
  };

  @action
  setMotionIntervals = (motionIntervals) => {
    this.motionIntervals = motionIntervals;

    this.store.patch();
  };

  @action
  setNightLightEnabled = (nightLightEnabled) => {
    this.nightLightEnabled = nightLightEnabled;

    this.store.patch();
  };

  @action
  setNightLightIntervals = (nightLightIntervals) => {
    this.nightLightIntervals = nightLightIntervals;

    this.store.patch();
  };

  @action
  setBrightness = (brightness) => {
    this.brightness = brightness;

    this.store.patch();
  };

  @action
  setChromaticity = (chromaticity) => {
    this.chromaticity = chromaticity;

    this.store.patch();
  };

  @action
  setAlarms = (alarms) => {
    this.alarms = alarms;

    this.store.patch();
  };

  @action
  setMotionTransition = (motionTransition) => {
    this.motionTransition = motionTransition;

    this.store.patch();
  };

  @action
  setMotionDuration = (duration) => {
    const parsedDuration = parseInt(duration);
    const durationInSeconds = parsedDuration ? parsedDuration * 60 : 60;

    const durationRatio = durationInSeconds / this.motionTransition[this.motionTransition.length - 1].x;

    this.motionTransition[this.motionTransition.length - 1].x = durationInSeconds;

    this.motionTransition.forEach((point, index) => {
      if (index !== 0 && index !== this.motionTransition.length - 1) {
        this.motionTransition[index].x = Math.round(durationRatio * this.motionTransition[index].x);
      }
    });

    this.store.patch();
  };
}

class Room extends Item {
  @observable groups = [];
  level = 'room';

  @observable uvCEnabled = false;
  @observable uvAEnabled = false;
  @observable uvCPeriod = 0;
  @observable uvCTimer = 0;
  @observable uvAIntervals = defaultIntervals();
  @observable uvCIntervals = defaultIntervals();
  @observable name = '';

  constructor(entity, configuration, store) {
    super(entity, configuration?.configuration, null, store);
    this.init(entity, configuration?.configuration, store);
  }

  init = (entity, configuration, store) => {
    this.name = entity.name;

    if (configuration.uv_c) {
      this.uvCEnabled = !!configuration.uv_c.enabled;
      this.uvCPeriod = configuration.uv_c.period ? configuration.uv_c.period : 0;
      this.uvCTimer = configuration.uv_c.timer ? configuration.uv_c.timer : 0;

      if (Array.isArray(configuration.uv_c.intervals)) {
        this.uvCIntervals = deserializeIntervals(configuration.uv_c.intervals);
      }
    }

    if (configuration.uv_a) {
      this.uvAEnabled = !!configuration.uv_a.enabled;

      if (Array.isArray(configuration.uv_a.intervals)) {
        this.uvAIntervals = deserializeIntervals(configuration.uv_a.intervals);
      }
    }

    entity.groups.forEach((group) => {
      const groupConfiguration = configuration && find(configuration.groups, { id: group.id });
      this.groups.push(new Group(group, groupConfiguration, this, store));
    });

    this.capabilities.set('brightness', some(this.groups, group => group.capabilities.get('brightness')));
    this.capabilities.set('chromaticity', some(this.groups, group => group.capabilities.get('chromaticity')));
    this.capabilities.set('uvA', some(this.groups, group => group.capabilities.get('uvA')));
    this.capabilities.set('uvC', some(this.groups, group => group.capabilities.get('uvC')));
  };

  serialize = () => {
    return {
      configuration: {
        ...this.serializeConfiguration(),
        uv_c: {
          enabled: this.uvCEnabled,
          intervals: serializeIntervals(this.uvCIntervals),
          period: this.uvCPeriod,
          timer: this.uvCTimer,
        },
        uv_a: {
          enabled: this.uvAEnabled,
          intervals: serializeIntervals(this.uvAIntervals),
        },
        groups: this.groups.map(group => group.serialize()),
      },
    };
  };

  @action
  setUvCEnabled = (uvCEnabled) => {
    this.uvCEnabled = uvCEnabled;

    this.store.patch();
  };

  @action
  setUvAIntervals = (uvAIntervals) => {
    this.uvAIntervals = uvAIntervals;

    this.store.patch();
  };

  @action
  setUvAEnabled = (uvAEnabled) => {
    this.uvAEnabled = uvAEnabled;

    this.store.patch();
  };

  @action
  setUvCIntervals = (uvCIntervals) => {
    this.uvCIntervals = uvCIntervals;

    this.store.patch();
  };

  @action
  setUvC = (which, value) => {
    if (which === 'howOften') {
      this.uvCPeriod = value;
    }

    if (which === 'howLong') {
      this.uvCTimer = value;
    }

    this.store.patch();
  };
}

class Group extends Item {
  @observable lights = [];
  @observable inherit = true;

  level = 'group';

  constructor(entity, configuration, parent, store) {
    super(entity, configuration?.configuration, parent, store);
    this.init(entity, configuration, store);
  }

  init = (entity, configuration, store) => {
    this.inherit = configuration && 'inherit' in configuration ? configuration.inherit : true;

    entity.lights.forEach((light) => {
      const lightConfiguration =
        configuration && configuration.configuration && find(configuration.configuration.lights, { id: light.id });
      this.lights.push(new Light(light, lightConfiguration, this, store));
    });

    this.capabilities.set('brightness', some(this.lights, light => light.capabilities.get('brightness')));
    this.capabilities.set('chromaticity', some(this.lights, light => light.capabilities.get('chromaticity')));
    this.capabilities.set('uvA', some(this.lights, light => light.capabilities.get('uvA')));
    this.capabilities.set('uvC', some(this.lights, light => light.capabilities.get('uvC')));
  };

  serialize = () => {
    return {
      id: this.id,
      inherit: this.inherit,
      configuration: {
        ...this.serializeConfiguration(),
        lights: this.lights.map(light => light.serialize()),
      },
    };
  };

  @action
  setInherit = (inherit) => {
    this.inherit = inherit;

    this.store.patch();
  };
}

class Light extends Item {
  @observable inherit = true;
  level = 'light';

  constructor(entity, configuration, parent, store) {
    super(entity, configuration?.configuration, parent, store);
    this.init(entity, configuration);
  }

  init = (entity, configuration) => {
    this.inherit = configuration && 'inherit' in configuration ? configuration.inherit : true;

    this.capabilities.set('brightness', entity.capabilities.Brightness);
    this.capabilities.set('chromaticity', entity.capabilities.Chromaticity);
    this.capabilities.set('uvA', entity.capabilities.UV_A);
    this.capabilities.set('uvC', entity.capabilities.UV_C);
  };

  serialize = () => {
    return {
      id: this.id,
      inherit: this.inherit,
      configuration: this.serializeConfiguration(),
    };
  };

  @action
  setInherit = (inherit) => {
    this.inherit = inherit;

    this.store.patch();
  };
}

export class AutoModeSettings extends Loadable {
  @observable room = null;
  @observable name = '';
  @observable configurationId = null;
  @observable type = 0;
  @observable repeatType = NO_REPEAT;
  @observable scheduledAt = dayjs();
  @observable repeat = {};

  init = flow(function* (roomId, configurationId) {
    this.loaded = false;
    this.room = null;
    this.name = '';
    this.type = 0;

    this.repeatType = NO_REPEAT;
    this.scheduledAt = dayjs();
    this.repeat = {};

    this.configurationId = configurationId;

    try {
      const configuration = (yield fetchAutoMode(configurationId)).data.data;
      const room = (yield fetchRoom(roomId)).data.data;

      this.name = configuration.name;
      this.type = configuration.type;
      this.repeatType = configuration.repeat_type;
      this.scheduledAt = dayjs(configuration.scheduled_at);
      this.repeat = configuration.repeat;

      this.room = new Room(room, configuration, this);

      this.loaded = true;
    } catch (error) {
      window.Store.handleError('autoMode.fetch');
      throw error;
    }
  }.bind(this));

  patch = debounce(flow(function* () {
    try {
      const serialized = this.room.serialize();
      yield patchAutoMode(this.configurationId, {
        name: this.name,
        scheduled_at: this.scheduledAt,
        repeat_type: this.repeatType,
        repeat: this.repeat,
        ...serialized,
      });
    } catch (error) {
      window.Store.handleError('autoMode.update');
      throw error;
    }
  }.bind(this)), 1000);

  setName = (name) => {
    this.name = name;
    this.patch();
  };

  setScheduledAt = (date) => {
    this.scheduledAt = date;
    this.patch();
  };

  setScheduling = ({ repeatType = null, repeat = null }) => {
    if (repeatType !== null) {
      this.repeatType = repeatType;
    }

    if (repeat !== null) {
      this.repeat = repeat;
    }

    this.patch();
  };

  setWeekDay = (day, value) => {
    this.repeat.days[day] = value;

    this.patch();
  };

  @computed get repeatShortcuts() {
    return [
      {
        scheduledAt: this.scheduledAt,
        repeatType: NO_REPEAT,
      },
      {
        scheduledAt: this.scheduledAt,
        repeatType: DAY,
        repeat: {
          every: 1,
        },
      },
      {
        scheduledAt: this.scheduledAt,
        repeatType: WEEK,
        repeat: {
          every: 1,
          days: {
            monday: this.scheduledAt.day() === 1,
            tuesday: this.scheduledAt.day() === 2,
            wednesday: this.scheduledAt.day() === 3,
            thursday: this.scheduledAt.day() === 4,
            friday: this.scheduledAt.day() === 5,
            saturday: this.scheduledAt.day() === 6,
            sunday: this.scheduledAt.day() === 0,
          },
        },
      },
      {
        scheduledAt: this.scheduledAt,
        repeatType: MONTH,
        repeat: {
          every: 1,
          type: 1,
        },
      },
      {
        scheduledAt: this.scheduledAt,
        repeatType: YEAR,
        repeat: {
          every: 1,
        },
      },
    ];
  }

  @computed get repeatConfig() {
    return {
      repeatType: this.repeatType,
      scheduledAt: this.scheduledAt,
      repeat: this.repeat,
    };
  }
}
