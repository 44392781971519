import React from 'react';

import SignalWifiOffIcon from '@material-ui/icons/SignalWifiOff';
import BrightnessLowIcon from '@material-ui/icons/BrightnessLow';
import BrightnessMediumIcon from '@material-ui/icons/BrightnessMedium';
import BrightnessHighIcon from '@material-ui/icons/BrightnessHigh';

import styles from './LightIcon.module.scss';

import { BrightnessBounds } from '../../../shared/constants';

export default function LightIcon({ actualChromaticity, actualBrightness, alive, kelvinRange }) {
  if (!alive) {
    return <SignalWifiOffIcon className={styles.colorOffline} />;
  }
  let colorClass = '';
  if (actualChromaticity <= -50) {
    colorClass = 'color-100';
  } else if (actualChromaticity <= 0) {
    colorClass = 'color-0';
  } else if (actualChromaticity > 50) {
    colorClass = 'color100';

    if (kelvinRange === 1) {
      colorClass += '-higher';
    }
  } else {
    colorClass = 'color0';
  }

  if (actualBrightness === BrightnessBounds[0]) {
    return <BrightnessLowIcon className={styles[colorClass]} />;
  } else if (actualBrightness === BrightnessBounds[1]) {
    return <BrightnessHighIcon className={styles[colorClass]} />;
  }
  return <BrightnessMediumIcon className={styles[colorClass]} />;
}
