import React from 'react';
import { observer } from 'mobx-react';

import { Paper } from '@material-ui/core';

import Light from './Light';

import styles from './Hierarchy.module.scss';

@observer
class Group extends React.Component {
  render() {
    const elements = React.Children.toArray(this.props.children(this.props.group));

    return (
      <div className={styles.level}>
        {!this.props.isLast && <div className={styles.hierarchyLine} />}

        <div className={styles.hierarchyCurve}>
          <svg>
            <path fill='none' stroke='#9D9D9B' strokeWidth='3px' d='M 25 0 L 25 30 Q 25 40 35 40 L 50 40' />
          </svg>
        </div>
        <Paper className={styles.paper} data-test-id="groupPaper">
          {elements.map((child, index) => <div key={index}>{React.cloneElement(child)}</div>)}
        </Paper>
        {this.props.group.lights.map((light, index) => (
          <Light
            key={index}
            light={light}
            isLast={index === this.props.group.lights.length - 1}
          >
            {this.props.children}
          </Light>
        ))}
      </div>
    );
  }
}

export default Group;
