import { observable, flow, computed, action } from 'mobx';
import find from 'lodash/find';

import Loadable from './extendable/loadable';
import { fetchNetwork } from '../endpoints';
import Navigator from '../helpers/navigation';


export class ControlCenter extends Loadable {
  @observable views = [];
  @observable links = [];
  @observable diagnosticsOn = false;
  @observable modalOpen = false;
  @observable currentView = 0;
  @observable currentViewObject = {};
  @observable currentTab = 'blueprint'; // for mobile devices

  init = flow(function* () {
    this.reset();

    try {
      const network = yield fetchNetwork(window.Store.currentNetwork.id);

      if (network.data.data.views.length > 0 && network.data.data) {
        this.diagnosticsOn = network.data.data.diagnostics_on;
        this.currentView = network.data.data.views[0].id;
        this.replace(network.data.data.views, network.data.data.diagnosticsLinks);
        this.loaded = true;
      } else {
        Navigator.push('Settings');
      }


    } catch (error) {
      window.Store.handleError('view.init');
      this.loadingError = true;
      throw error;
    }
  }.bind(this));

  @action
  replace = (views, links, diagnosticsOn) => {
    this.views = [];
    this.links = links ? links : [];
    this.diagnosticsOn = diagnosticsOn;
    views.forEach((view) => {
      view.links = this.links;
      const entity = window.Store.registerEntity('view', view);
      this.views.push(entity);
    });

    this.currentViewObject = find(this.views, {id: this.currentView});
  };

  reset = () => {
    this.resetLoadable();
    this.views = [];
  };

  setCurrentView = (id) => {
    this.currentView = id;
    this.currentViewObject = find(this.views, { id });
  };

  @computed get view() {
    return this.currentView ? find(this.views, (view) => view.id === this.currentView) : this.views[0];
  }
}
