import React, {useState, useRef} from 'react';
import sortBy from 'lodash/sortBy';
import Draggable from 'react-draggable';
import {Typography} from '@material-ui/core';
import ResizeDetector from 'react-resize-detector';
import { KEY_DELETE } from 'keycode-js';

import styles from './IntervalSlider.module.scss';

const POINT_WIDTH = 15;
const leftPoint = 0;
const rightPoint = 1;
const minutesInDay = 1439;
const pixelsFromClick = 5;

const IntervalSlider = ( props ) => {
  const [width, setWidth] = useState(0);

  let valuesCopy = [...props.values];

  const slider = useRef();

  const onResize = (width) => {
    setWidth(width - POINT_WIDTH);
  };

  const divideInterval = (e, intervalIndex) => {
    const clickedIndex = intervalIndex;

    let clickedOffset = Math.abs(Math.round(e.clientX - slider.current.getBoundingClientRect().left));
    let newLeftInterval = (clickedOffset - pixelsFromClick) / (width / minutesInDay);
    let newRightInterval = (clickedOffset + pixelsFromClick) / (width / minutesInDay);

    if ((Math.floor(newLeftInterval)) < 0) {
      newLeftInterval = 0;
    }

    if ((Math.floor(newRightInterval)) >= minutesInDay) {
      newRightInterval = minutesInDay;
    }

    const newInterval = [newLeftInterval, newRightInterval];

    valuesCopy.push(newInterval);

    valuesCopy = sortBy(valuesCopy, (e) => e[rightPoint]);

    const newIntLeft = [valuesCopy[clickedIndex + 1][leftPoint], valuesCopy[clickedIndex][leftPoint]];
    const newIntRight = [valuesCopy[clickedIndex][rightPoint], valuesCopy[clickedIndex + 1][rightPoint]];

    valuesCopy.splice(clickedIndex, 2);
    valuesCopy.push(newIntLeft, newIntRight);

    valuesCopy = sortBy(valuesCopy, (e) => e[rightPoint]);

    props.onChange(valuesCopy);
  };

  const handleClick = (e) => {
    let clickedOffset = Math.abs(Math.round(e.clientX - slider.current.getBoundingClientRect().left));
    let leftPointOffset = clickedOffset - pixelsFromClick;
    let rightPointOffset = clickedOffset + pixelsFromClick;

    const newLeftInterval = leftPointOffset / (width / minutesInDay);
    const newRightInterval = rightPointOffset / (width / minutesInDay);

    const newInterval = [newLeftInterval, newRightInterval];

    valuesCopy.push(newInterval);
    valuesCopy = sortBy(valuesCopy, (e) => e[rightPoint]);

    props.onChange(valuesCopy);
  };

  const handleDrag = (e, position, index, intervalIndex) => {
    let { x } = position;

    let clickedPoint = x / (width / minutesInDay);
    let clickedInterval = valuesCopy[intervalIndex];

    valuesCopy = sortBy(valuesCopy, (e) => e[rightPoint]);

    valuesCopy[intervalIndex][index] = clickedPoint;

    /* checking if clicked point has higher minute value then left point in
    next interval and joining lowest and highest values into new interval */
    if (intervalIndex < valuesCopy.length - 1) {
      if (clickedPoint > Math.min(...valuesCopy[intervalIndex + 1])) {
        const newInterval = [Math.min(...clickedInterval), Math.max(...valuesCopy[intervalIndex + 1])];
        valuesCopy.push(newInterval);
        valuesCopy.splice(intervalIndex, 2);
      }
    }

    /* checking if clicked point has higher minute value then right point in
    previous interval and joining lowest and highest values into new interval */
    if (intervalIndex > 0) {
      if (clickedPoint < Math.max(...valuesCopy[intervalIndex - 1])) {
        const newInterval = [Math.min(...valuesCopy[intervalIndex - 1]), Math.max(...clickedInterval)];
        valuesCopy.push(newInterval);
        valuesCopy.splice(intervalIndex - 1, 2);
      }
    }

    valuesCopy = sortBy(valuesCopy, (e) => e[rightPoint]);

    props.onChange(valuesCopy);
  };

  const handleStop = () => {
    for (let i = 0; i < valuesCopy.length; i++) {
      if (valuesCopy[i][leftPoint] > valuesCopy[i][rightPoint]) {
        valuesCopy[i].reverse();
      }
    }

    props.onChange(valuesCopy);
  };

  const handleKeyPress = (e, intervalIndex) => {
    const clickedPoint = intervalIndex;

    if (e.keyCode === KEY_DELETE) {
      valuesCopy.splice(clickedPoint, 1);

      props.onChange(valuesCopy);
    }
  };

  const handleDeleteClick = (intervalIndex) => {
    const clickedPoint = intervalIndex;
    valuesCopy.splice(clickedPoint, 1);

    props.onChange(valuesCopy);
  };

  return (
    <div className={styles.intervalSlider}>
      <div className={styles.sliderWrapper}>
        <ResizeDetector handleWidth onResize={(width) => onResize(width)}>
          <div data-test-id={props.sliderTestId} className={styles.slider} ref={slider} onMouseDown={(e) => handleClick(e)} />
        </ResizeDetector>

        {props.values.map((interval, intervalIndex) => {
          let leftOffset = width / minutesInDay * interval[leftPoint];
          let rightOffset = width / minutesInDay * interval[rightPoint];
          const intervalWidth = Math.round(rightOffset - leftOffset);

          if (interval[leftPoint] > interval[rightPoint]) {
            leftOffset = rightOffset;
            rightOffset = leftOffset;
          }

          if (interval.length === 2) {
            return interval.map((point, index) => {
              const offset = width / minutesInDay * point;
              let intervalPointHour = Math.floor(point / 60);
              let intervalPointMinute = Math.floor(point % 60);

              if (intervalPointHour === 24 && intervalPointMinute === 0) {
                intervalPointHour = 23;
                intervalPointMinute = 59;
              }

              return (
                <>
                  <Draggable
                    key={`${props.values.length}-${index}`}
                    axis="x"
                    bounds="parent"
                    onDrag={(e, position) => handleDrag(e, position, index, intervalIndex)}
                    onStop={handleStop}
                    position={{ x: offset, y: 0 }}
                  >
                    <div className={styles.buttonWrapper} onKeyDown={(e, intervalIndex) => handleKeyPress(e, intervalIndex)}>
                      <button
                        className={styles.deleteButton}
                        onClick={() => handleDeleteClick(intervalIndex)}
                        data-test-id="deleteInterval"
                      >
                        X
                      </button>
                      <button data-test-id={props.sliderBtnTestId} key={index} className={styles.sliderPoint} style={{ background: props.intervalColor }} />
                      <Typography variant="caption" className={styles.intervalTime}>
                        {intervalPointHour > 9 ? intervalPointHour : `0${intervalPointHour}`}: {intervalPointMinute > 9 ? intervalPointMinute : `0${intervalPointMinute}`}
                      </Typography>
                    </div>
                  </Draggable>

                  <div
                    key={`interval-${props.values.length}-${index}`}
                    onClick={(e) => divideInterval(e, intervalIndex)}
                    className={styles.intervalWidth}
                    style={{ width: `${Math.abs(intervalWidth)}px`, background: props.trackColor, left: `${leftOffset}px` }}
                    data-test-id={props.intervalMarkerTestId}
                  />
                </>
              );
            });
          }
        })}
      </div>
    </div>
  );
};

export default IntervalSlider;
