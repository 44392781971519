import { observable, flow, computed, action } from 'mobx';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import map from 'lodash/map';
import dayjs from 'dayjs';
import fromPairs from 'lodash/fromPairs';
import sortBy from 'lodash/sortBy';

import { patchRoom, patchGroup, patchLight, patchScene, deleteScene, createScene } from '../../endpoints';
import {
  MODE_SCENE,
  MODE_MANUAL,
} from '../../constants';
import { addAstrals } from './astrable';

const endpointMapping = {
  room: patchRoom,
  group: patchGroup,
  light: patchLight,
};

const defaultMotionTransition = () => [{ x: 0, y: 100 }, { x: 150, y: 50 }, { x: 300, y: 0 }];

const deserializePoints = (points) =>
  points.map(({ time, value }) => ({ type: 'classic', y: value, x: time }));

export default class Controlable {
  @observable brightness = 50;
  @observable chromaticity = 0;
  @observable computedBrightness = 50;
  @observable computedChromaticity = 0;
  @observable mode = 0;
  @observable overriddenUntil = null;
  @observable activeSceneId = null;
  @observable scenes = [];
  @observable motion = false;
  @observable motionTransition = observable.array(defaultMotionTransition(), { deep: false });
  @observable autoModeBrightness = [];
  @observable autoModeChromaticity = [];
  @observable dimBright = 100;
  @observable capabilities = observable.map({
    brightness: false,
    chromaticity: false,
    uvA: false,
    uvC: false,
  });
  @observable disabled = false;

  constructor(d) {
    this.replaceControlable(d);
  }

  replaceControlable = (d) => {
    this.brightness = d.brightness;
    this.chromaticity = d.chromaticity;
    this.computedBrightness = d.computed_brightness;
    this.computedChromaticity = d.computed_chromaticity;
    this.autoModeBrightness = deserializePoints(d.auto_mode_configuration.brightness);
    this.autoModeChromaticity = deserializePoints(d.auto_mode_configuration.chromaticity);
    this.mode = d.mode;
    this.activeSceneId = d.active_scene_id;
    this.scenes = d.scenes;
    this.motion = d.motion;
    this.overriddenUntil = dayjs(d.mode_overridden_until);
    this.motionTransition = d.motion_transition
      ? sortBy(map(d.motion_transition, (value, time) => ({ x: parseInt(time), y: value })), 'x')
      : defaultMotionTransition();

    this.setDimBrightCalculated();

    const astral = d.auto_mode_configuration.astral;
    if (astral.enabled) {
      this.autoModeBrightness =
        addAstrals(
          this.autoModeBrightness,
          astral.pointsBrightness,
        );
      this.autoModeChromaticity =
        addAstrals(
          this.autoModeChromaticity,
          astral.pointsChromaticity,
        );
    }
  };

  setMode = flow(function* (mode, level) {
    this.mode = parseInt(mode);
    try {
      const r = yield endpointMapping[level](this.id, { mode: this.mode });
      window.Store.registerEntity('room', r.data.data);
    } catch (error) {
      window.Store.handleError(`${level}.settings`);
      throw error;
    }
  }.bind(this));

  setOverride = flow(function* (interval, level) {
    if (interval) {
      this.overriddenUntil = dayjs().add(interval, 'minute');
    } else {
      this.overriddenUntil = null;
    }

    try {
      const r = yield endpointMapping[level](this.id, { mode_overridden_until: this.overriddenUntil, mode: this.mode });
      window.Store.registerEntity('room', r.data.data);
    } catch (error) {
      window.Store.handleError(`${level}.settings`);
      throw error;
    }
  }.bind(this));

  setScene = flow(function* (scene, level) {
    this.activeSceneId = scene;

    try {
      const r = yield endpointMapping[level](this.id, { active_scene_id: scene, mode: MODE_SCENE });
      window.Store.registerEntity('room', r.data.data);
    } catch (error) {
      window.Store.handleError(`${level}.settings`);
      throw error;
    }
  }.bind(this));

  createScene = flow(function* (level, data) {
    try {
      const r = yield createScene(level, this.id, data);
      window.Store.registerEntity('room', r.data.data);
      window.Store.handleError('scene.create');
    } catch (error) {
      window.Store.handleError(`${level}.settings`);
      throw error;
    }
  }.bind(this));

  updateScene = flow(function* (scene, level, data) {
    try {
      const r = yield patchScene(scene, data);
      window.Store.registerEntity('room', r.data.data);
      window.Store.handleError('scene.update');
    } catch (error) {
      window.Store.handleError(`${level}.settings`);
      throw error;
    }
  });

  deleteScene = flow(function* (scene, level) {
    try {
      const r = yield deleteScene(scene);
      window.Store.registerEntity('room', r.data.data);
      window.Store.handleError('scene.delete');
    } catch (error) {
      window.Store.handleError(`${level}.settings`);
      throw error;
    }
  });

  setOnOff = flow(function* (on, level) {
    try {
      const r = yield endpointMapping[level](this.id, { on });
      window.Store.registerEntity('room', r.data.data);
    } catch (error) {
      window.Store.handleError(`${level}.settings`);
      throw error;
    }
  }.bind(this));

  setMotionEnabled = flow(function* (enabled, level) {
    try {
      const r = yield endpointMapping[level](this.id, {
        motion_reaction_enabled: enabled,
        mode: MODE_MANUAL,
      });
      window.Store.registerEntity('room', r.data.data);
    } catch (error) {
      window.Store.handleError(`${level}.settings`);
      throw error;
    }
  }.bind(this));

  setNightLightEnabled = flow(function* (enabled, level) {
    try {
      const r = yield endpointMapping[level](this.id, {
        night_light_enabled: enabled,
        brightness: 0,
        mode: MODE_MANUAL,
      });
      window.Store.registerEntity('room', r.data.data);
    } catch (error) {
      window.Store.handleError(`${level}.settings`);
      throw error;
    }
  }.bind(this));

  setDimBright = (value, level) => {
    const diff = this.dimBright - value;
    const boundedChromaticity = (this.chromaticity - 100) / 2 * -1; // into interval <0, 100>

    const unboundChromaticity = (value) => {
      return value * -2 + 100; // to interval <-100, 100>
    };

    const reversed = (value) => 100 - value;

    if (diff > 0) {
      const percentualDiff = (this.dimBright - value) / this.dimBright;

      this.setValues(
        this.brightness - (this.brightness * percentualDiff),
        unboundChromaticity(boundedChromaticity - (boundedChromaticity * percentualDiff)),
        level,
      );
    } else {
      const percentualDiff =
        reversed(this.dimBright) ? (reversed(this.dimBright) - reversed(value)) / reversed(this.dimBright) : 0;

      this.setValues(
        this.brightness + (reversed(this.brightness) * percentualDiff),
        unboundChromaticity(boundedChromaticity + (reversed(boundedChromaticity) * percentualDiff)),
        level,
      );
    }

    this.dimBright = value;
  };

  setDimBrightCalculated = () => {
    const boundedChromaticity = (this.chromaticity - 100) / 2 * -1; // into interval <0, 100>
    this.dimBright = (this.brightness + boundedChromaticity) / 2;
  };

  setMotionTransition = (transition, level) => {
    this.motionTransition.replace(transition);
    this.setMotionTransitionDebounced(transition, level);
  };

  @action
  setMotionDuration = (duration, level) => {
    const parsedDuration = parseInt(duration);
    const durationInSeconds = parsedDuration ? parsedDuration * 60 : 60;

    const durationRatio = durationInSeconds / this.motionTransition[this.motionTransition.length - 1].x;

    this.motionTransition[this.motionTransition.length - 1].x = durationInSeconds;

    this.motionTransition.forEach((point, index) => {
      if (index !== 0 && index !== this.motionTransition.length - 1) {
        this.motionTransition[index].x = Math.round(durationRatio * this.motionTransition[index].x);
      }
    });

    this.setMotionTransitionDebounced(this.motionTransition, level);
  };

  setMotionTransitionDebounced = debounce(flow(function* (transition, level) {
    try {
      this.disabled = true;

      const r = yield endpointMapping[level](this.id, {
        motion_transition: fromPairs(transition.map(({ x, y}) => [x, y])),
        mode: MODE_MANUAL,
      });
      window.Store.registerEntity('room', r.data.data);
    } catch (error) {
      window.Store.handleError(`${level}.settings`);
      throw error;
    } finally {
      this.disabled = false;
    }
  }.bind(this)), 500);

  setValues = (brightness, chromaticity, level) => {
    this.brightness = brightness;
    this.chromaticity = chromaticity;

    this.setDimBrightCalculated();
    this.setValuesDebounced(level);
  };

  setValue = (which, value, level) => {
    this[which] = value;

    this.setDimBrightCalculated();
    this.setValueDebounced(which, level);
  };

  setValueShortcut = flow(function* (which, level) {
    const values = {};

    switch (which) {
      case 'fullLight':
        values['brightness'] = 100;
        values['chromaticity'] = 0;
        break;
      case 'dayLight':
        values['brightness'] = 70;
        values['chromaticity'] = -100;
        break;
      case 'eveningLight':
        values['brightness'] = 30;
        values['chromaticity'] = 100;
        break;
      default:
        values['brightness'] = this.brightness;
        values['chromaticity'] = this.chromaticity;
    }

    try {
      const r = yield endpointMapping[level](this.id, { ...values, mode: MODE_MANUAL, night_light_enabled: false });
      window.Store.registerEntity('room', r.data.data);
    } catch (error) {
      window.Store.handleError(`${level}.settings`);
      throw error;
    }
  }.bind(this));

  setValuesDebounced = debounce(flow(function* (level) {
    try {
      this.disabled = true;

      const r = yield endpointMapping[level](this.id, {
        brightness: this.brightness,
        chromaticity: this.chromaticity,
        mode: MODE_MANUAL,
        night_light_enabled: false,
      });
      window.Store.registerEntity('room', r.data.data);
    } catch (error) {
      window.Store.handleError(`${level}.settings`);
      throw error;
    } finally {
      this.disabled = false;
    }
  }.bind(this)), 500);

  setValueDebounced = debounce(flow(function* (which, level) {
    try {
      this.disabled = true;

      const r = yield endpointMapping[level](this.id, {
        [which]: this[which],
        mode: MODE_MANUAL,
        ...(which === 'brightness' ? { night_light_enabled: false, on: true } : {}),
      });
      window.Store.registerEntity('room', r.data.data);
    } catch (error) {
      window.Store.handleError(`${level}.settings`);
      throw error;
    } finally {
      this.disabled = false;
    }
  }.bind(this)), 500);

  @computed get activeScene() {
    return find(this.scenes, scene => scene.id === this.activeSceneId);
  }
}
