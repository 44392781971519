import { observable, flow, computed } from 'mobx';
import Validator from 'validatorjs';

import remove from 'lodash/remove';

import { fetchNetworks, updateNetwork, postNetwork, deleteNetwork } from '../endpoints';

class Network {
  @observable id = '';
  @observable name = '';
  @observable user = null;

  constructor(network) {
    this.id = network.id;
    this.name = network.name;
    this.user = network.user;
  }

  update = flow(function* (name) {
    if (this.valid.passes()) {
      try {
        yield updateNetwork(this.id, { name });
      } catch (error) {
        window.Store.handleError('network.rename');
        throw error;
      }
    }
  }.bind(this));

  remove = flow(function* () {
    try {
      yield deleteNetwork(this.id);
      window.Store.networkSettings.removeListener(this.id);
    } catch (error) {
      window.Store.handleError('network.remove');
      throw error;
    }
  }.bind(this));

  @computed get valid() {
    const validator = new Validator({ name: this.name }, { name: 'required' });
    if (this.submitted) {
      validator.passes();
    }
    return validator;
  }
}

export class NetworkSettings {
  @observable networks = [];
  @observable form = observable.map({
    name: '',
  });
  @observable submitted = false;
  @observable loaded = false;
  @observable loadingError = false;

  init = flow(function* () {
    this.loadingError = false;
    this.loaded = false;

    try {
      const res = yield fetchNetworks();
      this.networks = res.data.data.map(network => new Network(network));
      this.loaded = true;
    } catch (error) {
      this.loadingError = true;
      window.Store.handleError('network.init');
      throw error;
    }
  }.bind(this));

  // add

  add = flow(function* () {
    this.submitted = true;

    if (this.valid.passes()) {
      try {
        const network = yield postNetwork(this.form.get('name'));
        this.networks.push(new Network(network.data.data));
        this.form.set('name', '');
      } catch (error) {
        window.Store.handleError('network.add');
        throw error;
      }
    }
  }.bind(this));

  @computed get valid() {
    const validator = new Validator({ name: this.form.get('name') }, { name: 'required' });
    if (this.submitted) {
      validator.passes();
    }
    return validator;
  }

  removeListener = (id) => {
    remove(this.networks, n => n.id === id);
  };
}
