import React from 'react';
import { observer, inject } from 'mobx-react';
import {
  CircularProgress,
  BottomNavigation,
  BottomNavigationAction,
  Popover,
} from '@material-ui/core';

import classnames from 'classnames';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import queryString from 'query-string';
import Navigator from '../../shared/helpers/navigation';

import LoadingError from '../../components/LoadingError';
import Blueprint from './Blueprint';
import Room from './Room';
import Control from './control/Control';

import styles from './ControlCenter.module.scss';

@inject('store')
@observer
class ControlCenter extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      anchor: null,
      level: 'room',
      store: null,
      roomStore: null,
    };
    this.controlCenterListWrapper = React.createRef();
    this.wrapper = React.createRef();

    this.roomOpened = false;
    this.viewOpened = false;
  }

  async componentDidMount() {
    await window.Store.setAccessToken();
    await window.Store.init();
    if (window.Store.currentNetwork) {
      this.props.store.controlCenter.init();
    }
    this.props.store.crumbs = [{ label: 'Control Center', value: '/in' }];
  }

  componentDidUpdate() {
    const parsedUrl = queryString.parse(location.search);

    if (
      !this.roomOpened && 'room' in parsedUrl && this.props.store.controlCenter.view &&
      (this.viewOpened || this.props.store.controlCenter.view.id === parseInt(parsedUrl['view']))
    ) {
      const el = document.querySelector(`[anchorid=room${parsedUrl.room}]`);
      if (el) {
        el.click();
      }

      this.roomOpened = true;
    }

    if ('view' in parsedUrl && !this.viewOpened) {
      this.props.store.controlCenter.setCurrentView(parseInt(parsedUrl['view']));
      this.viewOpened = true;
    }
  }

  onItemClick = (level, store, anchor, roomStore) => {
    this.setState({ level, store, anchor, roomStore });
  };

  onClose = () => {
    this.setState({ anchor: null });
    Navigator._navigator.push({ search: '' });
  };

  render() {
    const s = this.props.store.controlCenter;
    return (
      <div className={styles.wrapper} ref={this.wrapper}>
        <div className={styles.left}>
          <Blueprint />
          <div>
            {s.loaded && (
              <div className={styles.bottomNav}>
                <BottomNavigation
                  showLabels
                  value={s.view.id}
                  onChange={(_, val) => s.setCurrentView(val)}
                >
                  {s.views.map(view => (
                    <BottomNavigationAction
                      label={view.name}
                      icon={<LocationOnIcon />}
                      value={view.id}
                      key={view.id}
                      onClick={() => this.props.store.controlCenter.setCurrentView(view.id), this.onItemClick}/>
                  ))}
                </BottomNavigation>
              </div>
            )}

            <div className={styles.switcher}>
              <BottomNavigation
                showLabels
                value={s.currentTab}
                onChange={(_, val) => s.currentTab = val}
              >
                <BottomNavigationAction label="Blueprint" value="blueprint" />
                <BottomNavigationAction label="List" value="list" />
              </BottomNavigation>
            </div>
          </div>
        </div>

        <div ref={this.controlCenterListWrapper} className={classnames(styles.right, { 'hidden-mobile': s.currentTab === 'blueprint' })}>
          {!s.loaded && !s.loadingError &&
            <div id='progress' className={styles.progress} data-test-id="loadingProgress">
              <CircularProgress />
            </div>
          }

          {s.loadingError &&
            <LoadingError />
          }

          {s.loaded &&
            <>
              {s.view.rooms.map((room, index) => (
                <Room
                  key={room.id}
                  s={room}
                  clickedAnchor={this.state.anchor}
                  index={index}
                  onItemClick={this.onItemClick}
                  onClose={this.onClose}
                />
              ))}
              <Popover
                open={Boolean(this.state.anchor)}
                onClose={this.onClose}
                anchorEl={this.controlCenterListWrapper ? this.controlCenterListWrapper.current : this.wrapper.current}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'center', horizontal: 'right' }}
                >
                <Control
                  level={this.state.level}
                  onClose={this.onClose}
                  s={this.state.store}
                  roomStore={this.state.roomStore}
                />
              </Popover>
            </>
          }
        </div>
      </div>
    );
  }
}

export default ControlCenter;
