import React from 'react';

import {
  Button
} from '@material-ui/core';

const SaveButton = ({ onClick, disabled, type }) => (
  <React.Fragment>
      <Button type={type} variant="contained" color="secondary" onClick={onClick} disabled={disabled}>
        <div>
          save
        </div>
      </Button>
  </React.Fragment>
);

export default SaveButton;
