import React from 'react';
import { observer, inject } from 'mobx-react';
import { injectIntl, FormattedMessage } from 'react-intl';

import Navigator from '../../shared/helpers/navigation';

import SignLayout from './SignLayout';

import {
  Paper,
  Typography,
  Button,
  TextField,
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import styles from './SignLayout.module.scss';

@inject('store')
@observer
class SignIn extends React.Component {
  componentDidMount() {
    this.props.store.signIn.init();
  }

  handleChange = (prop) => (event) => {
    this.props.store.signIn.form.set(prop, event.target.value);
  };

  render() {
    const s = this.props.store.signIn;

    return (
      <SignLayout history={this.props.history}>
        <Paper elevation={2} className={styles.paper}>
          <Typography variant="h1"><FormattedMessage id="path./" /></Typography>
          <Typography variant="body1" gutterBottom><FormattedMessage id="sign.in.subheading" /></Typography>

          <form className={styles.form} onSubmit={(e) => {
            e.preventDefault();
            s.signIn();
          }}>
            <TextField
              label={this.props.intl.formatMessage({ id: 'sign.label.email' })}
              fullWidth
              margin="normal"
              variant="outlined"
              error={!!s.valid.errors.first('email')}
              helperText={s.valid.errors.first('email')}
              onChange={this.handleChange('email')}
              value={s.form.email}
            />
            <TextField
              label={this.props.intl.formatMessage({ id: 'sign.label.password' })}
              fullWidth
              margin="normal"
              variant="outlined"
              type="password"
              error={!!s.valid.errors.first('password')}
              helperText={s.valid.errors.first('password')}
              onChange={this.handleChange('password')}
              value={s.form.password}
            />
            <Button variant="contained" color="secondary" className={styles.signButton} type="submit">
              <FormattedMessage id="path./" />
              <NavigateNextIcon />
            </Button>

            <Button className={styles.passwordButton} size="small" onClick={() => Navigator.push('LostPassword')}>
              <FormattedMessage id="sign.lostPassword.question"/>
            </Button>
          </form>
        </Paper>

        <Paper elevation={2} className={styles.paper}>
          <Typography variant="h1" gutterBottom><FormattedMessage id="sign.up.question"/></Typography>
              <Button variant="contained" color="secondary" className={styles.registerButton} onClick={() => Navigator.push('SignUp')}>
                <FormattedMessage id="path./sign-up" />
                <NavigateNextIcon />
              </Button>
        </Paper>
      </SignLayout>
    );
  }
}

export default injectIntl(SignIn);
