import React from 'react';
import { injectIntl } from 'react-intl';
import { observer, inject } from 'mobx-react';

import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from '@material-ui/core';

import { Link } from 'react-router-dom';

import DashboardIcon from '@material-ui/icons/Dashboard';
import FaceIcon from '@material-ui/icons/Face';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import SettingsIcon from '@material-ui/icons/Settings';

import styles from './LeftMenu.module.scss';

import logo from '../shared/images/logo-inverse.png';

@inject('store')
@observer
class LeftMenu extends React.Component {
  state = {
    settingsAnchor: null,
    viewportWidth: 0,
  };

  closeSettingsMenu = () => {
    this.setState({ settingsAnchor: null });
    this.props.store.menuOpen = false;
  };

  componentDidMount() {
    this.setState({ viewportWidth: window.innerWidth });
  }

  render() {
    const s = this.props.store;

    return (
      <React.Fragment>
        <Drawer
          variant={this.state.viewportWidth > 768 ? 'permanent' : 'temporary'}
          anchor="left"
          classes={{
            paper: styles.drawer,
          }}
          open={s.menuOpen}
          onClose={() => s.menuOpen = false}
        >
          <div className={styles.logoWrapper}>
            <img src={logo} className={styles.logo} alt="Suntense" />
          </div>
          <Divider />
          <List>
            <ListItem className={styles.listItemSection} component={Link} to="/in" button onClick={() => s.menuOpen = false} data-test-id='controlCenter'>
              <ListItemIcon>
                <SettingsApplicationsIcon />
              </ListItemIcon>
              <Typography variant="h2">{this.props.intl.formatMessage({ id: 'path./in' })}</Typography>
            </ListItem>
              <ListItem className={styles.listItemSection} component={Link} to="/in/usage" button onClick={() => s.menuOpen = false} data-test-id='usageStatistics'>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <Typography variant="h2">{this.props.intl.formatMessage({ id: 'path./in/usage' })}</Typography>
              </ListItem>
              <ListItem className={styles.listItemSection} component={Link} to="/in/access" button onClick={() => s.menuOpen = false} data-test-id='userAccess'>
                <ListItemIcon>
                  <FaceIcon />
                </ListItemIcon>
                <Typography variant="h2">{this.props.intl.formatMessage({ id: 'path./in/access' })}</Typography>
              </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem className={styles.listItemSection} component={Link} to="/in/hierarchy" button onClick={(e) => this.setState({ settingsAnchor: e.currentTarget })}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <Typography variant="h2">{this.props.intl.formatMessage({ id: 'path./in/settings' })}</Typography>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem className={styles.listItemSection} button>
              <ListItemIcon>
                <LiveHelpIcon />
              </ListItemIcon>
              <Typography variant="h2" onClick={() => s.menuOpen = false}>{this.props.intl.formatMessage({ id: 'path./in/help' })}</Typography>
            </ListItem>
          </List>
        </Drawer>
      </React.Fragment>
    );
  }
}

export default injectIntl(LeftMenu);
