import React from 'react';
import { observer, inject } from 'mobx-react';
import { injectIntl } from 'react-intl';

import {
  Snackbar,
  IconButton,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

@inject('store')
@observer
class Error extends React.Component {
  render() {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={Boolean(this.props.store.error)}
        onClose={this.props.store.discardError}
        autoHideDuration={3000}
        message={this.props.intl.formatMessage({ id: `error.${this.props.store.error}` })}
        action={
          <IconButton
            color="secondary"
            onClick={this.props.store.discardError}
          >
            <CloseIcon />
          </IconButton>
        }
      />
    );
  }
}

export default injectIntl(Error);
