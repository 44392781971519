import React from 'react';
import {
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from '@material-ui/core';
import { Observer } from 'mobx-react';

import AddIcon from '@material-ui/icons/Add';
import CropFreeIcon from '@material-ui/icons/CropFree';

import DialogTitle from '../DialogTitle';
import styles from './HierarchyTree.module.scss';

class Modal extends React.Component {
  state = {
    isWebView: false,
  };

  componentDidMount() {
    if (window.ReactNativeWebView) {
      this.setState({ isWebView: true });
    }
  }

  getTitle = (levelData) => {
    const title = levelData.level === 1 && `Add View to ${levelData.title}` ||
    levelData.level === 2 && `Add Room to ${levelData.title}` ||
    levelData.level === 3 && `Add Group to ${levelData.title}` ||
    levelData.level === 4 && `Add Light to ${levelData.title}` ||
    levelData.level === 'Gateways' && `Add Gateway to ${levelData.title}`;
    return title;
  };

  handleQR = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('openScanner');
    }
  };

  render() {
    const levelData = this.props.data;

    return (
      <Observer>
        {() => (
          <>
            <div className={styles.levelButton}>
              <div
                className={styles.hierarchyCurve}>
                <svg>
                  <path fill='none' stroke='#9D9D9B' strokeWidth='3px' d='M 24.5 0 L 24.5 30 Q 24.5 40 35 40 L 50 40' />
                </svg>
              </div>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<AddIcon />}
                onClick={() => {
                  levelData.handleModal();
                  this.props.store.isEditting = true;
                  levelData.newTitle = '';
                }}
              >
                <Typography variant="body2">
                  {levelData.level === 1 && `Add View to ${levelData.title}`}
                  {levelData.level === 2 && `Add Room to ${levelData.title}`}
                  {levelData.level === 3 && `Add Group to ${levelData.title}`}
                  {levelData.level === 4 && `Add Light to ${levelData.title}`}
                  {levelData.level === 'Gateways' && `Add Gateway to ${levelData.title}`}
                </Typography>
              </Button>

              <Dialog
                open={levelData.modalOpen}
                onClose={() => {
                  levelData.handleModal();
                  levelData.newSerial = '';
                  levelData.newTitle = '';
                  levelData.submitted = false;
                  this.props.store.isEditting = false;
                }}
              >
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    levelData.submitted = true;
                    if (this.props.store.modalLightFormValue === 'New light') {
                      if (levelData.level === 4 || levelData.level === 'Gateways') {
                        if (levelData.valid.passes() && levelData.validSerial.passes()) {
                          const name = levelData.newTitle;
                          const serial = levelData.newSerial;
                          const droppableTo = levelData.level;
                          levelData.addToLevel({
                            name: name,
                            serial: `${serial}`,
                            droppableTo: droppableTo
                          });
                          levelData.submitted = false;
                          levelData.newTitle = '';
                          levelData.newSerial = '';
                          levelData.handleModal();
                          this.props.store.isEditting = false;
                        }
                      } else {
                        if (levelData.valid.passes()) {
                          const name = levelData.newTitle;
                          const serial = levelData.newSerial;
                          const droppableTo = levelData.level;
                          levelData.addToLevel({
                            name: name,
                            serial: `${serial}`,
                            droppableTo: droppableTo
                          });
                          levelData.submitted = false;
                          levelData.newTitle = '';
                          levelData.newSerial = '';
                          levelData.handleModal();
                          this.props.store.isEditting = false;
                        }
                      }
                    }
                  }}
                >
                  <DialogTitle
                    title={this.getTitle(levelData)}
                    onClose={() => {
                      levelData.handleModal();
                      levelData.newSerial = '';
                      levelData.newTitle = '';
                      levelData.submitted = false;
                      this.props.store.isEditting = false;
                    }}
                  />

                  <DialogContent>
                    {levelData.level === 4 &&
                      <DialogContentText>
                        Find serial number on the back side of package.
                      </DialogContentText>
                    }

                    {(levelData.level === 4 || levelData.level === 'Gateways') &&
                      <div className="d-flex align-items-center">
                        <div id="serial-code" data-dnd-id={levelData.DnD_Id}>
                          <TextField
                            label= { levelData.level === 'Gateways' ? `Gateway serial number` : `Light serial number` }
                            value={levelData.newSerial}
                            error={!!levelData.validSerial.errors.first('serial')}
                            helperText={levelData.validSerial.errors.first('serial')}
                            margin="normal"
                            fullWidth
                            variant="outlined"
                            onChange={(e) => levelData.newSerial = e.target.value}
                          />
                        </div>
                        {this.state.isWebView &&
                          <Button variant="contained" color="secondary" className="ml-1 mt-2" onClick={this.handleQR}>
                            <CropFreeIcon className="mr-1" /> QR
                          </Button>
                        }
                      </div>
                    }

                    <TextField
                      label="Name"
                      margin="normal"
                      fullWidth
                      value={levelData.newTitle}
                      variant="outlined"
                      error={!!levelData.valid.errors.first('name')}
                      helperText={levelData.valid.errors.first('name')}
                      onChange={(e) => levelData.newTitle = e.target.value}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      size="small"
                      onClick={() => {
                        levelData.handleModal();
                        levelData.newSerial = '';
                        levelData.newTitle = '';
                        levelData.submitted = false;
                        this.props.store.isEditting = false;
                      }}
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button variant="contained" type="submit" color="secondary">
                      Add
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
            </div>
          </>
        )}
      </Observer>
    );
  }
}

export default Modal;
