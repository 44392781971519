import React from 'react';
import { observer, inject } from 'mobx-react';
import classNames from 'classnames';
import DayjsUtils from '@date-io/dayjs';

import {
  CircularProgress,
  Paper,
  Typography,
  Grid,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Menu,
  MenuItem,
  ListItemText,
  Dialog,
  DialogContent,
  DialogActions,
  Select,
  FormGroup,
  Switch,
} from '@material-ui/core';

import { Link } from 'react-router-dom';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import { ChevronLeft } from '@material-ui/icons';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DialogTitle from '../../components/DialogTitle';

import Hierarchy from '../../components/hierarchy/Hierarchy';
import LoadingError from '../../components/LoadingError';
import Chart from '../../components/chart/Chart';
import IntervalSlider from '../../components/intervalSlider/IntervalSlider';
import { prettify, NO_REPEAT, DAY, WEEK, MONTH, YEAR, DAY_OF_MONTH, DAY_OF_NTH_WEEK, days } from '../../shared/stores/extendable/schedulable';
import { AUTO_EXCEPTION } from '../../shared/constants';

import {
  BrightnessBounds,
  ChromaticityBounds,
  ChromaticityGradient,
  BrightnessGradient,
  DayLength,
} from '../../shared/constants';

import styles from './AutoModeSettings.module.scss';

const timeFormatter = (value) => {
  const hours = Math.floor(value / 60);
  const minutes = Math.floor(value % 60);

  return `${hours > 9 ? hours : `0${hours}`}:${minutes > 9 ? minutes : `0${minutes}`}`;
};

@inject('store')
@observer
class AutoModeSettings extends React.Component {
  scrolled = false;

  state = {
    repeatAnchor: null,
    calendarAnchor: null,
    repeatModal: false,
    initialData: null,
  };

  async componentDidMount() {
    const store = this.props.store;

    await store.setAccessToken();
    await store.init();

    await store.autoModeSettings.init(this.props.match.params.roomId, this.props.match.params.confId);

    this.props.store.crumbs = [
      { label: 'Control Center', value: '/in' },
      { label: store.autoModeSettings.room.name, value: `/in?room=${this.props.match.params.roomId}&view=${this.props.match.params.viewId}` },
      { label: 'Auto settings' },
    ];
  }

  componentDidUpdate() {
    const hash = this.props.location.hash;

    if (!this.scrolled && hash && !hash.startsWith('#room') && this.props.store.autoModeSettings.loaded) {
      const top = document.getElementById(hash.slice(1)).getBoundingClientRect().top;
      window.scroll({
        top: top + window.scrollY - 75,
        behavior: 'smooth',
      });
      this.scrolled = true;
      this.setState({ highlight: hash.slice(1) });
    }
  }

  handleChangeShortcut = (shortcut) => {
    this.props.store.autoModeSettings.setScheduling(shortcut);
    this.setState({ repeatAnchor: null });
  };

  handleChangeEvery = (every) => {
    const s = this.props.store.autoModeSettings;

    s.setScheduling({
      repeat: {
        every: Math.max(1, every),
        type: s.repeat.type,
      },
    });
  };

  handleClickCustom = () => {
    const s = this.props.store.autoModeSettings;

    if (s.repeatType === NO_REPEAT) {
      s.setScheduling({ repeatType: YEAR, repeat: { every: 1 } });
    }

    this.setState({ repeatModal: true });
  }

  handleChangeRepeat = (repeatType) => {
    const s = this.props.store.autoModeSettings;

    s.setScheduling({ repeatType, repeat: {
      every: s.repeat.every,
      ...(repeatType === MONTH ? { type: s.repeat && 'type' in s.repeat ? s.repeat.type : DAY_OF_MONTH } : {}),
      ...(repeatType === WEEK ? { days: s.repeat && 'days' in s.repeat ? s.repeat.days : {
        monday: this.scheduledAt.day() === 1,
        tuesday: this.scheduledAt.day() === 2,
        wednesday: this.scheduledAt.day() === 3,
        thursday: this.scheduledAt.day() === 4,
        friday: this.scheduledAt.day() === 5,
        saturday: this.scheduledAt.day() === 6,
        sunday: this.scheduledAt.day() === 0,
      } } : {}),
    }});
  };

  render() {
    const s = this.props.store.autoModeSettings;
    return (
      <div className={styles.wrapper}>
        {!s.loaded && !s.loadingError &&
          <Paper>
            <div className={styles.progress}>
              <CircularProgress data-test-id="loadingProgress"/>
            </div>
          </Paper>
        }

        {s.loadingError &&
          <LoadingError />
        }

        {s.loaded && (
          <>
            <Grid item>
              <section className={styles.headingSection}>
                <Typography variant="h3" gutterBottom>{s.room.name}</Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    startIcon={<ChevronLeft />}
                    component={Link}
                    to="/in"
                  >
                    Control center
                  </Button>
              </section>
              <TextField
                margin="normal"
                variant="outlined"
                className="mb-3"
                fullWidth
                disabled={s.type !== AUTO_EXCEPTION}
                value={s.name}
                onChange={(e) => s.setName(e.target.value)}
              />
            </Grid>

            {s.type === AUTO_EXCEPTION && (
              <>
                <Typography variant="h3" gutterBottom>
                  Date
                </Typography>
                <Grid item xs={12} lg={6} className="mb-3">
                  <MuiPickersUtilsProvider utils={DayjsUtils}>
                    <DatePicker
                      fullWidth
                      inputVariant="outlined"
                      format="D. M. YYYY"
                      value={s.scheduledAt}
                      onChange={s.setScheduledAt}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Typography variant="h3">
                  Repeat
                </Typography>
                <Grid item xs={12} lg={6} className="mb-5">
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={styles.repeatButton}
                    onClick={(e) => this.setState({ repeatAnchor: e.currentTarget })}
                  >
                    <span>
                      {prettify(s.repeatConfig)}
                    </span>
                    <ArrowDropDownIcon />
                  </Button>
                  <Menu
                    anchorEl={this.state.repeatAnchor}
                    onClose={() => this.setState({ repeatAnchor: null })}
                    open={Boolean(this.state.repeatAnchor)}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  >
                    {s.repeatShortcuts.map((shortcut) => (
                      <MenuItem onClick={() => this.handleChangeShortcut(shortcut)}>
                        <ListItemText primary={prettify(shortcut)} />
                      </MenuItem>
                    ))}
                    <MenuItem onClick={this.handleClickCustom}>
                      <ListItemText primary="Custom..." />
                    </MenuItem>
                  </Menu>
                </Grid>
              </>
            )}

            <Hierarchy hierarchy={s.room}>
              {(model) => {
                return (
                <div
                  className={classNames(styles.level, { [styles.highlight]: this.state.highlight === `${model.level}-${model.id}` })}
                  id={model.level !== 'room' && `${model.level}-${model.id}`}
                >
                  <div
                    className={classNames(
                      styles.header,
                      {
                        [styles.expanded]: !model.inherit || model.level === 'room', [styles.expandable]: model.level !== 'room',
                      },
                    )}
                    onClick={() => {
                      model.level !== 'room' &&
                      ( model.capabilities.get('brightness') ||
                        model.capabilities.get('chromaticity') ||
                        model.capabilities.get('uvC') ||
                        model.capabilities.get('uvA')
                      ) && model.setInherit(!model.inherit);
                    }}
                  >
                    <div className={classNames(styles.info, { [styles.fullWidth]: model.inherit && model.level !== 'room' })}>
                      <Typography variant="h3" data-test-id="levelName">{model.name}</Typography>
                      {model.inherit && model.level !== 'room' && (
                        <Typography variant="caption">
                          Inherits from {model.parent.name}
                        </Typography>
                      )}
                    </div>

                    {(!model.inherit || model.level === 'room') && (
                      <FormGroup row>
                          {(model.capabilities.get('brightness') ||
                            model.capabilities.get('chromaticity')) && (
                          <>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={model.motionEnabled}
                                  onChange={(e) => model.setMotionEnabled(e.target.checked)}
                                  onClick={(e) => e.stopPropagation()}
                                  data-test-id='motionSwitch'
                                />
                              }
                              label="Motion"
                            />
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={model.astralEnabled}
                                  onChange={(e) => model.setAstralEnabled(e.target.checked)}
                                  onClick={(e) => e.stopPropagation()}
                                  data-test-id='astralSwitch'
                                />
                              }
                              label="Astral"
                            />
                          </>
                        )}

                        {model.level === 'room' && (
                          <>
                            {model.capabilities.get('uvC') && (
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={model.uvCEnabled}
                                    onChange={(e) => model.setUvCEnabled(e.target.checked)}
                                    onClick={(e) => e.stopPropagation()}
                                    data-test-id='uvcSwitch'
                                  />
                                }
                                label="UV-C"
                              />
                            )}

                            {model.capabilities.get('uvA') && (
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={model.uvAEnabled}
                                    onChange={(e) => model.setUvAEnabled(e.target.checked)}
                                    onClick={(e) => e.stopPropagation()}
                                    data-test-id='uvaSwitch'
                                  />
                                }
                                label="UV-A"
                              />
                            )}
                          </>
                        )}
                          {(model.capabilities.get('brightness')) && (
                          <FormControlLabel
                            control={
                              <Switch
                                checked={model.nightLightEnabled}
                                onChange={(e) => model.setNightLightEnabled(e.target.checked)}
                                onClick={(e) => e.stopPropagation()}
                                data-test-id='nightLightSwitch'
                              />
                            }
                            label="Night light"
                          />
                        )}
                      </FormGroup>
                    )}
                  </div>

                    {((!model.inherit || model.level === 'room') && model.capabilities.get('brightness')) && (
                    <div className="mb-3">
                      {model.motionEnabled && (
                        <Paper className={styles.setting} data-test-id="motionPaper">
                          <Typography variant="body1">Motion</Typography>
                          <IntervalSlider
                            values={model.motionIntervals}
                            onChange={model.setMotionIntervals}
                            intervalColor="#fff"
                            trackColor="#fafafa"
                            intervalMarkerTestId='motionIntervalMarker'
                            sliderTestId="motionMultislider"
                            sliderBtnTestId="motionMultisliderButton"
                          />
                          <Grid item xs={12} sm={4} className="mb-3">
                            <TextField
                              label="Motion transition duration (minutes)"
                              margin="normal"
                              fullWidth
                              type="number"
                              value={model.motionTransition[model.motionTransition.length -1].x / 60}
                              onChange={(e) => model.setMotionDuration(e.target.value)}
                              className="mb-1"
                            />
                            <Chart
                              values={model.motionTransition.toJS()}
                              onChange={model.setMotionTransition}
                              min={0}
                              max={100}
                              duration={model.motionTransition[model.motionTransition.length -1].x}
                              timeFormatter={timeFormatter}
                            />
                          </Grid>
                        </Paper>
                      )}

                      {model.uvCEnabled && (
                        <Paper className={styles.setting} data-test-id="uvcPaper">
                          <Typography variant="body1">UV-C</Typography>
                          <IntervalSlider
                            values={model.uvCIntervals}
                            onChange={model.setUvCIntervals}
                            intervalColor="#c659ff"
                            trackColor="#a800ff"
                            sliderTestId='uvcMultislider'
                            sliderBtnTestId='uvcMultisliderButton'
                            intervalMarkerTestId='uvcIntervalMarker'
                          />
                          <Grid style={{marginTop: "25px"}} item xs={12} sm={4} className="mb-3">
                            <Typography data-test-id="uvcSelectHeading" variant="body2">How often</Typography>
                            <Select
                              value={model.uvCPeriod}
                              displayEmpty
                              onChange={(e) => model.setUvC('howOften', e.target.value)}
                              fullWidth
                              className="mb-2"
                              data-test-id="uvcSelect"
                            >
                              <MenuItem value={0}>
                                Every hour
                              </MenuItem>
                              <MenuItem value={1}>
                                Every 2 hours
                              </MenuItem>
                              <MenuItem value={2}>
                                Every 4 hours
                              </MenuItem>
                            </Select>

                            <Typography data-test-id="uvcSelectHeading" variant="body2">For how long</Typography>
                            <Select
                              value={model.uvCTimer}
                              displayEmpty
                              onChange={(e) => model.setUvC('howLong', e.target.value)}
                              fullWidth
                              data-test-id="uvcSelect"
                            >
                              <MenuItem value={0}>
                                2 minutes
                              </MenuItem>
                              <MenuItem value={1}>
                                5 minutes
                              </MenuItem>
                              <MenuItem value={2}>
                                10 minutes
                              </MenuItem>
                            </Select>
                          </Grid>
                        </Paper>
                      )}

                      {model.uvAEnabled && (
                        <Paper className={styles.setting} data-test-id="uvaPaper">
                          <Typography variant="body1">UV-A</Typography>
                          <IntervalSlider
                            values={model.uvAIntervals}
                            onChange={model.setUvAIntervals}
                            intervalColor="#c659ff"
                            trackColor="#a800ff"
                            sliderTestId='uvaMultislider'
                            sliderBtnTestId='uvaMultisliderButton'
                            intervalMarkerTestId='uvaIntervalMarker'
                          />
                        </Paper>
                      )}

                      {model.nightLightEnabled && (
                        <Paper className={styles.setting} data-test-id="nightLightPaper">
                          <Typography variant="body1">Night light</Typography>
                          <IntervalSlider
                            values={model.nightLightIntervals}
                            onChange={model.setNightLightIntervals}
                            intervalColor="#e38b20"
                            trackColor="#d6994f"
                            sliderTestId='nightLightMultislider'
                            sliderBtnTestId='nightLightMultiSliderButton'
                            intervalMarkerTestId='nightLightIntervalMarker'
                          />
                        </Paper>
                      )}
                    </div>
                  )}
                    {((!model.inherit || model.level === 'room') && model.capabilities.get('brightness')) && (
                    <>
                      <div className="mb-4">
                        <Typography className={styles.chartHeader} variant="body1">Brightness</Typography>
                        <Chart
                          values={model.brightness.toJS()}
                          onChange={model.setBrightness}
                          min={BrightnessBounds[0]}
                          max={BrightnessBounds[1]}
                          duration={DayLength}
                          gradient={BrightnessGradient}
                          alarms={model.alarms}
                          onAlarmsChange={model.setAlarms}
                          timeFormatter={timeFormatter}
                          pointTestID='brightnessPoint'
                          chartTestID='brightnessChart'
                        />
                      </div>
                    </>
                    )}
                    {((!model.inherit || model.level === 'room') && model.capabilities.get('chromaticity')) && (
                    <>
                      <div className="mb-3" >
                        <Typography className={styles.chartHeader} variant="body1">Chromaticity</Typography>
                        <Chart
                          values={model.chromaticity.toJS()}
                          onChange={model.setChromaticity}
                          min={ChromaticityBounds[1]}
                          max={ChromaticityBounds[0]}
                          duration={DayLength}
                          gradient={ChromaticityGradient}
                          alarms={model.alarms}
                          onAlarmsChange={model.setAlarms}
                          timeFormatter={timeFormatter}
                        />
                      </div>
                    </>
                    )}
                    {!(model.capabilities.get('brightness') ||
                      model.capabilities.get('chromaticity')) && (
                    <Typography variant="body1">Nothing to set up.</Typography>
                  )}
                </div>
              );}}
            </Hierarchy>
          </>
        )}

        <Dialog
          open={this.state.repeatModal}
          onClose={() => this.setState({ repeatModal: false })}
        >
          <DialogTitle
            title='Custom recurrence'
            onClose={() => this.setState({ repeatModal: false })}
          />
          <DialogContent>
            <Typography variant="body1" gutterBottom>Repeat every</Typography>
            <Grid container spacing={2} className="mb-3">
              <Grid item xs={12} lg={4}>
                <TextField
                  margin="normal"
                  type="number"
                  fullWidth
                  value={s.repeat && s.repeat.every ? s.repeat.every : 1}
                  onChange={(e) => this.handleChangeEvery(parseInt(e.target.value))}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Select
                  value={s.repeatType}
                  fullWidth
                  onChange={(e) => this.handleChangeRepeat(e.target.value)}
                >
                  <MenuItem value={DAY}>day</MenuItem>
                  <MenuItem value={WEEK}>week</MenuItem>
                  <MenuItem value={MONTH}>month</MenuItem>
                  <MenuItem value={YEAR}>year</MenuItem>
                </Select>
              </Grid>
            </Grid>

            {s.repeatType === WEEK && (
              <>
                <Typography variant="body1">Repeat on</Typography>
                <FormGroup row>
                  {days.map(day => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={s.repeat.days[day.toLowerCase()]}
                          onChange={(e) => s.setWeekDay(day.toLowerCase(), e.target.checked)}
                        />
                      }
                      label={day.substring(0, 2)}
                    />
                  ))}
                </FormGroup>
              </>
            )}

            {s.repeatType === MONTH && (
              <Select
                value={s.repeat.type}
                onChange={(e) => s.setScheduling({ repeat: { every: s.repeat.every, type: e.target.value } })}
              >
                <MenuItem value={DAY_OF_MONTH}>
                  {prettify({ scheduledAt: s.scheduledAt, repeatType: MONTH, repeat: { every: s.repeat.every, type: DAY_OF_MONTH } })}
                </MenuItem>
                <MenuItem value={DAY_OF_NTH_WEEK}>
                  {prettify({ scheduledAt: s.scheduledAt, repeatType: MONTH, repeat: { every: s.repeat.every, type: DAY_OF_NTH_WEEK } })}
                </MenuItem>
              </Select>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ repeatModal: false, repeatAnchor: null })} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default AutoModeSettings;
