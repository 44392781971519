let env = 'development';
if (process.env.REACT_APP_ENV) {
  env = process.env.REACT_APP_ENV;
}

const serverUrls = {
  development: 'http://cashm',
  production: 'https://api.suntense.com',
  staging: 'https://api.staging.suntense.com',
};

const socketUrls = {
  development: 'http://cashm:6001',
  production: 'https://api.suntense.com',
  staging: 'https://api.staging.suntense.com',
};

export const SERVER_URL = `${serverUrls[env]}/app-api/v1`;
export const SOCKET_URL = socketUrls[env];
