import React from 'react';

import {
  Button, IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import styles from './RemoveButton.module.scss';

const RemoveButton = ({ onClick, long, disabled }) => (
  <React.Fragment>
    {long ? (
      <Button variant="contained" color="secondary" className={styles.remove} onClick={onClick} disabled={disabled}>
        Remove
        <DeleteIcon />
      </Button>
    ) : (
      <IconButton onClick={onClick} disabled={disabled}>
        <DeleteIcon />
      </IconButton>
    )}
  </React.Fragment>
);

export default RemoveButton;
