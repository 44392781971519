import React from "react";
import { observer, inject } from "mobx-react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Link } from 'react-router-dom';

import SignLayout from './SignLayout';

import {
  Paper,
  Typography,
  Button,
  TextField,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import styles from "./SignLayout.module.scss";

import Navigator from '../../shared/helpers/navigation';

@inject("store")
@observer
class SignUp extends React.Component {

  handleChange = prop => event => {
    this.props.store.signUp.form.set(prop, event.target.value);
  };

  render() {
    const s = this.props.store.signUp;

    return (
      <SignLayout history={this.props.history}>
        <Paper elevation={2} className={styles.paper}>
          <Typography variant="h1">
            <FormattedMessage id="path./sign-up" />
          </Typography>
          <Typography variant="body1" gutterBottom>
            <FormattedMessage id="sign.up.subheading" />
          </Typography>

          <form
            className={styles.form}
            onSubmit={e => {
              e.preventDefault();
              s.signUp();
            }}
          >
            <TextField
              label={this.props.intl.formatMessage({ id: "sign.label.email" })}
              fullWidth
              margin="normal"
              variant="outlined"
              error={!!s.valid.errors.first("email")}
              helperText={s.valid.errors.first("email")}
              onChange={this.handleChange("email")}
              value={s.form.email}
            />
            <TextField
              label={this.props.intl.formatMessage({
                id: "sign.label.password"
              })}
              fullWidth
              margin="normal"
              variant="outlined"
              type="password"
              error={!!s.valid.errors.first("password")}
              helperText={s.valid.errors.first("password")}
              onChange={this.handleChange("password")}
              value={s.form.password}
            />
            <TextField
              label={this.props.intl.formatMessage({
                id: "sign.label.passwordAgain"
              })}
              fullWidth
              margin="normal"
              variant="outlined"
              type="password"
              onChange={this.handleChange("password_confirmation")}
              value={s.form.password_confirmation}
            />

            <FormControl
              required
              error={!!s.valid.errors.first("terms")}
            >
            <FormHelperText>{s.valid.errors.first("terms")}</FormHelperText>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={s.form.terms}
                    onChange={(_, val) => s.form.set("terms", val)}
                  />
                }
                label={
                  <Link to={Navigator.resolvePath('TermsOfUse')} style={{color: "white"}}>
                    {this.props.intl.formatMessage({
                    id: "sign.up.agreeWithTerms"
                    })}
                  </Link>
                }
              />
            </FormControl>
            <Button
              variant="contained"
              color="secondary"
              className={styles.signButton}
              type="submit"
            >
              <FormattedMessage id="path./sign-up" />
              <NavigateNextIcon />
            </Button>
          </form>
        </Paper>

        <Typography variant="body1" gutterBottom align="center">
          <FormattedMessage id="sign.in.question" />
          <Button
            variant="text"
            color="secondary"
            onClick={() => Navigator.push('SignIn')}
            size="small"
            style={{
                marginBottom: '10px',
                marginLeft: '5px'
            }}
          >
            <FormattedMessage id="path./" />
          </Button>
        </Typography>
      </SignLayout>
    );
  }
}

export default injectIntl(SignUp);
