import { observable, flow, action, computed } from 'mobx';
import Validator from 'validatorjs';

import {
  fetchGateways,
  fetchDevices,
  fetchNetwork,
  patchDevice,
  patchGroup,
  patchRoom,
  patchGateway,
  updateBlueprint,
  updateView,
  deleteView,
  deleteRoom,
  deleteGroup,
  deleteDevice,
  deleteGateway,
  postDevice,
  postGroup,
  postGateway,
  postRoom,
  postView
} from '../endpoints';
import dayjs from 'dayjs';
import find from 'lodash/find';


class Level {
  @observable children = [];
  @observable id = '';
  @observable isExpanded = true;
  @observable DnD_Id = '';
  @observable title = '';
  @observable newTitle = '';
  @observable newSerial = '';
  @observable modalOpen = false;
  @observable settingsOpen = false;
  @observable modalDelete = false;
  @observable droppableTo = null;
  @observable parent = null;
  @observable submitted = null;

  constructor(config) {
    this.initLevel(config);
  }

  initLevel = (config) => {
    this.id = config.id;
    this.DnD_Id = `${config.name} in ${config.id}`;
    this.title = config.name;
    this.newTitle = this.title;
  }

  @computed get valid() {
    const validator = new Validator({ name: this.newTitle }, { name: 'required' });
    if (this.submitted) {
      validator.passes();
    }
    return validator;
  }

  @computed get validSerial() {
    const validator = new Validator({ serial: this.newSerial }, { serial: 'required' });
    if (this.submitted) {
      validator.passes();
    }
    return validator;
  }

  @action handleModal = () => {
    this.modalOpen = !this.modalOpen;
  };

  @action handleOpenSettings = () => {
    this.settingsOpen =  !this.settingsOpen;
  }

  @action changeName = flow(function* (levelData, newName) {
    if (levelData.level === 4) {
      const room = (yield patchGroup(this.id, {
        name: newName
      })).data.data;
      levelData.replace(room);
    } else if (levelData.level === 3) {
      const room = (yield patchRoom(levelData.id, {
        name: newName
      })).data.data;
      levelData.replace(room);
    } else if (levelData.level === 2) {
      const view = (yield updateView(levelData.id, {
        name: newName
      })).data.data;
      levelData.replaceView(view);
    }
  })

  @action addToLevel = flow(function* (config) {
    try {
      if (config.name) {
        if (config.droppableTo === 4) {
          const room = (yield postDevice(
            this.id,
            { serial_code: config.serial, name: config.name }
            )).data.data;
          this.replace(room);
        } else if (config.droppableTo === 3) {
          const room = (yield postGroup(
            this.id,
            config.name
            )).data.data;
          this.replace(room);
        } else if (config.droppableTo === 2) {
          const network = (yield postRoom(
            this.id,
            config.name
            )).data.data;
          this.replaceNetwork(network);
        } else if (config.droppableTo === 1) {
          const network = (yield postView(
            this.id,
            config.name
            )).data.data;
          this.replaceNetwork(network);
        }
      }
    } catch (error) {
      window.Store.handleError('device.add');
      throw error;
    }
  })

  @action handleDelete = flow(function* (levelData) {
    try {
      if (levelData.level === 2) {
        const network = (yield deleteView(levelData.id)).data.data;
        levelData.replaceNetwork(network);
      } else if (levelData.level === 3) {
        const network = (yield deleteRoom(levelData.id)).data.data;
        levelData.replaceNetwork(network);
      } else if (levelData.level === 4) {
        const room = (yield deleteGroup(levelData.id)).data.data;
        levelData.replace(room);
      } else if (levelData.id === 5) {
        const room = (yield deleteDevice(levelData.id));
        levelData.replace(room);
      }
    } catch (error) {
      window.Store.handleError('autoMode.fetch');
      throw error;
    }
  }.bind(this))

  @action handleModalDelete = () => {
    this.modalDelete = !this.modalDelete;
  }
}

class Device {
  @observable id = '';
  @observable title = '';
  @observable newTitle = '';
  @observable serial = '';
  @observable droppableTo = null;
  @observable settingsOpen = false;
  @observable alive = null;
  @observable modalDelete = false;
  @observable DnD_Id = '';
  @observable parent = null;
  @observable submitted = null;

  constructor(config) {
    this.id = config.id;
    this.DnD_Id = `${config.name} in ${config.id}`;
    this.serial = `${config.serial_code}`;
    this.alive = config.alive;
    this.title = config.name;
    this.droppableTo = config.droppableTo;
  }

  @computed get valid() {
    const validator = new Validator({ name: this.newTitle }, { name: 'required' });
    if (this.submitted) {
      validator.passes();
    }
    return validator;
  }

  @action handleDelete = flow(function* (levelData) {
    if (levelData.level === 'Gateway') {
      const parent = levelData.parent;
      yield deleteGateway(levelData.id);
      parent.init();
    } else {
      const room = (yield deleteDevice(levelData.id)).data.data;
      levelData.replace(room);
    }
  })

  @action handleModalDelete = () => {
    this.modalDelete = !this.modalDelete;
  }

  @action handleOpenSettings = () => {
    this.settingsOpen =  !this.settingsOpen;
  }

  @action changeName = flow(function* (levelData, newName) {
    if (levelData.level !== 'Gateway') {
      const room = (yield patchDevice(this.id, {
        name: newName
      })).data.data;
      levelData.replace(room);
    } else {
      yield patchGateway(this.id, { name: newName });
      this.parent.init();
    }
  })
}

class Gateways {
  @observable id = '';
  @observable DnD_Id = '';
  @observable title = '';
  @observable newTitle = '';
  @observable newSerial = '';
  @observable modalOpen = false;
  @observable submitted = null;
  @observable children = [];
  @observable droppDisabled = true;
  @observable level = 'Gateways';

  constructor() {
    this.init();
  }

  init = flow(function* () {
    this.children = [];
    this.id = 'Gateways';
    this.DnD_Id = 'Gateways';
    this.title = 'Gateways';

    try {
      const gws = yield fetchGateways();
      gws.data.data.forEach((gw) => {
        this.children.push(new Gateway(gw, this.level, this));
      });
      this.loaded = true;
    } catch (error) {
      this.loadingError = true;
      throw error;
    }
  }.bind(this));

  @computed get valid() {
    const validator = new Validator({ name: this.newTitle }, { name: 'required' });
    if (this.submitted) {
      validator.passes();
    }
    return validator;
  }

  @computed get validSerial() {
    const validator = new Validator({ serial: this.newSerial }, { serial: 'required' });
    if (this.submitted) {
      validator.passes();
    }
    return validator;
  }

  updateOnOff = (id, value) => {
    const gw = find(this.children, { id });
    if (gw) {
      if (gw.alive && !value) {
        window.Store.notifications.push(
          new Notification(1, `Gateway ${gw.serial} has gone off-line (${dayjs().format('HH:mm')})`)
        );
      }
      gw.alive = value;
    }
  };

  @action handleModal = () => {
    this.modalOpen = !this.modalOpen;
  };

  @action addToLevel = flow(function* (config) {
    yield postGateway(config.serial, config.name);
    this.init();
  })
}

class Gateway extends Device {
  @observable droppDisabled = false;
  @observable level = 'Gateway';

  constructor(config, droppableLevel, parent) {
    super(config);
    this.init(config, droppableLevel, parent);
  }

  init = (config, droppableLevel, parent) => {
    this.droppableTo = droppableLevel;
    this.parent = parent;
    this.title = `${config.name} ${config.id}`;
  };
}

class Network extends Level {
  @observable droppDisabled = true;
  @observable level = 1;

  constructor(config) {
    super(config);
    this.init(config);
  }

  init = (config) => {
    this.parent = config.id;
    config.views.forEach(view => {
      const viewConfig = view && view;
      const droppableLevel = this.level;
      this.children.push(new View(viewConfig, droppableLevel, this));
    });
  }

  replaceNetwork = (network) => {
    this.children = [];
    network.forEach(view => {
      const viewConfig = view && view;
      const droppableLevel = this.level;
      this.children.push(new View(viewConfig, droppableLevel, this));
    });
  }
}

class View extends Level {
  @observable droppDisabled = true;
  @observable level = 2;
  @observable allLights = [];

  constructor(config, droppableLevel, parent) {
    super(config);
    this.init(config, droppableLevel, parent);
  }
  init = (config, droppableLevel, parent) => {
    this.parent = parent;
    this.droppableTo = droppableLevel;
    config.rooms.forEach(room => {
      const roomConfig = room && room;
      const droppableToLevel = this.level;
      this.children.push(new Room(roomConfig, droppableToLevel, this));
    });
  }

  @computed
  get replaceNetwork() {
    return this.parent.replaceNetwork;
  }

  uploadBlueprint = flow(function* (id, file) {
    const data = new FormData();
    data.append('image', file);

    try {
      yield updateBlueprint(id, data);
    } catch (error) {
      window.Store.handleError('view.update');
      throw error;
    }
  });

  replaceView = (view) => {
    this.initLevel(view);
  }
}

class Room extends Level {
  @observable droppDisabled = true;
  @observable level = 3;

  constructor(config, droppableLevel, parent) {
    super(config);
    this.init(config, droppableLevel, parent);
  }
  init = (config, droppableLevel = null, parent = null) => {
    this.children = [];

    if (droppableLevel) {
      this.droppableTo = droppableLevel;
    }

    if (parent) {
      this.parent = parent;
    }

    config.groups.forEach(group => {
      const groupConfig = group && group;
      const droppableToLevel = this.level;
      this.children.push(new Group(groupConfig, droppableToLevel, this));
    });
  }

  replace = (room) => {
    this.initLevel(room);
    this.init(room);
  }

  @computed
  get replaceNetwork() {
    return this.parent.replaceNetwork;
  }
}

class Group extends Level {
  @observable droppDisabled = true;
  @observable level = 4;

  constructor(config, droppableLevel, parent) {
    super(config);
    this.init(config, droppableLevel, parent);
  }

  init = (config, droppableLevel, parent) => {
    this.droppableTo = droppableLevel;
    this.parent = parent;
    config.lights.forEach(light => {
      const lightConfig = light && light;
      const droppableToLevel = this.level;
      this.children.push(new Light(lightConfig, droppableToLevel, this));
    });
  }

  @computed
  get replace() {
    return this.parent.replace;
  }

  @computed
  get replaceNetwork() {
    return this.parent.replaceNetwork;
  }
}

class Light extends Device {
  @observable droppDisabled = true;
  @observable level = 5;

  constructor(config, droppableLevel, parent) {
    super(config);
    this.init(config, droppableLevel, parent);
  }

  init = (config, droppableLevel, parent) => {
    this.droppableTo = droppableLevel;
    this.parent = parent;
  };

  @computed
  get replace() {
    return this.parent.replace;
  }

  @computed
  get replaceNetwork() {
    return this.parent.replaceNetwork;
  }
}

export class HierarchySettings {
  @observable network = [];
  @observable isEditting = false;
  @observable allLights = [];
  @observable modalLightFormValue = '';
  @observable title = '';
  @observable loaded = false;
  @observable loadingError = false;

  init = flow(function* (networkId) {
    this.network = [];
    this.allLights = [];

    try {
      const network = (yield fetchNetwork(networkId)).data.data;
      const devices = (yield fetchDevices(network.id)).data.data;
      this.allLights = devices;
      const gateways = new Gateways();
      this.title = network.name;
      this.modalLightFormValue='New light';
      this.network.push(new Network(network));
      this.network.push(gateways);
      this.loaded = true;
    } catch (error) {
      window.Store.handleError('autoMode.fetch');
      throw error;
    }
  }.bind(this));

  @action changeFormValue = (newValue) => {
    try {
      this.modalLightFormValue = newValue;
    } catch (error) {
      window.Store.handleError('autoMode.fetch');
      throw error;
    }
  };

  @action getChildren = (id, data) => {
    let result = null;
    data && data.forEach((child) => {
      if (id === child.DnD_Id) {
        result = child;
      } else {
        const searched = this.getChildren(id, child.children);
        if (searched) {
          result = searched;
        }
      }
    });
    return result;
  };

  @action changeParent = flow(function* (source, destination, draggableId) {
    const level = this.getChildren(draggableId, this.network);
    const startLevel = this.getChildren(source.droppableId, this.network);
    const destinationLevel = this.getChildren(destination.droppableId, this.network);
    const parentsChildrenOrder = [];
    destinationLevel.children.map(child => parentsChildrenOrder.push(child.id));
    parentsChildrenOrder.splice(source.index, 1);
    parentsChildrenOrder.splice(destination.index, 0, level.id);

    if (startLevel === destinationLevel) {
      try {
        if (level.level === 5) {
          const room = (yield patchDevice(level.id, {
            parents_children_order: parentsChildrenOrder
          })).data.data;
          level.replace(room);
        } else if (level.level === 4) {
          const room = (yield patchGroup(level.id, {
            parents_children_order: parentsChildrenOrder
          })).data.data;
          level.replace(room);
        } else if (level.level === 3) {
          const network = (yield patchRoom(level.id, {
            parents_children_order: parentsChildrenOrder
          })).data.data;
          level.replaceNetwork(network);
        } else if (level.level === 2) {
          const network = (yield updateView(level.id, {
            parents_children_order: parentsChildrenOrder
          })).data.data;
          level.replaceNetwork(network);
        }
      } catch (error) {
        window.Store.handleError('device.rename');
        throw error;
      }
    } else {
      try {
        if (level.level === 5) {
          const network = (yield patchDevice(level.id, {
            parent_id: destinationLevel.id,
            parents_children_order: parentsChildrenOrder
          })).data.data;
          level.replaceNetwork(network);
        } else if (level.level === 4) {
          const network = (yield patchGroup(level.id, {
            parent_id: destinationLevel.id,
            parents_children_order: parentsChildrenOrder
          })).data.data;
          level.replaceNetwork(network);
        } else if (level.level === 3) {
          const network = (yield patchRoom(level.id, {
            parent_id: destinationLevel.id,
            parents_children_order: parentsChildrenOrder
          })).data.data;
          level.replaceNetwork(network);
        }
      } catch (error) {
        window.Store.handleError('device.rename');
        throw error;
      }
    }
  });

  @action enableDrop = (droppableLevel, data) => {
    data && data.forEach(child => {
      if (droppableLevel === child.level) {
        child.droppDisabled = false;
      } else {
        this.enableDrop(droppableLevel, child.children);
      }
    });
  }

 @action disableDrop = (data) => {
  data && data.forEach(child => {
      child.droppDisabled = true;
      this.disableDrop(child.children);
    });
  };

  @action addViewToNetwork = (level) => {
    const newView = {
      title: `View-${level.children.length + 1}`,
      level: 2,
      modalOpen: false,
      children: [],
      droppDisabled: true,
      droppableTo: 1,
      id: `${level.title}-View-${level.children.length + 1}`
    };
    this.addRoomToView(newView);
    level.children.push(newView);
  };

 @action addRoomToView = (level) => {
    const newRoom = {
      title: `Room-${level.children.length + 1}`,
      level: 3,
      modalOpen: false,
      children: [],
      droppDisabled: true,
      droppableTo: 2,
      id: `${level.title}-Room-${level.children.length + 1}`
    };
    this.addGroupToRoom(newRoom);
    level.children.push(newRoom);
  };

  @action addGroupToRoom = (level) => {
    const newGroup = {
      title: `group-${level.children.length + 1}`,
      level: 4,
      droppDisabled: true,
      droppableTo: 3,
      modalOpen: false,
      children: [],
      id: `${level.title}-group-${level.children.length + 1}`
    };
    level.children.push(newGroup);
  };
}
