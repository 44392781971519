import React, { memo } from 'react';
import Draggable from 'react-draggable';
import classNames from 'classnames';
import { KEY_DELETE } from 'keycode-js';

import {Typography} from '@material-ui/core';
import styles from './Chart.module.scss';

const POINT_WIDTH = 16;

const Point = ({
  value,
  type,
  x,
  y,
  disabled,
  onChange,
  max,
  onDelete,
  deletable,
  line = false,
  axis = null,
  bounds,
  astralRectangleWidth,
  timeFormatter,
  duration,
  hidePoints,
  pointTestID,
  pixelsYRatio,
}) => {

  const onKeyDown = (e) => {
    if (e.keyCode === KEY_DELETE) {
      onDelete();
    }
  };

  if (!hidePoints && type === 'classic' || type === undefined) {
    return (
      <>
        <Draggable
          bounds={{left: (bounds.leftBound + 10), top: 0, right: (bounds.rightBound - 10), bottom: 100}}
          axis={axis}
          onDrag={(e, position) => {
            return onChange({
              x: axis === null || axis === 'x' ? position.x : x,
              y: axis === null || axis === 'y' ? position.y : y,
            });}
          }
          position={{ x, y }}
          disabled={disabled}
        >
          <button
            onKeyDown={onKeyDown}
            className={classNames(styles.point, {[ styles.disabled ]: disabled})}
            data-test-id={pointTestID}
          >
            {!disabled && deletable && (
              <div onClick={onDelete} className={styles.delete} data-test-id="deleteButton">
                x
              </div>
            )}
            <span className={styles.yValue}>
              {max - (y / pixelsYRatio)}
            </span>
          </button>
        </Draggable>

        {line && (
          <div
            className={styles.pointLine}
            style={{ height: `${max - y}px`, left: `${x + (POINT_WIDTH / 2)}px` }}
          />
        )}

        <div
          className={styles.pointLabel}
          style={{ left: `${x + (POINT_WIDTH / 2)}px` }}
        >
          <Typography variant="caption" className={styles.labelText}>{timeFormatter(value)}</Typography>
        </div>
      </>
    );
  } else {
    return (
      <>
        {type === 'astralStart' && (
          <div
            data-test-id="astralRectVisible"
            className={styles.astralRect}
            style={{
              width: `${(astralRectangleWidth + 1)}px`,
              left: `${x + (POINT_WIDTH / 2)}px`,
            }}
          />
        )}

        {(
          type === 'X2BeforeSunrise' ||
          type === 'XBeforeSunrise' ||
          type === 'sunrise' ||
          type === 'GoldenHourSunrise' ||
          type === 'GoldenHourSunset' ||
          type === 'sunset' ||
          type === 'XAfterSunset' ||
          type === 'X2AfterSunset'
        ) && !hidePoints && (
          <>
            <Draggable
              bounds="parent"
              axis={axis}
              onDrag={(e, position) => {
                return !disabled && onChange({
                  x: axis === null || axis === 'x' ? position.x : x,
                  y: axis === null || axis === 'y' ? position.y : y,
                });}
              }
              position={{ x, y }}
              onStart = {() => !disabled}
            >
              <button data-test-id="astrallButton" className={classNames(styles.point, {[ styles.disabled ]: disabled})}>
                <span className={styles.yValue}>
                  {max - (y / pixelsYRatio)}
                </span>
              </button>
            </Draggable>

            {line && (
              <div
                className={styles.pointLine}
                style={{ height: `${max - y}px`, left: `${x + (POINT_WIDTH / 2)}px` }}
              />
            )}

            <div
              className={styles.pointLabel}
              style={{ left: `${x + (POINT_WIDTH / 2)}px` }}
            >
              <Typography data-test-id="astralLabel" variant="caption" className={styles.labelText}>
                {
                  (type === 'X2BeforeSunrise') && '2X' ||
                  (type === 'XBeforeSunrise') && 'X' ||
                  (type === 'sunrise') && 'Sunrise' ||
                  (type === 'GoldenHourSunrise') && 'GoldenHour' ||
                  (type === 'GoldenHourSunset') && 'GoldenHour' ||
                  (type === 'sunset') && 'Sunset' ||
                  (type === 'XAfterSunset') && 'X' ||
                  (type === 'X2AfterSunset') && '2X'
                }
              </Typography>
            </div>
          </>
        )}
      </>
    );
  }
};

export default memo(Point);
