import React from 'react';
import { injectIntl } from 'react-intl';
import { observer, inject } from 'mobx-react';

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Button,
  Grow,
  Paper,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  TableSortLabel,
  CircularProgress,
} from '@material-ui/core';

import AddCircleIcon from '@material-ui/icons/AddCircle';

import RemoveButton from '../../components/RemoveButton';
import LoadingError from '../../components/LoadingError';
import DialogTitle from '../../components/DialogTitle';

import styles from './RoomSettings.module.scss';

@inject('store')
@observer
class NetworkSettings extends React.Component {
  state = {
    openAdd: false,
    openRemove: false,
    orderBy: 'name',
    order: 'asc',
  };

  async componentDidMount() {
    await window.Store.setAccessToken();
    await window.Store.init();
    this.props.store.networkSettings.init();

    this.props.store.crumbs = [
      { label: 'Control Center', value: '/in' },
      { label: 'Network settings' },
    ];
  }

  toggleAdd = () => this.setState({ openAdd: !this.state.openAdd });
  toggleRemove = () => this.setState({ openRemove: !this.state.openRemove });
  orderBy = (orderBy) => {
    const toggleOrder = (order) => order === 'asc' ? 'desc' : 'asc';

    if (orderBy === this.state.orderBy) {
      this.setState({ order: toggleOrder(this.state.order) });
    } else {
      this.setState({ orderBy, order: 'asc' });
    }
  };

  renameChange = (event) => {
    const targetNetwork = this.props.store.networkSettings.networks.filter(network => network.id.toString() === event.target.name)[0];
    targetNetwork.name = event.target.value;
  }

  renameBlur = (event) => {
    this.props.store.networkSettings.networks.filter(network => network.id = event.target.name)[0].update(event.target.value);
  }

  handleChange = (prop) => (event) => {
    this.props.store.networkSettings.form.set(prop, event.target.value);
  };

  render() {
    const s = this.props.store.networkSettings;

    return (
      <React.Fragment>
        <div className={styles.wrapper}>
          <Grow in={true} timeout={700}>
            <Paper elevation={2} className={styles.paper}>
              <Typography variant="body1" color="textSecondary">
                Physical network (f.e. for one building). Network is a top level entity in Suntense system.
              </Typography>
            </Paper>
          </Grow>
          <Grow in={true} timeout={1100}>
            <Paper elevation={2} className={styles.tablePaper}>

              {!s.loaded && !s.loadingError &&
                <div className={styles.progress}>
                  <CircularProgress />
                </div>
              }

              {s.loadingError &&
                <LoadingError />
              }

              {s.loaded &&
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sortDirection={this.state.orderBy === 'name' ? this.state.order : false}>
                        <TableSortLabel
                          active={this.state.orderBy === 'name'}
                          direction={this.state.order}
                          onClick={() => this.orderBy('name')}
                        >
                          Name
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {s.networks.map((network, index) => (
                      <TableRow key={index}>
                      <TableCell>
                        <TextField
                          placeholder="Set name"
                          margin="normal"
                          name={network.id.toString()}
                          value={network.name}
                          error={!!network.valid.errors.first('name')}
                          helperText={network.valid.errors.first('name')}
                          onChange={this.renameChange}
                          onBlur={this.renameBlur}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <RemoveButton
                          onClick={() => {
                            this.setState({ toRemove: network });
                            this.toggleRemove();
                          }}
                        />
                      </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              }
            </Paper>
          </Grow>

          <div className={styles.addButton}>
            <Button variant="contained" color="secondary" onClick={this.toggleAdd}>
              Add network <AddCircleIcon className="ml-1" />
            </Button>
          </div>
        </div>


        <Dialog
          open={this.state.openAdd}
          onClose={this.toggleAdd}
        >
          <form onSubmit={(e) => {
            e.preventDefault();
            s.add();

            if (s.valid.passes()) {
              this.toggleAdd();
            }
          }}>
            <DialogTitle
              title='Add a network'
              onClose={this.toggleAdd}
            />
            <DialogContent>
              <DialogContentText>
                Choose an unique name.
              </DialogContentText>
              <TextField
                label="Network name"
                margin="normal"
                fullWidth
                variant="outlined"
                error={!!s.valid.errors.first('name')}
                helperText={s.valid.errors.first('name')}
                onChange={this.handleChange('name')}
                value={s.form.get('name')}
              />
            </DialogContent>
            <DialogActions>
              <Button type="submit" color="primary">
                Add
              </Button>
            </DialogActions>
          </form>
        </Dialog>

        {this.state.toRemove && (
          <Dialog
            open={this.state.openRemove}
            onClose={this.toggleRemove}
          >
            <DialogTitle
              title='Remove network'
              onClose={this.toggleRemove}
            />
            <DialogContent>
              <DialogContentText>
                Are you sure you want to remove this network? It will remove all associated gateways, views, rooms and devices.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.toggleRemove} color="primary">
                Cancel
              </Button>
              <RemoveButton
                onClick={() => {
                  this.toggleRemove();
                  this.state.toRemove.remove();
                }}
                long
              />
            </DialogActions>
          </Dialog>
        )}
      </React.Fragment>
    );
  }
}

export default injectIntl(NetworkSettings);
