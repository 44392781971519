import React from 'react';
import { observer, inject } from 'mobx-react';
import Draggable from 'react-draggable';
import { autorun } from 'mobx';
import { SERVER_URL } from '../../shared/helpers/env';
import axios from 'axios';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import classnames from 'classnames';

import {
  Paper,
  CircularProgress,
  Popover,
  Typography,
} from '@material-ui/core';

import LoadingError from '../../components/LoadingError';
import Control from './control/Control';
import LightIcon from './control/LightIcon';
import SignalWifi1BarIcon from '@material-ui/icons/SignalWifi1Bar';
import SignalWifi2BarIcon from '@material-ui/icons/SignalWifi2Bar';
import SignalWifi3BarIcon from '@material-ui/icons/SignalWifi3Bar';
import SignalWifi4BarIcon from '@material-ui/icons/SignalWifi4Bar';
import find from 'lodash/find';

import styles from './Blueprint.module.scss';

@inject('store')
@observer
class SignalLine extends React.Component {
  getLineLength (x1, y1, x2, y2) {
    return Math.sqrt(((x2 - x1) * (x2 - x1)) + ((y2 - y1) * (y2 - y1)));
  }

  getLeftOffset (x1, y1, x2, y2) {
    return ((x1 + x2) / 2) - (this.getLineLength(x1, y1, x2, y2) / 2);
  }

  getTopOffset (y1, y2) {
    return ((y1 + y2) / 2) - (1 / 2);
  }

  getRotation (x1, y1, x2, y2) {
    return Math.atan2((y1 - y2), (x1 - x2)) * (180 / Math.PI);
  }

  Coordinates (lightPositions) {
    if (lightPositions[this.props.link.ANodeId] && lightPositions[this.props.link.BNodeId]) {
      return {
        x1: lightPositions[this.props.link.ANodeId].x,
        y1: lightPositions[this.props.link.ANodeId].y,
        x2: lightPositions[this.props.link.BNodeId].x,
        y2: lightPositions[this.props.link.BNodeId].y,
      };
    } else {
      return null;
    }
  }

  handleHover (over, signalANode, signalBNode) {
    const view = this.props.store.controlCenter.currentViewObject;
    const ANode = find(view.lights, {id: this.props.link.ANodeId});
    const BNode = find(view.lights, {id: this.props.link.BNodeId});

    if (over) {
      ANode.showDiagnostics = true;
      BNode.showDiagnostics = true;
      ANode.signal = signalANode;
      BNode.signal = signalBNode;
      ANode.rssi = this.props.link.ANodeRssi;
      BNode.rssi = this.props.link.BNodeRssi;
    } else {
      ANode.showDiagnostics = false;
      BNode.showDiagnostics = false;
      ANode.signal = null;
      BNode.signal = null;
      ANode.rssi = null;
      BNode.rssi = null;
    }
  }

  getSignalLevel (Rssi) {
      if (Rssi !== null && Rssi > -65) {
        return 1;
      } else if (Rssi > -70) {
        return 2;
      } else if (Rssi > -80) {
        return 3;
      } else if (Rssi <= -80) {
        return 4;
      }
    }

  getAlive (AnodeId, BnodeId) {
    const view = this.props.store.controlCenter.currentViewObject;
    const ANode = find(view.lights, {id: AnodeId});
    const Bnode = find(view.lights, {id: BnodeId});

    if ((ANode && ANode.alive) && (Bnode && Bnode.alive)) {
      return true;
    } else {
      return false;
    }
  }

  getGradientColour (Rssi) {
    if (Rssi !== null && Rssi > -65) {
        return "#EECA38, #EECA38";
      } else if (Rssi > -70) {
        return "#EECA38, #EECA38";
      } else if (Rssi > -80) {
        return "#EECA38, #EECA38";
      } else if (Rssi <= -80) {
        return "#E94338, #E94338";
      }
  }

  render() {
    const store = this.props.store.controlCenter;
    const cordinates = store.currentViewObject && this.Coordinates(store.currentViewObject.lightPositions);
    const ANodeRssi = this.props.link.ANodeRssi;
    const BNodeRssi = this.props.link.BNodeRssi;
    const signalANode = this.getSignalLevel(ANodeRssi);
    const signalBNode = this.getSignalLevel(BNodeRssi);
    return (
      <>
        {(store.loaded && cordinates && this.getAlive(this.props.link.ANodeId, this.props.link.BNodeId)) &&
          <div
            className={styles.signalLine}
            style={{
              transform: "rotate(" + this.getRotation(cordinates.x1, cordinates.y1, cordinates.x2, cordinates.y2) + "deg)",
                    background:
                      (ANodeRssi < BNodeRssi) && "linear-gradient(to right," + this.getGradientColour(ANodeRssi) + ", " + this.getGradientColour(BNodeRssi) + ")" ||
                      (ANodeRssi > BNodeRssi) && "linear-gradient(to left," + this.getGradientColour(ANodeRssi) + ", " + this.getGradientColour(BNodeRssi) + ")" ||
                      (signalANode === signalBNode) && (signalANode <=3 && signalBNode <=3 ) && "#EECA38" ||
                      (signalANode === signalBNode) && (signalANode > 3 && signalBNode > 3 ) && "#E94338",
              width: this.getLineLength(cordinates.x1, cordinates.y1, cordinates.x2, cordinates.y2) + 'px',
              left: this.getLeftOffset(cordinates.x1, cordinates.y1, cordinates.x2, cordinates.y2) + 15 + 'px',
              top: this.getTopOffset(cordinates.y1, cordinates.y2) + 15 + 'px',
            }}
            onMouseEnter={() => this.handleHover(true, signalANode, signalBNode)}
            onMouseLeave={() => this.handleHover(false, signalANode, signalBNode)}
          >
            <div
              className={classnames(
                styles.signalPoint,
                {
                  [styles.halfSignalX1]: (ANodeRssi < BNodeRssi) && signalANode >= 4,
                  [styles.halfSignalX2]: (ANodeRssi > BNodeRssi) && signalBNode >= 4,
                  [styles.FullSignal]: (signalANode <=3 && signalBNode <=3)
                })}
            />
          </div>
        }
      </>
    );
  }
}

@inject('store')
@observer
class Light extends React.Component {
  state = {
    dragging: false,
  };

  onStart = (e) => {
    e.stopPropagation();
  };

  onDrag = (e, data) => {
    e.stopPropagation();
    this.setState({ dragging: true });
    this.props.store.controlCenter.view.patchLightPosition(
      this.props.light.id,
      data.x,
      data.y,
      false,
    );
  };

  onStop = (e, data) => {
    e.stopPropagation();
    this.props.store.controlCenter.view.patchLightPosition(
      this.props.light.id,
      data.x,
      data.y,
      true,
    );

    setTimeout(() => {
      this.setState({ dragging: false });
    });
  };

  onClick = (e) => {
    if (!this.state.dragging) {
      this.props.onOpen(e, this.props.light, this.props.light.parent.parent);
    }
  };

  render() {
    const { light } = this.props;
    const s = this.props.store.controlCenter;

    return (
      <React.Fragment>
        <Draggable
          onStart={this.onStart}
          onStop={this.onStop}
          onDrag={this.onDrag}
          defaultPosition={{ x: s.view.getLightPosition(light.id).x, y: s.view.getLightPosition(light.id).y }}
          disabled={s.view.getLightPosition(light.id).disabled}
          scale={this.props.scale}
        >
          <Paper className={styles.device} onClick={this.onClick} elevation={1}>
              {light && light.alive && light.showDiagnostics && light.signal && (
                  <Paper className={styles.deviceRssi}>
                    <Typography variant="caption">
                      {light.rssi}
                    </Typography>
                  </Paper>
              )}
              {
                (light && light.alive && light.showDiagnostics && light.signal) === 1 && <SignalWifi4BarIcon /> ||
                (light && light.alive && light.showDiagnostics && light.signal) === 2 && <SignalWifi3BarIcon /> ||
                (light && light.alive && light.showDiagnostics && light.signal) === 3 && <SignalWifi2BarIcon /> ||
                (light && light.alive && light.showDiagnostics && light.signal) === 4 && <SignalWifi1BarIcon /> ||
                  <LightIcon
                    alive={light.alive}
                    actualChromaticity={light.computedChromaticity}
                    actualBrightness={light.computedBrightness}
                    kelvinRange={light.kelvinRange}
                  />
              }
          </Paper>
        </Draggable>
      </React.Fragment>
    );
  }
}

@inject('store')
@observer
class Blueprint extends React.Component {
  state = {
    store: null,
    roomStore: null,
    anchor: null,
    loadingBlueprint: true,
    loadingBlueprintError: false,
    lightPositions: this.props.lightPositions,
  };

  componentDidMount() {
    const s = this.props.store.controlCenter;

    autorun(() => {
      this.setState({
        loadingBlueprint: true,
        loadingBlueprintError: false,
      });

      if (s.loaded) {
        axios.get(`${SERVER_URL}/view/${s.view.id}/blueprint`, { responseType: 'blob' }).then(response => {
          const reader = new window.FileReader();
          reader.readAsDataURL(response.data);
          reader.onload = () => {
            this.setState({ loadingBlueprint: false });
            if (this.img) {
              this.img.setAttribute('src', reader.result);
            }
          };
        }).catch(e => {
          this.setState({ loadingBlueprintError: true });
        });

      }
    });
  }

  onClose = () => {
    this.setState({ anchor: null });
  };

  render() {
    const s = this.props.store.controlCenter;
    const diagnosticsOn = s.diagnosticsOn;
    return (
      <div className={classnames(styles.wrapper, { 'hidden-mobile': s.currentTab === 'list' })}>
        {s.loadingError &&
          <div className={styles.progress}>
            <LoadingError />
          </div>
        }
        {(this.state.loadingBlueprintError && !s.loadingError) &&
          <div className={styles.progress}>
            <LoadingError msg="Blueprint missing for this view." />
          </div>
        }
        {!this.state.loadingBlueprintError && !s.loadingError && this.state.loadingBlueprint &&
          <div className={styles.progress}>
            <CircularProgress />
          </div>
        }
        {!this.state.loadingBlueprintError && !s.loadingError && !this.state.loadingBlueprint && (
          <div className={styles.blueprintWrapper}>
            <TransformWrapper defaultScale={1} options={{ limitToBounds: false, disabled: !!this.state.anchor }} wheel={{ step: 20 }}>
              {({ scale }) => (
                <TransformComponent>
                  <div className={styles.blueprint}>
                    {s.loaded && s.view.lights.map((light) => (
                      <Light
                        light={light}
                        key={light.id}
                        onOpen={(e, store, roomStore) => this.setState({ anchor: e.currentTarget, store, roomStore })}
                        scale={scale}
                        absolute
                      />
                    ))}

                    {(s.loaded && diagnosticsOn) && s.currentViewObject.links.map(link => (
                      <SignalLine link={link}/>
                    ))}

                    <img ref={ref => this.img = ref} alt="Blueprint" />
                  </div>
                </TransformComponent>
              )}
            </TransformWrapper>

            <Popover
              open={Boolean(this.state.anchor)}
              onClose={this.onClose}
              anchorEl={this.state.anchor}
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <Control
                level="light"
                s={this.state.store}
                roomStore={this.state.roomStore}
                onClose={this.onClose}
              />
            </Popover>
          </div>
        )}
      </div>
    );
  }
}

export default Blueprint;
