import React from 'react';
import { observer } from 'mobx-react';
import { Paper } from '@material-ui/core';

import Group from './Group';

import styles from './Hierarchy.module.scss';

@observer
class Hierarchy extends React.Component {
  render() {
    const elements = React.Children.toArray(this.props.children(this.props.hierarchy));

    return (
      <div className={styles.hierarchy}>
        <Paper className={styles.paper}>
          {elements.map((child) => React.cloneElement(child))}
        </Paper>
        {this.props.hierarchy.groups.map((group, index) => (
          <Group
            key={group.id}
            group={group}
            isLast={index === this.props.hierarchy.groups.length - 1}
          >
            {this.props.children}
          </Group>
        ))}
      </div>
    );
  }
}

export default Hierarchy;
