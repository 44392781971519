import { observable, computed, action } from 'mobx';
import every from 'lodash/every';
import some from 'lodash/some';

// import { patchGroup } from '../../endpoints';
import Controlable from '../extendable/controlable';

export default class Group extends Controlable {
  @observable id = 0;
  @observable name = '';
  @observable lights = [];
  @observable kelvinRange = 0;
  @observable expanded = false;
  // @observable sensor = null;
  // @observable sensorControlled = false;
  @observable parent = null;

  constructor(d, parent) {
    super(d);
    this.replace(d, parent);
  }

  @action
  replace = (d, parent) => {
    this.replaceControlable(d);

    if (parent) {
      this.parent = parent;
    }

    this.id = d.id;
    this.name = d.name;
    this.kelvinRange = d.kelvin_range;
    // this.sensor = d.sensor === null ? null : observable.object(d.sensor);
    // this.sensorControlled = d.is_sensor_controlled;

    if ('lights' in d) {
      this.lights = [];
      d.lights.forEach((light) => {
        const entity = window.Store.registerEntity('light', light, this);
        this.lights.push(entity);
      });
    }

    this.capabilities.set('brightness', some(this.lights, light => light.capabilities.get('brightness')));
    this.capabilities.set('chromaticity', some(this.lights, light => light.capabilities.get('chromaticity')));
    this.capabilities.set('uvA', some(this.lights, light => light.capabilities.get('uvA')));
    this.capabilities.set('uvC', some(this.lights, light => light.capabilities.get('uvC')));
  };

  // setSensorControlled = flow(function* (value) {
  //   this.sensorControlled = value;

  //   try {
  //     const r = yield patchGroup(this.id, { is_sensor_controlled: this.sensorControlled });
  //     window.Store.registerEntity('room', r.data.data);
  //   } catch (error) {
  //     window.Store.handleError(`room.settings`);
  //     throw error;
  //   }
  // }.bind(this));

  toggleExpanded = () => {
    this.expanded = !this.expanded;
  };

  @computed get on() {
    return every(this.lights, light => light.on);
  }

  @computed get motionEnabled() {
    return every(this.lights, light => light.motionEnabled);
  }

  @computed get nightLightEnabled() {
    return every(this.lights, light => light.nightLightEnabled);
  }

  @computed get activeAutoModeName() {
    return this.parent.activeAutoModeName;
  }
}
