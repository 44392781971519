import React from 'react';
import { observer, inject } from 'mobx-react';
import classnames from 'classnames';
import dayjs from 'dayjs';
import {
  IconButton,
  Typography,
  List,
  ListItem,
  Collapse,
  Tooltip,
} from '@material-ui/core';
import TriStateSwicth from './TriStateSwitch';


import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
// import ExposureIcon from '@material-ui/icons/Exposure';
import GraphicEqIcon from '@material-ui/icons/GraphicEq';

import styles from './Room.module.scss';

import LightIcon from './control/LightIcon';
import {
  MODE_SCENE,
  MODE_MANUAL,
  MODE_INHERIT,
  MODE_AUTO,
} from '../../shared/constants';

function Running({ mode, scene, override = null, uvC = null, uvA = null }) {
  return (
    <div className={styles.running}>
      {(mode === MODE_MANUAL && (uvC || uvA)) && (
        <Tooltip title={`${uvC ? 'UV-C' : ''}${uvC && uvA ? '/' : ''}${uvA ? 'UV-A' : ''} running`}>
          <GraphicEqIcon className={styles.uv} />
        </Tooltip>
      )}
      {mode === MODE_MANUAL && <Typography variant="body1" className={styles.label}>Manual</Typography>}
      {mode === MODE_SCENE && (
        <Typography variant="h3" className={styles.label}>{scene ? scene.name : ''}</Typography>
      )}
      {mode === MODE_INHERIT && <Typography variant="body1" className={styles.label}>Inherit</Typography>}
      {mode === MODE_AUTO && <Typography variant="body1" className={styles.label}>Auto</Typography>}
      {override && override.isValid() && override.isAfter(dayjs()) && (
        <Typography variant="body1" className={styles.labelSub}>&nbsp;({override.diff(dayjs(), 'minute') + 1} min.)</Typography>
      )}
    </div>
  );
}

function ExpandIcon({expanded, onClick, dataTestId}) {
  return (
    <IconButton
      size="medium"
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      data-test-id={dataTestId}
    >
      {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </IconButton>
  );
}

@inject('store')
@observer
class Room extends React.Component {
  onItemClick = (level, store, anchor) => {
    this.props.onItemClick(level, store, anchor, this.props.s);
  };
  render() {
    const s = this.props.s;

    return (
      <React.Fragment>
        <div
          className={styles.room}
          data-test-id={`roomDiv${this.props.index}`}
        >
          <ListItem
            className={styles.item}
            button
            anchorid={`room${s.id}`}
            onClick={(e) => this.onItemClick('room', s, e.currentTarget)}
          >
            <div className={styles.level}>
              <div className={styles.left}>
                <ExpandIcon  dataTestId={`expandRoom${this.props.index}`} expanded={s.expanded} onClick={s.toggleExpanded} />
                <Typography variant="h3" className={styles.label}>{s.name}</Typography>
              </div>
              <div className={styles.right}>
                <Running
                  mode={s.mode}
                  scene={s.activeScene}
                  override={s.overriddenUntil}
                  uvC={s.uvC}
                  uvA={s.uvA}
                />
                <TriStateSwicth
                  lights={s.lights}
                  checked={s.on}
                  onChange={(isChecked) => s.setOnOff(isChecked, 'room')}
                />
              </div>
            </div>
          </ListItem>

          {s.groups.map((group, index) => (
            <Collapse key={group.id} in={s.expanded} timeout="auto" unmountOnExit>
              <ListItem
                className={styles.item}
                button
                onClick={(e) => this.onItemClick('group', group, e.currentTarget)}
              >
                <div className={classnames(styles.level, styles.level_2)}>
                  <div className={styles.left}>
                    <ExpandIcon dataTestId={`expandGroup${index}`} expanded={group.expanded} onClick={group.toggleExpanded} />
                    <Typography variant="h3" className={styles.label}>{group.name}</Typography>
                    {/* {group.sensor !== null && (
                      <div className="ml-1">
                        <Tooltip title={group.sensorControlled ? 'Control by sensor': 'Sensor control available'}>
                          <ExposureIcon color={group.sensorControlled ? 'secondary': 'disabled'} />
                        </Tooltip>
                      </div>
                    )} */}
                  </div>
                  <div className={styles.right}>
                    <Running mode={group.mode} scene={group.activeScene} override={group.overriddenUntil} />

                    <TriStateSwicth
                      lights={group.lights}
                      checked={group.on}
                      onChange={(isChecked) => group.setOnOff(isChecked, 'group')}
                    />
                  </div>
                </div>
              </ListItem>
              <Collapse in={group.expanded} timeout="auto" unmountOnExit>
                <List>
                  {group.lights.map((light) => (
                    <ListItem
                      key={light.id}
                      className={styles.item}
                      button
                      onClick={(e) => this.onItemClick('light', light, e.currentTarget)}
                    >
                      <div className={classnames(styles.level, styles.level_3)}>
                        <div className={styles.left}>
                          <span className={styles.lightIcon}>
                            <LightIcon
                              alive={light.alive}
                              actualChromaticity={light.computedChromaticity}
                              actualBrightness={light.computedBrightness}
                              kelvinRange={light.kelvinRange}
                            />
                          </span>
                          <Typography variant="h3" className={styles.label}>{light.name}</Typography>
                        </div>
                        <div className={styles.right}>
                          <Running mode={light.mode} scene={light.activeScene} override={light.overriddenUntil} />
                          <TriStateSwicth
                            lights={[light]}
                            checked={light.on}
                            onChange={(isChecked) => light.setOnOff(isChecked, 'light')}
                          />
                        </div>
                      </div>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </Collapse>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

export default Room;
