import { observable, flow, computed } from 'mobx';
import Validator from 'validatorjs';

import remove from 'lodash/remove';

import { fetchNetwork, postUser, removeUser } from '../endpoints';

class User {
  @observable email = '';
  @observable id = 0;

  constructor(d) {
    this.email = d.email;
    this.id = d.id;
  }
}

export class UserAccess {
  @observable users = [];
  @observable loaded = false;
  @observable loadingError = false;
  // form for inviting new user
  @observable form = observable.map({
    email: '',
  });
  @observable submitted = false;

  init = flow(function* () {
    this.loaded = false;
    this.users = [];
    this.loadingError = false;
    
    try {
      const id = window.Store.currentNetwork ? window.Store.currentNetwork.id : undefined;
      const network = yield fetchNetwork(id);
      const users = id ? network.data.data.users : network.data.data[0].users;

      users.forEach((user) => {
        this.users.push(new User(user));
      });
      this.loaded = true;
    } catch (error) {
      window.Store.handleError('access.init');
      this.loadingError = true;
      throw error;
    }
  }.bind(this));

  removeUser = flow(function* (id) {
    try {
      yield removeUser(id);
      remove(this.users, n => n.id === id);
    } catch (error) {
      window.Store.handleError('access.remove');
      throw error;
    }
  });

  addUser = flow(function* () {
    this.submitted = true;

    if (this.valid.passes()) {
      try {
        const user = yield postUser(this.form.get('email'));
        this.users.push(new User(user.data.data));
        this.form.set('email', '');
        this.submitted = false;
      } catch (error) {
        window.Store.handleError('access.invite');
        throw error;
      }
    }
  });

  @computed get valid() {
    const validator = new Validator({ email: this.form.get('email') }, { email: 'required|email' });
    if (this.submitted) {
      validator.passes();
    }
    return validator;
  }
}
