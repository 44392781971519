import React from 'react';
import { observer, inject } from 'mobx-react';
import { Router, Route, Switch } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import classNames from 'classnames';

import {
  MuiThemeProvider,
  CssBaseline,
} from '@material-ui/core';
import queryString from 'query-string';

import { darkTheme } from './theme';

import Error from './components/Error';

import SignIn from './pages/sign/SignIn';
import SignUp from './pages/sign/SignUp';
import TermsOfUse from './pages/sign/Terms';
import LostPassword from './pages/sign/LostPassword';
import ControlCenter from './pages/controlCenter/ControlCenter';
import UsageStats from './pages/usageStats/UsageStats';
import UserAccess from './pages/userAccess/UserAccess';
import Profile from './pages/profile/Profile';
import NetworkSettings from './pages/settings/NetworkSettings';
import AutoModeSettings from './pages/settings/AutoModeSettings';
import Hierarchy from './pages/settings/Hierarchy';
import LeftMenu from './pages/LeftMenu';
import TopMenu from './pages/TopMenu';

import Navigator from './shared/helpers/navigation';

import TranslationsEN from './shared/translations/en.json';
import TranslationsCS from './shared/translations/cs.json';

import {
  getItemSession,
} from './shared/helpers/storage';

import * as Sentry from '@sentry/react';
// Create Custom Sentry Route component
const SentryRoute = Sentry.withSentryRouting(Route);

Sentry.init({
  dsn: "https://a64c38989f68afcbff4db161a4c579b9@o4506100104822784.ingest.sentry.io/4506100124811264",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(Navigator._navigator),
    }),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const translations = {
  en: TranslationsEN,
  cs: TranslationsCS,
};

@inject('store')
@observer
class Main extends React.Component {
  state = {
    isWebView: false,
  };

  async componentDidMount() {
    const query = queryString.parse(window.location.search);
    if ('token' in query) {
      await this.props.store.setAccessToken(query.token);
    }

    if (window.ReactNativeWebView) {
      this.setState({ isWebView: true });
    }

    Navigator.listen(async (location) => {
      if (location.pathname !== '/' && !await getItemSession('access_token')) {
        await this.props.store.removeAccessToken();
      }

      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage('routeChange');
      }
    });
  }

  render() {
    return (
      <IntlProvider locale={this.props.store.locale} messages={translations[this.props.store.locale]}>
        <Router history={Navigator._navigator}>
          <MuiThemeProvider theme={darkTheme}>
            <CssBaseline />

            <div className={classNames('wrapper', { 'webview': this.state.isWebView })}>
              <Switch>
                <SentryRoute path="/" component={SignIn} exact />
                <SentryRoute path="/sign-up" component={SignUp} exact />
                <SentryRoute path="/terms-of-use" component={TermsOfUse} exact />
                <SentryRoute path="/lost-password" component={LostPassword} exact />
                <SentryRoute
                  path="/in"
                  render={() => (
                    <React.Fragment>
                      <LeftMenu />
                      <SentryRoute path="/" render={(props) => <TopMenu {...props} />} />
                      <main className="content">
                        <Switch>
                          <SentryRoute path="/in" exact component={ControlCenter} />
                          <SentryRoute path="/in/usage" component={UsageStats} />
                          <SentryRoute path="/in/access" component={UserAccess} exact />
                          <SentryRoute path="/in/profile" component={Profile} />
                          <SentryRoute path="/in/hierarchy" component={Hierarchy} />
                          <SentryRoute path="/in/settings/networks" component={NetworkSettings} />
                          <SentryRoute path="/in/settings/view/:viewId(\d+)/rooms/:roomId(\d+)/auto/:confId(\d+)" component={AutoModeSettings} exact />
                        </Switch>
                      </main>
                    </React.Fragment>
                  )}
                />
              </Switch>
            </div>

            {this.props.store.error &&
              <Error />
            }
          </MuiThemeProvider>
        </Router>
      </IntlProvider>
    );
  }
}

export default Main;
