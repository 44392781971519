import { observable, computed, action, flow } from 'mobx';
import uuid from 'uuid/v4';
import dayjs from 'dayjs';
import axios from 'axios';
import find from 'lodash/find';

import Navigator from './helpers/navigation';

import {
  getItemSession,
  setItemSession,
  removeItemSession,
  removeItemPersistent,
} from './helpers/storage';
import BroadcastListener from './broadcastListener';

import Notification from './stores/entities/notification';

import { fetchNetworks, fetchMe, patchNotification } from './endpoints';

import { ControlCenter } from './stores/controlCenter';
import { UserAccess } from './stores/userAccess';
import { SignIn } from './stores/sign';
import { SignUp } from './stores/sign';
import { LostPassword } from './stores/sign';
import { Gateways } from './stores/gatewaySettings';
import { NetworkSettings } from './stores/networkSettings';
import { AutoModeSettings } from './stores/autoModeSettings';
import { HierarchySettings } from './stores/DnDHierarchy';
import { Profile } from './stores/profile';

import Group from './stores/entities/group';
import Light from './stores/entities/light';
import Room from './stores/entities/room';
import View from './stores/entities/view';

const entityMapping = {
  Group,
  Light,
  Room,
  View,
};

class Network {
  @observable id = '';
  @observable name = '';
  @observable user = null;
  @observable statistics = null;
  @observable astralBounds = null;

  constructor(network) {
    this.id = network.id;
    this.name = network.name;
    this.user = network.user;
    this.statistics = network.powerStatistics;
    this.astralBounds = {
      sunriseSummer: network.sunrise_summer_soltice_at,
      sunriseWinter: network.sunrise_winter_soltice_at,
      sunsetSummer: network.sunset_summer_soltice_at,
      sunsetWinter: network.sunset_winter_soltice_at,
    };
  }
}

export default class RootStore {
  @observable locale = 'en';
  @observable pendingRequests = observable.map();
  @observable error = '';
  @observable currentNetwork = null;
  @observable user = null;
  @observable userId = null;
  @observable crumbs = [];
  @observable menuOpen = false;

  // entities
  @observable networks = [];
  @observable notifications = [];
  @observable views = {};
  @observable rooms = {};
  @observable groups = {};
  @observable lights = {};

  constructor() {
    axios.interceptors.response.use(null, (error) => {
      // eslint-disable-next-line
      console.log(error);
      if (error.response.status === 401) {
        this.removeAccessToken();
        Navigator.push('SignIn');

        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage('authError');
        }
      }
      return Promise.reject(error);
    });

    this.controlCenter = new ControlCenter();
    this.userAccess = new UserAccess();
    this.gateways = new Gateways();
    this.signIn = new SignIn();
    this.signUp = new SignUp();
    this.lostPassword = new LostPassword();
    this.networkSettings = new NetworkSettings();
    this.autoModeSettings = new AutoModeSettings();
    this.hierarchySettings = new HierarchySettings();
    this.profile = new Profile();
  }

  reset = () => {
    if (window.Broadcast) {
      window.Broadcast.echo.disconnect();
    }

    this.currentNetwork = null;
    this.notifications = [];
    this.user = null;

    this.controlCenter.reset();
  };

  init = flow(function* () {
    if (!this.currentNetwork) {
      this.networks = [];

      try {
        const res = yield fetchNetworks();
        this.networks = res.data.data.map(network => new Network(network));

        // set the first network as default
        this.currentNetwork = this.networks.length ? this.networks[0] : null;

        const me = yield fetchMe();
        this.user = me.data.data.email;
        this.userId = me.data.data.id;

        me.data.data.unreadNotifications.forEach(notification => {
          this.addNotification(notification);
        });

        if (this.currentNetwork) {
          this.gateways.init();
          window.Broadcast = new BroadcastListener(this);
          window.Broadcast.init();
        } else {
          setTimeout(() => Navigator.push('NetworkSettings'));
        }
      } catch (error) {
        window.Store.handleError('network.init');
        throw error;
      }
    }
  }.bind(this));

  registerEntity = (type, data, parent = null) => {
    const store = this[`${type}s`];

    if (data.id in store) {
      store[data.id].replace(data, parent);
    } else {
      const entityClass = entityMapping[type.charAt(0).toUpperCase() + type.slice(1)];
      store[data.id] = new entityClass(data, parent);
    }

    return store[data.id];
  };

  addNotification = (notification) => {
    this.notifications.push(
      new Notification(0, notification.data.message, () => {
        patchNotification(notification.id);
      })
    );
  };

  setAccessToken = async (token = null) => {
    if (!token) {
      token = await getItemSession('access_token');
    }

    if (token) {
      await setItemSession('access_token', token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
  };

  removeAccessToken = async () => {
    delete axios.defaults.headers.common.Authorization;
    await removeItemSession('access_token');
    await removeItemPersistent('access_token');
  };

  @action
  setNetwork = (networkObject) => {
    window.Broadcast.leaveCurrentChannel();
    this.currentNetwork = networkObject;
    window.Broadcast.subscribeToChannel(networkObject.id);
    Navigator.push('ControlCenter');
  };

  @action
  setNetworkById = (id) => {
    const network = find(this.networks, { id });
    this.setNetwork(network);
  };

  @action
  handleError = (type) => {
    this.error = type;
    setTimeout(() => this.error = '', 3500);
  };

  @action
  discardError = () => {
    this.error = '';
  };

  @action
  addPendingRequest = (url, type, cb = () => {}) => {
    const id = uuid();
    this.pendingRequests.set(id, {
      url,
      type,
      cb,
      timestamp: dayjs().format('HH:mm:ss'),
    });

    setTimeout(() => {
      if (this.pendingRequests.has(id)) {
        const request = this.pendingRequests.get(id);
        this.handleError('connection');
        // eslint-disable-next-line
        console.warn(
          `Dangling request: ${request.url} (${id})`
        );
        this.resolvePendingRequest(id);
      }
    }, 30000);

    return id;
  };

  @action
  resolvePendingRequest = (id, data = null) => {
    // only resolve request from this client
    if (this.pendingRequests.has(id)) {
      if (data) {
        this.pendingRequests.get(id).cb(data);
      }
      this.pendingRequests.delete(id);
    }
  }

  @computed
  get loading() {
    return !!this.pendingRequests.size;
  }
}
