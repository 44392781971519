import { observable, computed, flow } from 'mobx';
import Validator from 'validatorjs';
import en from 'validatorjs/src/lang/en';
import queryString from 'query-string';

import Navigator from '../helpers/navigation';
import { signIn, signUp, initLostPassword, finishLostPassword } from '../endpoints';

Validator.setMessages('en', en);

export class SignIn {
  @observable form = observable.map({
    email: '',
    password: '',
  });
  @observable submitted = false;

  init = () => {
    this.form.set('email', '');
    this.form.set('password', '');
  };

  signIn = flow(function*() {
    this.submitted = true;

    if (this.valid.passes()) {
      try {
        yield signIn(
          this.form.get('email'),
          this.form.get('password'),
        );
        Navigator.push('ControlCenter');
      } catch (error) {
        window.Store.handleError('signIn');
      }
    }
  }.bind(this));

  @computed get valid() {
    const rules = {
      email: 'required|email',
      password: 'required'
    };

    const data = {
      email: this.form.get('email'),
      password: this.form.get('password')
    };

    const validator = new Validator(data, rules);

    if (this.submitted) {
      // do the validation on first submit
      validator.passes();
    }
    return validator;
  }
}

export class SignUp {
  @observable form = observable.map({
    email: '',
    password: '',
    password_confirmation: '',
    terms: false
  });
  @observable submitted = false;

  signUp = flow(function*() {
    this.submitted = true;
    if (this.valid.passes()) {

      try {
        yield signUp({
          email: this.form.get('email'),
          password: this.form.get('password'),
          password_confirmation: this.form.get('password_confirmation'),
          agreement: this.form.get('terms')
        });
        yield signIn(
          this.form.get('email'),
          this.form.get('password'),
          false,
        );
        Navigator.push('ControlCenter');
      } catch (error) {
        window.Store.handleError('signUp');
      }
    }
  }.bind(this));

  @computed get valid() {
    const rules = {
      email: 'required|email',
      password: 'required|confirmed|min:8',
      terms: 'accepted'
    };

    const data = {
      email: this.form.get('email'),
      password: this.form.get('password'),
      password_confirmation: this.form.get('password_confirmation'),
      terms: this.form.get('terms')
    };

    const validator = new Validator(data, rules);

    if (this.submitted) {
      // do the validation on first submit
      validator.passes();
    }
    return validator;
  }
}

export class LostPassword {
  @observable form = observable.map({
    email: '',
    token: '',
    password: '',
    password_confirmation: '',
  });
  @observable submitted = false;

  init = () => {
    const query = queryString.parse(window.location.search);
    if ('token' in query) {
      this.form.set('token', query.token);
    }
  }

  initLostPassword = flow(function*() {
    this.submitted = true;
    if (this.validFirstStep.passes()) {
      try {
        yield initLostPassword(
          this.form.get('email'),
        );
      } catch (error) {
        // eslint-disable-next-line
        console.warn(error);
      }
    }
  }.bind(this));

  @computed get secondStep() {
    const query = queryString.parse(window.location.search);
    return 'token' in query;
  }

  finishLostPassword = flow(
    function*() {
      this.submitted = true;
      if (this.validSecondStep.passes()) {
        try {
          yield finishLostPassword({
            email: this.form.get('email'),
            token: this.form.get('token'),
            password: this.form.get('password'),
            password_confirmation: this.form.get('password_confirmation'),
          });

          window.Store.handleError('lostPassword.finished');

          yield signIn(
            this.form.get('email'),
            this.form.get('password'),
          );
          Navigator.push('ControlCenter');
        } catch (error) {
          window.Store.handleError('lostPassword.finish');
        }

      }
    }.bind(this)
  );

  @computed get validFirstStep() {
    const rules = {
      email: 'required|email',
    };

    const data = {
      email: this.form.get('email'),
    };

    const validator = new Validator(data, rules);

    if (this.submitted) {
      // do the validation on first submit
      validator.passes();
    }
    return validator;
  }

  @computed get validSecondStep() {
    const rules = {
      email: 'required|email',
      token: 'required',
      password: 'required|confirmed|min:8',
    };

    const data = {
      email: this.form.get('email'),
      token: this.form.get('token'),
      password: this.form.get('password'),
      password_confirmation: this.form.get('password_confirmation'),
    };

    const validator = new Validator(data, rules);

    if (this.submitted) {
      // do the validation on first submit
      validator.passes();
    }
    return validator;
  }
}
