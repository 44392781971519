import React from 'react';
import { injectIntl } from 'react-intl';

import { Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

import styles from './LoadingError.module.scss';

const LoadingError = ({ intl, msg }) => (
  <div className={styles.error}>
    <ErrorIcon className={styles.icon} color="secondary" fontSize="large" />
    <Typography variant="body1">
      {msg ? msg : intl.formatMessage({ id: 'error.loading' })}
    </Typography>
  </div>
);

export default injectIntl(LoadingError);
