import { observable } from 'mobx';

export default class Notify {
  @observable severity = 0;
  @observable message = '';
  @observable action = null;
  @observable actionLabel = '';
  @observable deleteAction = null;

  constructor(severity, message, deleteAction = null, action = null, actionLabel = '') {
    this.severity = severity;
    this.message = message;
    this.action = action;
    this.actionLabel = actionLabel;
    this.deleteAction = deleteAction;
  }
}
