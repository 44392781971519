import { observable, flow, computed } from 'mobx';
import Validator from 'validatorjs';
import find from 'lodash/find';
import dayjs from 'dayjs';

import remove from 'lodash/remove';

import { fetchGateways, deleteGateway, postGateway } from '../endpoints';
import Notification from './entities/notification';

class Gateway {
  @observable id = '';
  @observable serial = '';
  @observable alive = false;

  constructor(d) {
    this.id = d.id;
    this.serial = d.serial_code;
    this.alive = d.alive;
  }

  remove = flow(function* () {
    try {
      yield deleteGateway(this.id);
      window.Store.gateways.removeListener(this.id);
    } catch (error) {
      window.Store.handleError('gateway.remove');
      throw error;
    }
  }.bind(this));
}

export class Gateways {
  // form for adding new gateway
  @observable form = observable.map({
    serial: '',
  });
  @observable submitted = false;
  @observable gateways = [];
  @observable loaded = false;
  @observable loadingError = false;

  init = flow(function* () {
    this.gateways = [];
    this.submitted = false;
    this.loaded = false;
    this.loadingError = false;

    try {
      const gws = yield fetchGateways();
      gws.data.data.forEach((gw) => {
        this.gateways.push(new Gateway(gw));
      });
      this.loaded = true;
    } catch (error) {
      this.loadingError = true;
      throw error;
    }
  }.bind(this));

  add = flow(function* () {
    this.submitted = true;

    if (this.valid.passes()) {
      try {
        const gateway = yield postGateway(this.form.get('serial'));
        this.gateways.push(new Gateway(gateway.data.data));
      } catch (error) {
        window.Store.handleError('gateway.add');
        throw error;
      }
    }
  });

  removeListener = (id) => {
    remove(this.gateways, gw => gw.id === id);
  };

  updateOnOff = (id, value) => {
    const gw = find(this.gateways, { id });
    if (gw) {
      if (gw.alive && !value) {
        window.Store.notifications.push(
          new Notification(1, `Gateway ${gw.serial} has gone off-line (${dayjs().format('HH:mm')})`)
        );
      }
      gw.alive = value;
    }
  };

  @computed get valid() {
    const validator = new Validator({ serial: this.form.get('serial') }, { serial: 'required' });
    if (this.submitted) {
      validator.passes();
    }
    return validator;
  }
}
