import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import ResizeDetector from 'react-resize-detector';
import { Scatter } from 'react-chartjs-2';
import classNames from 'classnames';
import { useGesture } from 'react-use-gesture';

import {Typography} from '@material-ui/core';
import AlarmBar from './AlarmBar';
import sortBy from 'lodash/sortBy';
import Point from './Point';
import styles from './Chart.module.scss';

const POINT_WIDTH = 16;

let zoomChange = 0;
let zoomOffset = 0;
let prevDistance = 0;
let prevZoom = 0;
const MAX_ZOOM = 10;
const MIN_ZOOM = 1;
const PINCH_ZOOM_STEP = 0.1;

const Chart = ({ values, label, min, max, duration, onChange, disabled, gradient = [], timeFormatter = (x) => Math.round(x), hidePoints, alarms, onAlarmsChange, pointTestID, chartTestID }) => {
  const [zoom, setZoom] = useState(1);
  const [chartWidth, setChartWidth] = useState();
  const [pixelsXRatio, setpixelsXRatio] = useState();
  const [pixelsYRatio, setpixelsYRatio] = useState();
  const chart = useRef();
  const chartWrapper = useRef();
  const chartWrapperScrollBar = useRef();
  const chartRange = max - min;

  const handleChange = (index, x, y) => {
    values[index].x = (x / pixelsXRatio);
    values[index].y = max - (y / pixelsYRatio);

    onChange(sortBy(values, 'x'), values[index].y, values[index].type);
  };

  const onClick = (e) => {
    let copy = [...values];
    const halfPointWidth = POINT_WIDTH / 2;

    let x = Math.round((e.nativeEvent.layerX - halfPointWidth) / pixelsXRatio);
    let y = Math.round(max - (e.nativeEvent.layerY - halfPointWidth)) / pixelsYRatio + min;

    if (y > max) {
      y = max;
    } else if (y < min) {
      y = min;
    }

    if (x < 0) {
      x = 0;
    } else if (x > duration) {
      x = duration;
    }

    for (let i = 0; i < copy.length; i++) {
      if (copy[i].type === 'astralStart') {
        if (x > copy[i].x && x < (copy[i + 4].x)) {
          return null;
        }
      }
    }

    copy = sortBy([...copy, {x, y, type: 'classic'}], 'x');

    onChange(copy);
  };

  const onResize = (width) => {
    setChartWidth(width * zoom);
    setpixelsXRatio((width * zoom - POINT_WIDTH) / duration);
    setpixelsYRatio(max / chartRange);
  };

  const deleteAlarm = (index) => {
    const copy = [...alarms];
    copy.splice(index, 1);
    onAlarmsChange(copy);
  };

  const onDelete = (index) => {
    if (index !== 0 && index !== values.length - 1) {
      const copy = [...values];
      copy.splice(index, 1);

      onChange(sortBy(copy, 'x'));
    }
  };

  const handleScroll = () => {
    zoomOffset = chartWrapperScrollBar.current.scrollLeft;
  };

  const handleWheel = (e) => {
    if (e.nativeEvent.deltaY > 0) {
      if (zoom > MIN_ZOOM) {
        setZoom(zoom - 1);
        zoomOffset = zoomOffset - zoomChange;
      }
    } else if (e.nativeEvent.deltaY < 0) {
      if (zoom < MAX_ZOOM) {
        setZoom(zoom + 1);
        zoomOffset = Math.floor(e.nativeEvent.layerX);
        zoomChange = zoomOffset / zoom;
      }
    }
  };

  useGesture(
    {
      onDrag: () => {
        zoomOffset = chartWrapperScrollBar.current.scrollLeft;
      },
      onPinch: ({ offset: [dx, dy], origin: [x, y], dragging }) => {
        dragging = false;
        const chartLeftOffset = chartWrapperScrollBar.current.getBoundingClientRect().left;
        const pinchCenter = x - chartLeftOffset;

        if ((zoom - PINCH_ZOOM_STEP) >= MIN_ZOOM) {
          if (prevDistance > dx) {
            setZoom(zoom - PINCH_ZOOM_STEP);
            zoomOffset = (pinchCenter * zoom) - (chartWrapperScrollBar.current.getBoundingClientRect().width / 2);
            zoomChange = zoomOffset - prevZoom;
          }
        }

        if ((zoom + PINCH_ZOOM_STEP) <= MAX_ZOOM) {
          if (prevDistance < dx) {
            setZoom(zoom + PINCH_ZOOM_STEP);
            zoomOffset = (pinchCenter * zoom) - (chartWrapperScrollBar.current.getBoundingClientRect().width / 2);
            zoomChange = zoomOffset - prevZoom;
           }
        }

        prevZoom = zoomOffset;
        prevDistance = dx;
      },
    },
    {
      domTarget: chartWrapperScrollBar,
      eventOptions: { passive: false },
    },
  );

  const chartData = (canvas) => {
    const ctx = canvas.getContext('2d');
    const linearGradient = ctx.createLinearGradient(0, 0, 0, 150);

    gradient.forEach(color => {
      linearGradient.addColorStop(color.step, color.background);
    });

    return {
      datasets: [
        {
          backgroundColor: gradient.length ? linearGradient : 'rgba(255, 255, 255, 0.1)',
          borderColor: '#fff',
          data: values.filter(value =>
            value.type === 'X2BeforeSunrise' ||
            value.type === 'XBeforeSunrise' ||
            value.type === 'sunrise' ||
            value.type === 'GoldenHourSunrise' ||
            value.type === 'GoldenHourSunset' ||
            value.type === 'sunset' ||
            value.type === 'XAfterSunset' ||
            value.type === 'X2AfterSunset' ||
            value.type === 'classic' ||
            value.type === undefined
          ),
          showLine: true,
          pointRadius: 0,
          lineTension: 0.15,
        },
      ],
    };
  };

  const options = {
    animation: {
      duration: 0,
    },
    layout: {
      padding: {
        left: -8,
        bottom: -9,
        right: 6,
      },
    },
    scales: {
      yAxes: [{
        ticks: {
          min: min,
          max: max,
          beginAtZero: true,
          display: false,
        },
      }],
      xAxes: [{
        ticks: {
          min: min - min,
          max: duration,
          display: false,
        },
        gridLines: {
          display: false,
        }
      }],
    },
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
  };

  const handleGesturestart = (e) => {
    e.preventDefault();
  };

  const handleGesturechange = (e) => {
    e.preventDefault();
  };

  useLayoutEffect(() => {
    onResize(chartWrapper.current.offsetWidth);
    chartWrapperScrollBar.current.scrollLeft = zoomOffset;
    chart.current.chartInstance.update();
  });

  useEffect(() => {
    onResize(chartWrapper.current.offsetWidth);
    chart.current.chartInstance.update();
    document.addEventListener('gesturestart', handleGesturestart);
    document.addEventListener('gesturechange', handleGesturechange);
    return () => {
      document.removeEventListener('gesturestart', handleGesturestart);
      document.removeEventListener('gesturechange', handleGesturechange);
    };
  }, [duration, zoom]);

  return (
    <div>
      <ResizeDetector handleWidth onResize={(width) => onResize(width)} />

      <div
        className={styles.chartWrapper}
        onWheel={(e) => !disabled && handleWheel(e)}
        onScroll={handleScroll}
        ref={chartWrapperScrollBar}
      >
        {onAlarmsChange &&
          <AlarmBar
            onAlarmsChange={onAlarmsChange}
            alarms={alarms}
            onDelete={(index) => deleteAlarm(index)}
            duration={duration}
            pixelsXRatio={pixelsXRatio}
            chartWidth={chartWidth}
            handleChange={(x, index) => {
              const copy = [...alarms];
              copy[index].xOffset = x / pixelsXRatio;
              onAlarmsChange(copy);
            }}
            zoom={zoom}
            timeFormatter={timeFormatter}
          />
        }

        <div className={classNames(styles.chart, {[ styles.disabled ]: disabled})} ref={chartWrapper}>
          <div style={{ width: chartWidth }} onClick={(e) => !disabled && onClick(e)} data-test-id={chartTestID}>
            <Scatter data={chartData} options={options} ref={chart} />
            <div className={styles.YAxisSideLine}>
                <Typography
                  variant="caption"
                  className={styles.YAxisLabelTop}
                >
                  {max - (0 / pixelsYRatio)}%
                </Typography>
                <Typography
                  variant="caption"
                  className={styles.YAxisLabel}
                >
                  {max - (50 / pixelsYRatio)}%
                </Typography>
                <Typography
                  variant="caption"
                  className={styles.YAxisLabelBottom}
                >
                  {max - (100 / pixelsYRatio)}%
                </Typography>
              </div>
          </div>

          {values.map(({x, y, type}, index) => {
            // getting bounds for draggable elements so they do not go past each other.
            const bounds = {leftBound: 0, rightBound: 0};
            let astralRectangleWidth = 0;

            if (index < values.length - 1) {
              const rightX = Math.round(values[index + 1].x * pixelsXRatio);
              bounds.rightBound = rightX;
            }

            if (index > 0) {
              const leftX = Math.round(values[index - 1].x * pixelsXRatio);
              bounds.leftBound = leftX;
            }

            if (type === 'astralStart') {
              astralRectangleWidth = (values[index + 5].x - values[index].x) * pixelsXRatio;
            }

            return (
              <Point
                timeFormatter={timeFormatter}
                key={index}
                x={(x) * pixelsXRatio}
                y={((-y + max) * pixelsYRatio)}
                pixelsYRatio={pixelsYRatio}
                astralRectangleWidth={astralRectangleWidth}
                bounds={bounds}
                onChange={({ x, y }) => handleChange(index, x, y)}
                onDelete={() => onDelete(index)}
                axis={index === 0 ||
                      index === values.length - 1 ||
                      (
                        type === 'X2BeforeSunrise' ||
                        type === 'XBeforeSunrise' ||
                        type === 'sunrise' ||
                        type === 'GoldenHourSunrise' ||
                        type === 'GoldenHourSunset' ||
                        type === 'sunset' ||
                        type === 'XAfterSunset' ||
                        type === 'X2AfterSunset'
                      ) ? 'y' : null}
                line={index !== 0 && index !== values.length - 1}
                max={max}
                value={x}
                type={type}
                deletable={index !== 0 && index !== values.length - 1}
                disabled={disabled}
                duration={duration}
                pixelsXRatio={pixelsXRatio}
                hidePoints={hidePoints}
                pointTestID={pointTestID}
                index={index}
              />
            );
          })}

          {label && (
            <div style={{ left: `${label.valueX * pixelsXRatio}px` }}>
              <div className={styles.pointLine} style={{ height: '100px' }} />
              <div className={styles.pointLabel}>
                <Typography variant="caption" className={styles.labelTextNow}>{label.text}</Typography>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Chart;
