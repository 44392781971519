import React from 'react';
import { observer, inject } from 'mobx-react';
import dayjs from 'dayjs';
import {
  Typography,
  FormGroup,
  FormControlLabel,
  Radio,
  Tooltip,
  Button,
  TextField,
  Menu,
  MenuItem,
  Switch,
  Select,
  IconButton,
} from '@material-ui/core';
import Slider from '../../../components/slider/Slider';

import Chart from '../../../components/chart/Chart';
import CustomScenes from './CustomScenes';
import AutoModes from './AutoModes';

import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import CloseIcon from '@material-ui/icons/Close';
// import ExposureIcon from '@material-ui/icons/Exposure';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import styles from './Control.module.scss';

import {
  BrightnessBounds,
  ChromaticityBounds,
  ChromaticityGradient,
  BrightnessGradient,
  DayLength,
  MODE_SCENE,
  MODE_MANUAL,
  MODE_AUTO,
} from '../../../shared/constants';

const ModeLabels = {
  [MODE_SCENE]: 'Scene',
  [MODE_MANUAL]: 'Manual mode',
};

@inject('store')
@observer
class Control extends React.Component {
  state = {
    overrideAnchor: null,
  };

  closeOverrideMenu = () => {
    this.setState({ overrideAnchor: null });
  };

  onChangeMode = e => {
    this.props.s.setMode(e.target.value, this.props.level);
  };

  onChangeOverride = (interval) => {
    this.props.s.setOverride(interval, this.props.level);
  };

  render() {
    const { level, s } = this.props;

    return (
      <div className={styles.controlWrapper}>
        <div className={styles.control}>
          <div className={styles.header}>
            <div>
              <Typography variant="h3" className={styles.heading}>{s.name}</Typography>
              {level === 'light' && (
                <Typography variant="caption" className={styles.kelvin}>
                  {s.kelvinRange === 0 ? '2700K - 6500K' : '1800K - 6500K'}
                </Typography>
              )}
            </div>
            <IconButton onClick={() => this.props.onClose()}>
              <CloseIcon />
            </IconButton>
            {s.motion && (
              <Tooltip title="Motion detected">
                <DirectionsRunIcon className={styles.motionIcon} />
              </Tooltip>
            )}
          </div>

          <div className={styles.mode}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Radio
                    checked={s.mode === MODE_MANUAL || s.mode === MODE_SCENE}
                    value="1"
                    onChange={this.onChangeMode}
                    data-test-id="toggleManual"
                  />
                }
                label="Manual"
              />
              <FormControlLabel
                control={
                  <Radio
                    checked={s.mode === MODE_AUTO}
                    value="3"
                    onChange={this.onChangeMode}
                    data-test-id="toggleAuto"
                  />
                }
                label="Auto"
              />
            </FormGroup>
          </div>

          {(s.capabilities.get('brightness') || s.capabilities.get('chromaticity')) && s.overriddenUntil && s.overriddenUntil.isValid() && s.overriddenUntil.isAfter(dayjs()) && (
            <div className={styles.timer}>
              <Typography variant="body1">{ModeLabels[s.mode]} active for</Typography>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={(e) => this.setState({ overrideAnchor: e.currentTarget })}
              >
                <span>{s.overriddenUntil.diff(dayjs(), 'minute') + 1} min.</span>
                <KeyboardArrowDownIcon />
              </Button>

              <Menu
                anchorEl={this.state.overrideAnchor}
                onClose={this.closeOverrideMenu}
                open={Boolean(this.state.overrideAnchor)}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              >
                <MenuItem disabled value="until">{s.overriddenUntil.diff(dayjs(), 'minute')} min.</MenuItem>
                <MenuItem onClick={() => this.onChangeOverride(2)}>2 min.</MenuItem>
                <MenuItem onClick={() => this.onChangeOverride(30)}>30 min.</MenuItem>
                <MenuItem onClick={() => this.onChangeOverride(null)}>Forever</MenuItem>
              </Menu>
            </div>
          )}

          {s.mode === MODE_AUTO && (s.capabilities.get('brightness') || s.capabilities.get('chromaticity')) && (
            <Typography variant="body1">Currently ({dayjs().format('DD. MM. YYYY')}, {s.activeAutoModeName})</Typography>
          )}

          {s.mode === MODE_AUTO && s.capabilities.get('brightness') && (
            <>
              <div className="mb-4">
                <Typography variant="caption">Brightness</Typography>
                <Chart
                  values={s.autoModeBrightness.toJS()}
                  min={BrightnessBounds[0]}
                  max={BrightnessBounds[1]}
                  duration={DayLength}
                  gradient={BrightnessGradient}
                  disabled
                  hidePoints
                  label={{ valueX: dayjs().hour() * 60 + dayjs().minute(), text: 'Now' }}
                />
              </div>
            </>
          )}

          {s.mode === MODE_AUTO && s.capabilities.get('chromaticity') && (
            <>
              <div className="mb-4">
                <Typography variant="caption">Chromaticity</Typography>
                <Chart
                  values={s.autoModeChromaticity.toJS()}
                  min={ChromaticityBounds[1]}
                  max={ChromaticityBounds[0]}
                  duration={DayLength}
                  gradient={ChromaticityGradient}
                  disabled
                  hidePoints
                  label={{ valueX: dayjs().hour() * 60 + dayjs().minute(), text: 'Now' }}
                />
              </div>
            </>
          )}

          {(s.mode === MODE_MANUAL || s.mode === MODE_SCENE) && (
            <>
              <div className={styles.capabilities}>
                {(s.capabilities.get('brightness') || s.capabilities.get('chromaticity')) && (
                  <>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={s.motionEnabled}
                          onChange={(e) => s.setMotionEnabled(e.target.checked, level)}
                          onClick={(e) => e.stopPropagation()}
                        />
                      }
                      label="Motion"
                    />

                    <FormControlLabel
                      control={
                        <Switch
                          checked={s.nightLightEnabled}
                          onChange={(e) => s.setNightLightEnabled(e.target.checked, level)}
                          onClick={(e) => e.stopPropagation()}
                        />
                      }
                      label="Night light"
                    />
                  </>
                )}
                {level === 'room' && (s.capabilities.get('uvA') || s.capabilities.get('uvC')) && (
                  <>
                    {s.capabilities.get('uvA') && (
                      <FormControlLabel
                        control={
                          <Switch
                            checked={s.uvAEnabledAt && s.uvAEnabledAt.isValid()}
                            onChange={(e) => s.setUv('a', e.target.checked)}
                            onClick={(e) => e.stopPropagation()}
                          />
                        }
                        label="UV-A"
                      />
                    )}
                    {s.capabilities.get('uvC') && (
                      <FormControlLabel
                        control={
                          <Switch
                            checked={s.uvCEnabledAt && s.uvCEnabledAt.isValid()}
                            onChange={(e) => s.setUv('c', e.target.checked)}
                            onClick={(e) => e.stopPropagation()}
                          />
                        }
                        label="UV-C"
                      />
                    )}
                  </>
                )}
              </div>
              {s.uvCEnabledAt && s.uvCEnabledAt.isValid() && (
                <div className={styles.capabilitiesSettings}>
                  <Typography variant="body1">How often (UV-C)</Typography>
                  <Select
                    value={s.uvCPeriod}
                    displayEmpty
                    onChange={(e) => s.setUv('howOften', e.target.value)}
                    fullWidth
                    className="mb-2"
                  >
                    <MenuItem value={0}>
                      Every hour
                    </MenuItem>
                    <MenuItem value={1}>
                      Every 2 hours
                    </MenuItem>
                    <MenuItem value={2}>
                      Every 4 hours
                    </MenuItem>
                  </Select>

                  <Typography variant="body1">For how long (UV-C)</Typography>
                  <Select
                    value={s.uvCTimer}
                    displayEmpty
                    onChange={(e) => s.setUv('howLong', e.target.value)}
                    fullWidth
                  >
                    <MenuItem value={0}>
                      2 minutes
                    </MenuItem>
                    <MenuItem value={1}>
                      5 minutes
                    </MenuItem>
                    <MenuItem value={2}>
                      10 minutes
                    </MenuItem>
                  </Select>
                </div>
              )}
              {s.motionEnabled && (s.capabilities.get('brightness') || s.capabilities.get('chromaticity')) && (
                <>
                  <TextField
                    label="Motion transition duration (minutes)"
                    margin="normal"
                    fullWidth
                    type="number"
                    value={s.motionTransition[s.motionTransition.length -1].x / 60}
                    onChange={(e) => s.setMotionDuration(e.target.value, level)}
                    className="mb-1"
                  />
                  <div className="mb-3">
                    <Chart
                      values={s.motionTransition.toJS()}
                      onChange={(values) => s.setMotionTransition(values, level)}
                      min={0}
                      max={100}
                      duration={s.motionTransition[s.motionTransition.length -1].x}
                      disabled={s.disabled}
                      timeFormatter={(x) => `${Math.round(x / 60)}m`}
                    />
                  </div>
                </>
              )}
            </>
          )}

          {(s.mode === MODE_MANUAL || s.mode === MODE_SCENE) && (
            <div className={styles.manual}>
              {(s.capabilities.get('brightness') || s.capabilities.get('chromaticity')) && (
                <>
                  {s.capabilities.get('brightness') && (
                    <div className={styles.slider}>
                      <Typography variant="body1">Brightness</Typography>
                      <Slider
                        value={s.brightness}
                        computedValue={level === 'light' ? s.computedBrightness : null}
                        min={BrightnessBounds[0]}
                        max={BrightnessBounds[1]}
                        disabled={s.disabled}
                        onChange={(val) => s.setValue('brightness', val, level)}
                        className={styles.brightnessSlider}
                      />
                    </div>
                  )}

                  {s.capabilities.get('chromaticity') && (
                    <div className={styles.slider}>
                      <Typography variant="body1">Chromaticity</Typography>
                      <Slider
                        value={-s.chromaticity}
                        computedValue={level === 'light' ? -s.computedChromaticity : null}
                        min={ChromaticityBounds[1]}
                        max={ChromaticityBounds[0]}
                        disabled={s.disabled}
                        onChange={(val) => s.setValue('chromaticity', -val, level)}
                        className={s.kelvinRange === 0 ? styles.chromaticitySlider : styles.greaterChromaticitySlider}
                      />
                    </div>
                  )}

                  {s.capabilities.get('brightness') && s.capabilities.get('chromaticity') && (
                    <div className={styles.slider}>
                      <div className={styles.dimBright}>
                        <Typography variant="body1">Dim to warm</Typography>
                        <Typography variant="body1">Bright to cool</Typography>
                      </div>
                      <Slider
                        value={s.dimBright}
                        disabled={s.disabled}
                        min={0}
                        max={100}
                        onChange={(val) => s.setDimBright(val, level)}
                        log
                        className={s.kelvinRange === 0 ?
                          styles.dimBrightChromaticitySlider :
                          styles.dimBrightGreaterChromaticitySlider
                        }
                      />
                    </div>
                  )}

                  <Typography variant="body1">Predefined scenes</Typography>
                  <div className={styles.shortcuts}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => s.setValueShortcut('fullLight', level)}
                    >
                      Full light
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => s.setValueShortcut('sleep', level)}
                      disabled
                    >
                      Sleep
                    </Button>
                  </div>
                  <div className={styles.shortcuts}>
                    <Button
                      variant="contained"
                      className={styles.dayLight}
                      onClick={() => s.setValueShortcut('dayLight', level)}
                    >
                      Day light
                    </Button>
                    <Button
                      variant="contained"
                      className={styles.eveningLight}
                      onClick={() => s.setValueShortcut('eveningLight', level)}
                    >
                      Evening light
                    </Button>
                  </div>

                  <CustomScenes level={level} s={s} />

                  {/* {s.sensor && (
                    <div>
                      <hr className="mt-2" />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={s.sensorControlled}
                            onChange={(e) => s.setSensorControlled(e.target.checked)}
                            onClick={(e) => e.stopPropagation()}
                          />
                        }
                        label="Control by sensor"
                      />

                      {!!s.sensorControlled && (
                        <div className={styles.sensor}>
                          <div className={styles.icon}>
                            <ExposureIcon color="secondary" />
                          </div>

                          <Typography>
                            {s.sensor.brightness} Lux, {s.sensor.chromaticity} Kelvin
                          </Typography>
                        </div>
                      )}
                    </div>
                  )} */}
                </>
              )}
            </div>
          )}
        </div>

        {s.mode === MODE_AUTO && (
          <AutoModes s={this.props.roomStore} hash={`${level}-${s.id}`} breadCrumbUrlParsed={this.props.breadCrumbUrlParsed} />
        )}
        {level !== 'room' && (
          <div className={styles.bottomMargin} />
        )}

        {level === 'light' && (
          <>
            <hr className={styles.horizontal} />
            <div className={styles.runtime}>
              <div>
                <Typography variant="body1">Power on</Typography>
                <Typography variant="body2" className={styles.sub}>
                  {s.alive && (
                    <>
                      <span>
                        {s.poweredSince.format('YYYY-MM-DD HH:mm')} - {dayjs().format('YYYY-MM-DD HH:mm')} /
                      </span>
                      <span>
                        {Math.floor(s.powerOn / 60)}:{s.powerOn % 60}
                      </span>
                    </>
                  )}
                  {!s.alive && (
                    <span>Off-line</span>
                  )}
                </Typography>
              </div>
              <div>
                <Typography variant="body1">LED temperature</Typography>
                <Typography variant="body2" className={styles.sub}>{s.ledTemp / 10}&deg;</Typography>
              </div>
              <div>
                <Typography variant="body1">Driver temperature</Typography>
                <Typography variant="body2" className={styles.sub}>{s.driverTemp / 10}&deg;</Typography>
              </div>
              <div>
                <Typography variant="body1">Power consumption</Typography>
                <Typography variant="body2" className={styles.sub}>{s.watts / 1000000}w</Typography>
              </div>
              <div>
                <Typography variant="body1">Serial code</Typography>
                <Typography variant="body2" className={styles.sub}>{s.serialCode}</Typography>
              </div>
              <div>
                <Typography variant="body1">Firmware version</Typography>
                <Typography variant="body2" className={styles.sub}>{s.firmwareVersion}</Typography>
              </div>
              <div>
                <Typography variant="body1">Reed</Typography>
                <Typography variant="body2" className={styles.sub}>
                  {`${Boolean(s.reed)}`}
                </Typography>
              </div>
              <div>
                <Typography variant="body1">Last alive time</Typography>
                <Typography variant="body2" className={styles.sub}>
                  {
                    !s.aliveAt ? "Never" : s.aliveAt
                  }
                </Typography>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default Control;
