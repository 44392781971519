import React, {useState, useRef, useEffect} from 'react';
import Draggable from 'react-draggable';
import classNames from 'classnames';

import styles from './Slider.module.scss';

const Slider = ({ computedValue = null, value, min, max, onChange, className, disabled }) => {
  const slider = useRef();
  const [width, setWidth] = useState();

  const sliderRange = max - min;
  const pixelsPerStep = width / sliderRange;

  const handleDrag = (e, position) => {
    const { x } = position;

    let draggedPosition = Math.round(x / pixelsPerStep + min);

    if (draggedPosition > max) {
      draggedPosition = max;
    }

    if (draggedPosition < min) {
      draggedPosition = min;
    }

    onChange(draggedPosition);
  };

  const handleClick = (e) => {
    // 8px is half of draggable button width
    let clickedPosition = Math.round((e.pageX - slider.current.getBoundingClientRect().left - 8) / pixelsPerStep + min);

    if (clickedPosition > max) {
      clickedPosition = max;
    }

    if (clickedPosition < min) {
      clickedPosition = min;
    }

    onChange(clickedPosition);
  };

  useEffect(() => {
    // 16px is a width of a dragable button.
    setWidth(slider.current.offsetWidth - 16);
  }, []);

  return (
    <div className={styles.sliderWrapper} ref={slider}>
      <div
        className={classNames(className, styles.slider, { [styles.disabled]: disabled })}
        onClick={(e) => !disabled && handleClick(e)}
      />

      {computedValue !== null && (
        <Draggable position={{ x: (computedValue - min) * pixelsPerStep, y: 0 }} onStart={() => false}>
          <div className={styles.computedSliderPoint} />
        </Draggable>
      )}

      <Draggable
        axis="x"
        bounds="parent"
        onDrag={(e, position) => !disabled && handleDrag(e, position)}
        position={{ x: ((value - min) * pixelsPerStep), y: 0 }}
      >
        <button className={classNames(styles.sliderPoint, { [styles.disabled]: disabled })} />
      </Draggable>
    </div>
  );
};

export default Slider;
