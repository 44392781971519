import React from 'react';
import { observer, inject } from 'mobx-react';
import {
  CircularProgress,
  Paper,
} from '@material-ui/core';

import HierarchyTree from '../../components/HierarchyTree/HierarchyTree';
import LoadingError from '../../components/LoadingError';
import styles from './hierarchy.module.scss';

@inject('store')
@observer
class Hierarchy extends React.Component {

  async componentDidMount() {
    const store = this.props.store;
    this.props.store.hierarchySettings.loaded = false;

    await store.setAccessToken();
    await store.init();

    store.hierarchySettings.init(window.Store.currentNetwork.id);

    this.props.store.crumbs = [
      { label: 'Hierarchy settings'},
    ];
  }

  render() {
    const s = this.props.store.hierarchySettings;
    return (
    <div className={styles.hierarchySettingsWrapper} id="hierarchyWrapper">
      {!s.loaded && !s.loadingError &&
          <Paper>
            <div className={styles.progress}>
              <CircularProgress />
            </div>
          </Paper>
        }

        {s.loadingError &&
          <LoadingError />
        }

        {s.loaded &&
          <HierarchyTree
            data={s}
          />
        }
    </div>

    );
  }
}

export default Hierarchy;
