import every from 'lodash/every';
import pickBy from 'lodash/pickBy';
import map from 'lodash/map';

export const NO_REPEAT = 0;
export const YEAR = 1;
export const MONTH = 2;
export const WEEK = 3;
export const DAY = 4;

export const DAY_OF_MONTH = 0;
export const DAY_OF_NTH_WEEK = 1;

export const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

function allWeekDays(days) {
  return days.monday && days.tuesday && days.wednesday && days.thursday && days.friday && !days.saturday && !days.sunday;
}

function allWeekEnds(days) {
  return !days.monday && !days.tuesday && !days.wednesday && !days.thursday && !days.friday && days.saturday && days.sunday;
}

export function prettify(config) {
  if (config.repeatType === DAY) {
    if (config.repeat.every === 1) {
      return 'Daily';
    } else {
      return `Every ${config.repeat.every} days`;
    }
  }

  if (config.repeatType === WEEK) {
    let result = '';

    if (config.repeat.every === 1) {
      result += 'Weekly on ';
    } else {
      result += `Every ${config.repeat.every} weeks on `;
    }

    if (every(config.repeat.days, Boolean)) {
      return `${result} all days`;
    }

    if (allWeekEnds(config.repeat.days)) {
      return `${result} weekends`;
    }

    if (allWeekDays(config.repeat.days)) {
      return `${result} weekdays`;
    }

    const selectedDays =
      map(pickBy(config.repeat.days, Boolean), (value, key) => `${key.charAt(0).toUpperCase()}${key.slice(1)}`);
    return `${result} ${selectedDays.join(', ')}`;
  }

  if (config.repeatType === YEAR) {
    let result = '';

    if (config.repeat.every === 1) {
      result += 'Annually on ';
    } else {
      result += `Every ${config.repeat.every} years on `;
    }

    return `${result}${config.scheduledAt.format('D. M.')}`;
  }

  if (config.repeatType === MONTH) {
    let result = '';

    if (config.repeat.every === 1) {
      result += 'Monthly on ';
    } else {
      result += `Every ${config.repeat.every} months on `;
    }

    if (config.repeat.type === DAY_OF_MONTH) {
      result += `day ${config.scheduledAt.date()}`;
    } else {
      const day = config.scheduledAt.day() === 0 ? 6 : config.scheduledAt.day() - 1;
      const week = Math.ceil(config.scheduledAt.date() / 7);

      result += `the ${week}${week === 1 ? 'st' : week === 2 ? 'nd' : week === 3 ? 'rd' : 'th'} ${days[day]}`;
    }

    return result;
  }

  return `Doesn't repeat (once on ${config.scheduledAt.format('D. M. YYYY')})`;
}
