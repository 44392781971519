import { createBrowserHistory } from "history";
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';

let _navigator = createBrowserHistory();

const routes = {
  'SignIn': '/',
  'SignUp': '/sign-up',
  'ControlCenter': '/in',
  'Profile': '/in/profile',
  'Settings': '/in/hierarchy',
  'NetworkSettings': '/in/settings/networks',
  'SceneSettings': '/in/settings/scenes',
  'Timeline': '/in/settings/timeline',
  'UserAccess': '/in/access',
  'LostPassword': '/lost-password',
  'TermsOfUse': '/terms-of-use'
};

function push(routeName, params) {
  _navigator.push(resolvePath(routeName, params = {}));
}

function resolvePath(routeName, params = {}) {
  if (!(routeName in routes)) {
    // eslint-disable-next-line
    console.warn(`Unknown path ${routeName}`);
  }

  let path = routes[routeName];
  if (!isEmpty(params)) {
    path += map(params, (param, key) => `/${key}/${param}`).join();
  }

  return path;
}

function listen(fun) {
  _navigator.listen(fun);
}

export default {
  push,
  resolvePath,
  listen,
  _navigator,
};
