import React from "react";
import { injectIntl } from "react-intl";
import { observer, inject } from "mobx-react";

import {
  Typography,
  Button,
  Grow,
  Paper,
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";
import DialogTitle from '../../components/DialogTitle';

import styles from "./Profile.module.scss";

function humanize(key) {
  return key.replace(/_/g, " ").replace(/(?: |\b)(\w)/g, function(key) {
    return key.toUpperCase();
  });
}

@inject("store")
@observer
class Profile extends React.Component {
  state = {
    openEmail: false,
    openPassword: false,
    openProfile: false
  };

  close = which => {
    this.setState({ [`open${which}`]: false });
  };

  async componentDidMount() {
    await window.Store.setAccessToken();
    await window.Store.init();

    if (window.Store.currentNetwork) {
      this.props.store.profile.fetchProfile();
    }

    this.props.store.crumbs = [
      { label: 'Control Center', value: '/in' },
      { label: 'Profile Settings'}
    ];
  }

  handleChange = (event) => {
    const targetOption = this.props.store.profile.profile_options.filter(option => option.name === event.target.name)[0];
    targetOption.selected = event.target.value;
  }

  changeAge = (event) => {
    this.props.store.profile.age = event.target.value;
  }

  changePassword = (event) => {
    this.props.store.profile.password.form.set(event.target.name, event.target.value);
  }

  changeEmail = (event) => {
    this.props.store.profile.email.form.set(event.target.name, event.target.value);
  }

  render() {
    const s = this.props.store.profile;

    return (
      <React.Fragment>
        <div className={styles.wrapper}>
          <Grow in={true} timeout={700}>
            <Paper elevation={2} className={styles.paper}>
              <div>
                <Typography variant="body1">E-mail</Typography>
                <Typography variant="body1">{window.localStorage.getItem('email') || window.sessionStorage.getItem('email')}</Typography>
              </div>
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                onClick={() => this.setState({ openEmail: true })}
              >
                <EditIcon className="mr-1" />
                Change
              </Button>
            </Paper>
          </Grow>
          <Grow in={true} timeout={1050}>
            <Paper elevation={2} className={styles.paper}>
              <div>
                <Typography variant="body1">Password</Typography>
                <Typography variant="body2">********</Typography>
              </div>
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                onClick={() => this.setState({ openPassword: true })}
              >
                <EditIcon className="mr-1" />
                Change
              </Button>
            </Paper>
          </Grow>
          <Grow in={true} timeout={1400}>
            <Paper elevation={2} className={styles.paper}>
              <Typography variant="body1">Personal profile</Typography>
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                onClick={() => this.setState({ openProfile: true })}
              >
                <EditIcon className="mr-1" />
                Change
              </Button>
            </Paper>
          </Grow>
        </div>

        <Dialog open={this.state.openEmail} onClose={() => this.close("Email")}>
          <DialogTitle
            title='Change E-mail'
            onClose={() => this.close("Email")}
          />
          <DialogContent>
            <form className={styles.form}>
              <TextField
                label="New E-mail"
                fullWidth
                margin="normal"
                variant="outlined"
                name="email"
                error={!!s.email.valid.errors.first('email')}
                helperText={s.email.valid.errors.first('email')}
                onChange={this.changeEmail}
                value={s.email.form.get('email')}
              />
              <TextField
                label="Current password"
                fullWidth
                margin="normal"
                variant="outlined"
                type="password"
                name="password"
                error={!!s.email.valid.errors.first('password')}
                helperText={s.email.valid.errors.first('password')}
                onChange={this.changeEmail}
                value={s.email.form.get('password')}
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.close("Email")} color="primary">
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                s.email.submit();
                if (s.email.valid.passes()) {
                  this.close("Email");
                }
                }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.openPassword}
          onClose={() => this.close("Password")}
        >
          <DialogTitle
            title='Change Password'
            onClose={() => this.close("Password")}
          />
          <DialogContent>
            <form className={styles.form}>
              <TextField
                label="New password"
                fullWidth
                margin="normal"
                variant="outlined"
                type="password"
                name="password"
                error={!!s.password.valid.errors.first('password')}
                helperText={s.password.valid.errors.first('password')}
                onChange={this.changePassword}
                value={s.password.form.get('password')}
              />
              <TextField
                label="New password confirmation"
                fullWidth
                margin="normal"
                variant="outlined"
                type="password"
                name="password_confirmation"
                error={!!s.password.valid.errors.first('password_confirmation')}
                helperText={s.password.valid.errors.first('password_confirmation')}
                onChange={this.changePassword}
                value={s.password.form.get('password_confirmation')}
              />
              <TextField
                label="Current password"
                fullWidth
                margin="normal"
                variant="outlined"
                type="password"
                name="old_password"
                error={!!s.password.valid.errors.first('old_password')}
                helperText={s.password.valid.errors.first('old_password')}
                onChange={this.changePassword}
                value={s.password.form.get('old_password')}
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.close("Password")} color="primary">
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                s.password.submit();
                if (s.password.valid.passes()) {
                  this.close("Password");
                }
                }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.openProfile}
          onClose={() => this.close("Profile")}
        >
          <DialogTitle
            title='Change Profile'
            onClose={() => this.close("Profile")}
          />
            <DialogContent>
            <form className={styles.form} >
              <TextField
                label="Age"
                type="number"
                fullWidth
                margin="normal"
                variant="outlined"
                error={!!s.validAge.errors.first('age')}
                helperText={s.validAge.errors.first('age')}
                onChange={this.changeAge}
                value={s.age}
              />
              {s.profile_options.map(option => (
                <React.Fragment key={option.name}>
                  <Typography variant="body2" className="mt-1">
                    {humanize(option.name)}
                  </Typography>
                  <RadioGroup row name={option.name} onChange={this.handleChange}>
                    {option.boxes.map(box => (
                      <FormControlLabel
                        value={box}
                        key={box}
                        control={<Radio checked={option.selected === box} />}
                        label={humanize(box)}
                      />
                    ))}
                  </RadioGroup>
                </React.Fragment>
              ))}
              </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.close("Profile")} color="primary">
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                s.submitOptions();

                if (s.age === '' || s.validAge.passes()) {
                  this.close("Profile");
                }
              }}
              >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default injectIntl(Profile);
