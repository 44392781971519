import React from 'react';
import { observer, inject } from 'mobx-react';

import { Link } from 'react-router-dom';

import { Typography, Breadcrumbs as Bcrumbs } from '@material-ui/core';

import styles from './Breadcrumbs.module.scss';

@inject('store')
@observer
class Breadcrumbs extends React.Component {
  render() {
    return (
      <section className={styles.breadCrumb}>
        <Bcrumbs separator="›" aria-label="breadcrumb">
          {this.props.store.crumbs && this.props.store.crumbs.map((crumb, index) => {
            if (index < this.props.store.crumbs.length - 1) {
              return (
                <Link key={index} color="inherit" to={crumb.value} className={styles.link}>
                  <Typography variant="h1" color="inherit" className="hidden-mobile">
                    {crumb.label}
                  </Typography>
                </Link>
              );
            } else {
              return (
                <Typography key={index} variant="h1" color="inherit" className="hidden-mobile">
                  {crumb.label}
                </Typography>
              );
            }
          })}
        </Bcrumbs>
      </section>
    );
  }
}

export default Breadcrumbs;
