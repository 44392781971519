import { observable, action, computed, flow } from 'mobx';
import forEach from 'lodash/forEach';

import { patchLightPosition } from '../../endpoints';

export default class View {
  @observable id = 0;
  @observable name = '';
  @observable rooms = [];
  @observable lightPositions = {};
  @observable links = [];

  constructor(d) {
    this.replace(d);
  }

  @action
  replace = (d) => {
    this.id = d.id;
    this.name = d.name;
    this.links = [...d.links];

    if ('rooms' in d) {
      this.rooms = [];
      d.rooms.forEach((room) => {
        const entity = window.Store.registerEntity('room', room, this);
        this.rooms.push(entity);
      });
    }

    if (d.light_positions) {
      forEach(d.light_positions, (pos, id) => {
        this.lightPositions[id] = { x: pos.x, y: pos.y };
      });
    }
  };

  getLightPosition = (id) => {
    const pos = this.lightPositions[id];
    if (pos) {
      return { x: pos.x, y: pos.y };
    }
    return { x: 0, y: 0 };
  };

  patchLightPosition = flow(function* (id, x, y, sendRequest) {
    try {
      if (this.lightPositions && this.lightPositions[id] && !sendRequest) {
        const positions = this.lightPositions;
        positions[id].x = Math.round(x);
        positions[id].y = Math.round(y);
        this.lightPositions = positions;
      } else if (sendRequest) {
        const response = yield patchLightPosition(this.id, id, Math.round(x), Math.round(y));
        this.lightPositions = response.data.data.light_positions;
      }
    } catch (error) {
      window.Store.handleError('view.position');
      throw error;
    }
  }.bind(this));

  @computed get lights() {
    let result = [];

    this.rooms.forEach((room) => {
      result = [...result, ...room.lights];
    });

    return result;
  }
}
