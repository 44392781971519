import React from 'react';
import { observer } from 'mobx-react';

import { Paper } from '@material-ui/core';

import styles from './Hierarchy.module.scss';

@observer
class Light extends React.Component {
  render() {
    const elements = React.Children.toArray(this.props.children(this.props.light));
    return (
      <div className={styles.level}>
        {!this.props.isLast && <div className={styles.hierarchyLine} />}

        <div className={styles.hierarchyCurve}>
          <svg>
            <path fill='none' stroke='#9D9D9B' strokeWidth='3px' d='M 25 0 L 25 30 Q 25 40 35 40 L 50 40' />
          </svg>
        </div>
        <Paper className={styles.paper} data-test-id="lightPaper">
          {elements.map((child) => React.cloneElement(child))}
        </Paper>
      </div>
    );
  }
}

export default Light;
