export const getItemSession = (id) => {
  return Promise.resolve(window.sessionStorage.getItem(id));
};

export const getItemPersistent = (id) => {
  return Promise.resolve(window.localStorage.getItem(id));
};

export const setItemSession = (id, value) => {
  return Promise.resolve(window.sessionStorage.setItem(id, value));
};

export const setItemPersistent = (id, value) => {
  return Promise.resolve(window.localStorage.setItem(id, value));
};

export const removeItemSession = (id) => {
  return Promise.resolve(window.sessionStorage.removeItem(id));
};

export const removeItemPersistent = (id) => {
  return Promise.resolve(window.localStorage.removeItem(id));
};
